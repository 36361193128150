import React, { useState, useEffect } from 'react';
import GridPanel from '../../../global/gridpanel';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import cellButton from "../../../global/cellbutton";
import { show, close, showLoader, hideLoader } from '../../../../services/renderer';
import repoAuditLog from '../../../../repositories/security/auditlog';
import { notify, alert } from '../../../../services/notification';
import { triggerListeners } from '../../../../services/listeners';
import { cloneObject } from '../../../../services/tools';
import { isAuthorized } from '../../../../services/authorization';
import translations from '../../../../translations';

const List: React.FC = (props) => {

	const recordCell = (cellProps: any) => {
		let record = "";
		if (cellProps.dataItem.action == "LogIn") record = "";
		if (cellProps.dataItem.action == "UpdateUser") record = cellProps.dataItem.data.FullName + " (" + cellProps.dataItem.data.UserID + ")";
		if (cellProps.dataItem.action == "UpdateRole") record = cellProps.dataItem.data.Name;
		if (cellProps.dataItem.action == "AddUser") record = cellProps.dataItem.data.FullName + " (" + cellProps.dataItem.data.UserID + ")";
		return (
			<td>
				{record}
			</td>
		);
	};

	return (
		<div>
			<GridPanel reloadKey="reloadAuditLog" loadData={repoAuditLog.getAll}>
				<GridColumn field="date" title="Date" editable={false} filter={'date'} format="{0:yyyy/MM/dd HH:mm}" />
				<GridColumn field="action" title="Action" editable={false} />
				<GridColumn filterable={false} title="Record" cell={recordCell} editable={false} />
				<GridColumn field="user" title="User" editable={false} />
			</GridPanel>
		</div>
	);
}

export default List;