///<reference path= "../../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect } from 'react';
import { minimalEditor } from '../../../../services/environment';
import { Upload } from '@progress/kendo-react-upload';
import FilePreview from '../../../global/filepreview';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { showLoader, hideLoader, confirmChanged } from '../../../../services/renderer';
import repoPublications from '../../../../repositories/settings/publications';
import repoCountryLanguages from '../../../../repositories/settings/countrylanguages';
import repoIndustries from '../../../../repositories/settings/industries';
import repoServices from '../../../../repositories/settings/services';
import _ from 'lodash';
import { alert } from '../../../../services/notification';
import { isAuthorized } from '../../../../services/authorization';
import translations, { getCurrentLanguage } from '../../../../translations';
import { preventDoubleClick } from '../../../../services/tools';

interface EditProps {
	dataItem: any,
	save: (record: any) => void,
	cancel: () => void
}

const Edit: React.FC<EditProps> = (props) => {

	const [record, setRecord] = useState<any>({});
	const [languages, setLanguages] = useState([]);
	const [allLanguagesChecked, setAllLanguagesChecked] = useState(false);
	const [industries, setIndustries] = useState([]);
	const [allIndustriesChecked, setAllIndustriesChecked] = useState(false);
	const [services, setServices] = useState([]);
	const [allServicesChecked, setAllServicesChecked] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [file, setFile] = useState();
	const [fileChanged, setFileChanged] = useState(false);
	const [editorConfig] = useState({
		...minimalEditor,
		placeholderText: translations.publications_description_placeholder,
		language: getCurrentLanguage(),
		height: 400,
	});
	const [dataChanged, setDataChanged] = useState(false);
	const [permissions, setPermissions] = useState("none");

	useEffect(() => {
		showLoader();
		repoPublications.get(props.dataItem.id).then((resultRecord) => {
			setRecord(resultRecord);
			repoCountryLanguages.getActive().then((result) => {
				setLanguages(result.map((language: any) => {
					return {
						id: language.id,
						name: language.name,
						checked: _.some(resultRecord.languages, { languageID: language.id })
					}
				}));
			});
			repoIndustries.getActive().then((result) => {
				setIndustries(result.map((industry: any) => {
					return {
						id: industry.id,
						name: industry.name,
						checked: _.some(resultRecord.industries, { industryID: industry.id })
					}
				}));
			});
			repoServices.subServices.getActiveForCountry().then((result) => {
				setServices(result.map((service: any) => {
					return {
						id: service.id,
						name: service.mainOffer.mainOffering + " > " + service.subOffering,
						checked: _.some(resultRecord.services, { serviceID: service.id })
					}
				}));
			});
			if (resultRecord.image && resultRecord.image.size && resultRecord.image.size > 0) {
				setFile({
					content: "/api/content/files/get/" + resultRecord.image.internalFilename + "/" + resultRecord.image.filename,
					name: resultRecord.image.filename,
					size: resultRecord.image.size,
					type: resultRecord.image.type
				});
			}
			hideLoader();
		});

		if (isAuthorized("Publications", "Update")) {
			setPermissions("Update");
		} else if ((isAuthorized("Publications", "Read"))) {
			setPermissions("Read");
		}
	
	}, []);

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			default:
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value;
		}
		setRecord(newRecord);
		setDataChanged(true);
	}

	function changeContent(newContent: string) {
		let newRecord = { ...record };
		newRecord.description = newContent;
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		record.document_fileAction = fileChanged ? "change" : "keep";
		if (fileChanged && !file) {
			alert(translations.general_fileupload_imagerequired);
		} else {
			if (fileChanged && file) {
				record.file = file;
				// @ts-ignore
				record.image_fileAction = "change";
			}
			record.industries.length = 0;
			industries.forEach((industry: any) => {
				if (industry.checked) record.industries.push(industry.id);
			});
			record.services.length = 0;
			services.forEach((service: any) => {
				if (service.checked) record.services.push(service.id);
			});
			record.languages.length = 0;
			languages.forEach((language: any) => {
				if (language.checked) record.languages.push(language.id);
			});
			props.save(record);
		}
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	function changeIndustry(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllIndustriesChecked(false);
		setIndustries([...industries]);
		setDataChanged(true);
	}

	function selectAllIndustries(e: any) {
		setAllIndustriesChecked(e.target.value);
		industries.forEach((industry: any) => {
			industry.checked = e.target.value;
		});
		setIndustries([...industries]);
		setDataChanged(true);
	}

	function changeService(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllServicesChecked(false);
		setServices([...services]);
		setDataChanged(true);
	}

	function selectAllServices(e: any) {
		setAllServicesChecked(e.target.value);
		services.forEach((service: any) => {
			service.checked = e.target.value;
		});
		setServices([...services]);
		setDataChanged(true);
	}

	function changeLanguage(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllLanguagesChecked(false);
		setLanguages([...languages]);
		setDataChanged(true);
	}

	function selectAllLanguages(e: any) {
		setAllLanguagesChecked(e.target.value);
		languages.forEach((language: any) => {
			language.checked = e.target.value;
		});
		setLanguages([...languages]);
		setDataChanged(true);
	}

	// @ts-ignore
	function addFile(event: UploadOnAddEvent) {
		if (event && event.affectedFiles && event.affectedFiles.length > 0 && event.affectedFiles[0]) {
			if (event.affectedFiles[0].size && event.affectedFiles[0].size > 4000000) {
				setFile(null);
				alert(translations.general_fileupload_above4mb);
				return;
			}
			if (".gif.jpg.png".indexOf(event.affectedFiles[0].extension.toLowerCase() || "") == -1) {
				setFile(null);
				alert(translations.general_fileupload_noimage);
				return;
			}
			setFileChanged(true);
			setFile(event.affectedFiles[0]);
			setDataChanged(true);
		} else {
			setFile(null);
			alert(translations.general_fileupload_novalidfile);	
		}
	}

	return (
		<Dialog
			onClose={cancel}
			title={(permissions === "Update" ? translations.general_edit : translations.general_view) + " " + (record && record.title ? record.title : "")}
			width="70%"
		>
			<div className="tab-bar">
				<ul>
					<li className={selectedTab === 0 ? "active" : ""} onClick={() => { setSelectedTab(0); }}>{translations.general_tab_details}</li>
					<li className={selectedTab === 4 ? "active" : ""} onClick={() => { setSelectedTab(4); }}>{translations.publications_description}</li>
					<li className={selectedTab === 1 ? "active" : ""} onClick={() => { setSelectedTab(1); }}>{translations.general_tab_industries}</li>
					<li className={selectedTab === 2 ? "active" : ""} onClick={() => { setSelectedTab(2); }}>{translations.general_tab_services}</li>
					<li className={selectedTab === 3 ? "active" : ""} onClick={() => { setSelectedTab(3); }}>{translations.general_tab_languages}</li>
				</ul>
			</div>
			<div className="tab-bar-container">
				<div className={selectedTab === 0 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<label className="k-form-field">
							<span>{translations.publications_title}</span>
							<Input
								name="title"
								style={{ width: "100%" }}
								placeholder={translations.general_placeholder_prefix + " " + translations.publications_title + "..."}
								value={record && record.title ? record.title : ""}
								onChange={onChange}
								required={true}
								validationMessage={translations.general_validationmessage + " " + translations.publications_title}
								readOnly={permissions === "Read"}
							/>
						</label>
						<label className="k-form-field">
							<span>{translations.publications_externallink}</span>
							<Input
								name="externalLink"
								style={{ width: "100%" }}
								placeholder={translations.general_placeholder_prefix + " " + translations.publications_externallink + "..."}
								value={record && record.externalLink ? record.externalLink : ""}
								onChange={onChange}
								required={true}
								validationMessage={translations.general_validationmessage + " " + translations.publications_externallink}
								readOnly={permissions === "Read"}
							/>
						</label>
						<label className="k-form-field">
							<span>{translations.publications_image}</span>
							<div className={!file ? "isrequired" : ""}>
								<Upload
									autoUpload={false}
									multiple={false}
									accept=".gif,.jpg,.png"
									files={file ? [file] : []}
									onAdd={addFile}
									listItemUI={FilePreview}
									onRemove={() => {
										setFile(undefined);
									}}
									className="hidden-upload-button-in-preview"
									disabled={permissions === "Read"}
								/>
							</div>
						</label>
						<label className="k-form-field">
							<span>{translations.general_active}</span>
							{/*
					// @ts-ignore*/}
							<Switch
								name="active"
								checked={
									record && record.active ? record.active : false
								}
								onChange={onChange}
								disabled={permissions === "Read"}
								onLabel={translations.general_switch_on}
								offLabel={translations.general_switch_off}
							/>
						</label>
					</form>
				</div>
				<div className={selectedTab === 4 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row">
							<div className="col-md-12">
								<div className={record.description == "" ? "isrequired" : ""}><FroalaEditorComponent config={editorConfig} model={record.description} onModelChange={changeContent}></FroalaEditorComponent></div>
							</div>
						</div>
					</form>
				</div>
				<div className={selectedTab === 1 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allIndustriesChecked} onChange={selectAllIndustries} disabled={permissions === "Read"} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{industries.map((industry: any) => (
							<div className="row spaced-row switch-row" key={industry.id}>
								<div className="col-md-11">
									<span className={industry.checked ? "checked-row" : ""}>{industry.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={industry} checked={industry.checked} onChange={changeIndustry} disabled={permissions === "Read"} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
				<div className={selectedTab === 2 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allServicesChecked} onChange={selectAllServices} disabled={permissions === "Read"} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{services.map((service: any) => (
							<div className="row spaced-row switch-row" key={service.id}>
								<div className="col-md-11">
									<span className={service.checked ? "checked-row" : ""}>{service.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={service} checked={service.checked} onChange={changeService} disabled={permissions === "Read"} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
				<div className={selectedTab === 3 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allLanguagesChecked} onChange={selectAllLanguages} disabled={permissions === "Read"} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{languages.map((language: any) => (
							<div className="row spaced-row switch-row" key={language.id}>
								<div className="col-md-11">
									<span className={language.checked ? "checked-row" : ""}>{language.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={language} checked={language.checked} onChange={changeLanguage} disabled={permissions === "Read"} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
			</div>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				{permissions === "Update" ? <button className="k-button k-primary" onClick={() => preventDoubleClick(save)}>
					{translations.general_save}
				</button> : null}
			</DialogActionsBar>
		</Dialog>
	);
}

Edit.defaultProps = {
	dataItem: null,
	save: (record: any) => { },
	cancel: () => { }
};

export default Edit;