import { AuthenticationContext, runWithAdal, withAdalLogin, AdalConfig, adalGetToken } from 'react-adal';
import axios from 'axios';
import { getEnvironmentData } from './environment';

export interface IUser {
	userID: string
	firstName: string
	lastName: string
	email: string
	phone?: string
	mobile?: string
	function?: string
	businessUnit: {
		id: string
		name: string
		countryID: string
		active: boolean
	}
	businessUnitID: string
	permissions: any
	location?: string
	active: boolean
	image: {
		size: number,
		filename?: string
		type?: string
		internalFilename?: string
	},
	languageID: string,
	language: {
		code: string
	}
	userRoles: Array<any>
};

let adalConfig: AdalConfig;

const DO_NOT_LOGIN = false;

let currentUser: IUser;

let _authContext: AuthenticationContext;

export const authContext = () => {
	if (_authContext) return _authContext;
	const environment = getEnvironmentData();
	adalConfig = {
		tenant: environment.tenantId,
		clientId: environment.clientId,
		postLogoutRedirectUri: window.location.origin,
		endpoints: {
			api: environment.clientId,
		},
		// @ts-ignore
		cacheLocation: "localStorage",
	};
	_authContext = new AuthenticationContext(adalConfig);
	return _authContext;
};

export const wrapApplication = (callback: () => void) => runWithAdal(authContext(), callback, DO_NOT_LOGIN);

export const getToken = () => {
	return adalGetToken(authContext(), adalConfig.clientId).catch((err) => {
		window.location.reload();
		// make this better (popup login?)
	});
}

export const loadMyProfile = async () => {
	var accessToken = await getToken();
	var myProfileResult = await axios.get('/api/myprofile', {
		headers: {
			"Authorization": "Bearer " + accessToken
		}
	});
	currentUser = {
		userID: myProfileResult.data.userID,
		firstName: myProfileResult.data.firstName,
		lastName: myProfileResult.data.lastName,
		email: myProfileResult.data.email,
		phone: myProfileResult.data.phone,
		mobile: myProfileResult.data.mobile,
		function: myProfileResult.data.function,
		businessUnit: myProfileResult.data.businessUnit,
		businessUnitID: myProfileResult.data.businessUnitID,
		location: myProfileResult.data.location,
		active: myProfileResult.data.active,
		image: {
			size: myProfileResult.data.image.size,
			filename: myProfileResult.data.image.filename,
			type: myProfileResult.data.image.type,
			internalFilename: myProfileResult.data.image.internalFilename,
		},
		languageID: myProfileResult.data.languageID,
		language: myProfileResult.data.language,
		permissions: myProfileResult.data.permissions,
		userRoles: myProfileResult.data.userRoles,
	}
	return true;
};

export const logOut = () => {
	_authContext.logOut();
};

export const getMyProfile = () => currentUser;
