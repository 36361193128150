import React, { useState, useEffect } from 'react';

const Faq: React.FC = () => {
	return (
		<div className="faq-container">
			{/*
				// @ts-ignore*/}
			<iframe src="https://player.vimeo.com/video/363824250" width="100%" height="100%" style={{ border: 0}} allowFullScreen></iframe>
		</div>
	);
}

export default Faq;
