import React, { useState, useEffect } from 'react';
import GridPanel from '../../../global/gridpanel';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import cellButton from '../../../global/cellbutton';
import New from './new';
import Edit from './edit';
import { show, close, showLoader, hideLoader } from '../../../../services/renderer';
import repoLegalDocuments from '../../../../repositories/settings/legaldocuments';
import { notify, alert } from '../../../../services/notification';
import { triggerListeners } from '../../../../services/listeners';
import { cloneObject } from '../../../../services/tools';
import { isAuthorized } from '../../../../services/authorization';
import translations from '../../../../translations';
import DeloitteButton from '../../../global/deloittebutton';

const List: React.FC = (props) => {

	function edit(dataItem: any) {
		show(Edit, "legaldocumentedit", { dataItem: cloneObject(dataItem), save: save, cancel: cancel });
	}

	function cancel() {
		close("legaldocumentedit");
		close("legaldocumentnew");
	}

	function save(record: any) {
		showLoader();
		if (!record.id ||
			!record.businessUnitID ||
			record.businessUnitID == "" ||
			record.businessUnitID == "00000000-0000-0000-0000-000000000000" ||
			!record.languageID ||
			record.languageID == "" ||
			record.languageID == "00000000-0000-0000-0000-000000000000" ||
			!record.title) {
			hideLoader();
			alert(translations.general_fillinallrequiredfields);
		} else {
			var formData = new FormData();
			if (record.document_fileAction && record.document_fileAction == "change") {
				let file = record.file.getRawFile();
				formData.append("document_file", file);
			}
			formData.append("id", record.id);
			formData.append("businessUnitID", record.businessUnitID);
			formData.append("languageID", record.languageID);
			formData.append("title", record.title);
			formData.append("active", record.active);
			if (record.document_fileAction) formData.append("document_fileAction", record.document_fileAction);
			repoLegalDocuments.save(formData).then(result => {
				notify(translations.general_savedsuccess);
				triggerListeners("reloadLegalDocuments");
				cancel();
				hideLoader();
			}).catch((error) => {
				hideLoader();
				alert(error);
			});
		}
	}

	return (
		<GridPanel reloadKey="reloadLegalDocuments" loadData={repoLegalDocuments.getAll} filter={{
			logic: "and",
			filters: [
				{
					field: "active",
					operator: "eq",
					value: true
				}
			]
		}} onRowDoubleClick={edit}>
			{isAuthorized("LegalDocuments", "Create") ? <GridToolbar >
				<div>
					<DeloitteButton title={translations.general_addnew} onClick={() => { show(New, "legaldocumentnew", { save: save, cancel: cancel }); }} >
						{translations.general_addnew}
					</DeloitteButton>
				</div>
			</GridToolbar> : null}
			<GridColumn field="title" title={translations.legaldocuments_title} editable={false} />
			<GridColumn field="language.name" title={translations.legaldocuments_language} editable={false} />
			<GridColumn field="document.filename" title={translations.legaldocuments_document} editable={false} />
			<GridColumn field="businessUnit.name" title={translations.legaldocuments_businessunit} editable={false} />
			<GridColumn field="active" title={translations.general_active} width="125px" editor="boolean" filter={'boolean'} />
			{isAuthorized("LegalDocuments", "Update") ? <GridColumn filterable={false} width="120px" editable={false} title={translations.general_edit} cell={cellButton(edit, translations.general_edit)} /> :
				(!isAuthorized("LegalDocuments", "Update") && isAuthorized("LegalDocuments", "Read") ? <GridColumn filterable={false} width="120px" editable={false} title={translations.general_view} cell={cellButton(edit, translations.general_view)} /> : null)} 
		</GridPanel>
	);
}

export default List;