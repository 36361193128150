export default [{
	"name": "Abacus",
	"class": "deloitteicon-130"
}, {
	"name": "Alert (Bell)",
	"class": "deloitteicon-127"
}, {
	"name": "Ambulance",
	"class": "deloitteicon-114"
}, {
	"name": "Anker",
	"class": "deloitteicon-133"
}, {
	"name": "Appendix",
	"class": "deloitteicon-18"
}, {
	"name": "Arrow left right",
	"class": "deloitteicon-134"
}, {
	"name": "At sign",
	"class": "deloitteicon-54"
}, {
	"name": "Badge",
	"class": "deloitteicon-94"
}, {
	"name": "Balance",
	"class": "deloitteicon-69"
}, {
	"name": "Battery",
	"class": "deloitteicon-143"
}, {
	"name": "Bicycle",
	"class": "deloitteicon-129"
}, {
	"name": "Binoculars",
	"class": "deloitteicon-151"
}, {
	"name": "Blank sheets",
	"class": "deloitteicon-44"
}, {
	"name": "Book with placeholder",
	"class": "deloitteicon-52"
}, {
	"name": "Box (1)",
	"class": "deloitteicon-154"
}, {
	"name": "Brain",
	"class": "deloitteicon-160"
}, {
	"name": "Bridge",
	"class": "deloitteicon-132"
}, {
	"name": "Briefcase",
	"class": "deloitteicon-5"
}, {
	"name": "Budget (Piggy bank)",
	"class": "deloitteicon-109"
}, {
	"name": "Bulb - low energy",
	"class": "deloitteicon-93"
}, {
	"name": "Calculation signs",
	"class": "deloitteicon-152"
}, {
	"name": "Car connected",
	"class": "deloitteicon-107"
}, {
	"name": "Carrier (boxes)",
	"class": "deloitteicon-153"
}, {
	"name": "Certificate sign",
	"class": "deloitteicon-103"
}, {
	"name": "Chart (Bars)",
	"class": "deloitteicon-155"
}, {
	"name": "Check in calender",
	"class": "deloitteicon-47"
}, {
	"name": "Check list",
	"class": "deloitteicon-98"
}, {
	"name": "Check mark",
	"class": "deloitteicon-72"
}, {
	"name": "Chemic bottle",
	"class": "deloitteicon-51"
}, {
	"name": "Chemistry",
	"class": "deloitteicon-74"
}, {
	"name": "Chemistry (1)",
	"class": "deloitteicon-110"
}, {
	"name": "Chess pion",
	"class": "deloitteicon-126"
}, {
	"name": "Chip (IT)",
	"class": "deloitteicon-21"
}, {
	"name": "Chronometer",
	"class": "deloitteicon-7"
}, {
	"name": "Cinema clapper",
	"class": "deloitteicon-46"
}, {
	"name": "Circle graph",
	"class": "deloitteicon-106"
}, {
	"name": "Clock",
	"class": "deloitteicon-147"
}, {
	"name": "Coding",
	"class": "deloitteicon-158"
}, {
	"name": "Coffee",
	"class": "deloitteicon-150"
}, {
	"name": "Compass",
	"class": "deloitteicon-122"
}, {
	"name": "Compass sign",
	"class": "deloitteicon-20"
}, {
	"name": "Continuüm",
	"class": "deloitteicon-26"
}, {
	"name": "Crane (construction)",
	"class": "deloitteicon-45"
}, {
	"name": "Diamond",
	"class": "deloitteicon-121"
}, {
	"name": "Directions (Left - right)",
	"class": "deloitteicon-111"
}, {
	"name": "Display",
	"class": "deloitteicon-66"
}, {
	"name": "DNA sign",
	"class": "deloitteicon-136"
}, {
	"name": "Download sign",
	"class": "deloitteicon-128"
}, {
	"name": "Electricity sign",
	"class": "deloitteicon-92"
}, {
	"name": "Enlarge sign",
	"class": "deloitteicon-105"
}, {
	"name": "Entrance sign",
	"class": "deloitteicon-149"
}, {
	"name": "Euro sign",
	"class": "deloitteicon-67"
}, {
	"name": "Exclamatation sign",
	"class": "deloitteicon-68"
}, {
	"name": "Eye",
	"class": "deloitteicon-59"
}, {
	"name": "Factory",
	"class": "deloitteicon-38"
}, {
	"name": "Film sign",
	"class": "deloitteicon-148"
}, {
	"name": "Filter sign",
	"class": "deloitteicon-118"
}, {
	"name": "Finish",
	"class": "deloitteicon-157"
}, {
	"name": "Flag",
	"class": "deloitteicon-29"
}, {
	"name": "Flower",
	"class": "deloitteicon-31"
}, {
	"name": "Flyer",
	"class": "deloitteicon-10"
}, {
	"name": "Focus",
	"class": "deloitteicon-36"
}, {
	"name": "Forklift",
	"class": "deloitteicon-119"
}, {
	"name": "Game console",
	"class": "deloitteicon-117"
}, {
	"name": "Gear",
	"class": "deloitteicon-58"
}, {
	"name": "Gears (2)",
	"class": "deloitteicon-146"
}, {
	"name": "Globe",
	"class": "deloitteicon-33"
}, {
	"name": "Globe on stand",
	"class": "deloitteicon-28"
}, {
	"name": "Graph (bars & line)",
	"class": "deloitteicon-113"
}, {
	"name": "Graph (line)",
	"class": "deloitteicon-108"
}, {
	"name": "Handshake",
	"class": "deloitteicon-35"
}, {
	"name": "Hartbeat",
	"class": "deloitteicon-27"
}, {
	"name": "Hourglass",
	"class": "deloitteicon-112"
}, {
	"name": "House",
	"class": "deloitteicon-39"
}, {
	"name": "Inbox",
	"class": "deloitteicon-88"
}, {
	"name": "Info sign",
	"class": "deloitteicon-25"
}, {
	"name": "Injection",
	"class": "deloitteicon-77"
}, {
	"name": "Label",
	"class": "deloitteicon-80"
}, {
	"name": "Labyrint",
	"class": "deloitteicon-22"
}, {
	"name": "Laptop",
	"class": "deloitteicon-141"
}, {
	"name": "Leaf",
	"class": "deloitteicon-95"
}, {
	"name": "Learning",
	"class": "deloitteicon-56"
}, {
	"name": "Light bulb",
	"class": "deloitteicon-34"
}, {
	"name": "Location sign",
	"class": "deloitteicon-19"
}, {
	"name": "Lock",
	"class": "deloitteicon-24"
}, {
	"name": "Mailbox flag",
	"class": "deloitteicon-89"
}, {
	"name": "Male/female",
	"class": "deloitteicon-6"
}, {
	"name": "Man silhouette",
	"class": "deloitteicon-142"
}, {
	"name": "Man streaming out",
	"class": "deloitteicon-9"
}, {
	"name": "Map",
	"class": "deloitteicon-32"
}, {
	"name": "Maps",
	"class": "deloitteicon-40"
}, {
	"name": "Measuring plate",
	"class": "deloitteicon-11"
}, {
	"name": "Microphone",
	"class": "deloitteicon-17"
}, {
	"name": "Microscoop",
	"class": "deloitteicon-87"
}, {
	"name": "Money bag",
	"class": "deloitteicon-101"
}, {
	"name": "Mouse pointer",
	"class": "deloitteicon-135"
}, {
	"name": "News",
	"class": "deloitteicon-138"
}, {
	"name": "Office building",
	"class": "deloitteicon-86"
}, {
	"name": "Office Building (3)",
	"class": "deloitteicon-91"
}, {
	"name": "Open book",
	"class": "deloitteicon-137"
}, {
	"name": "Open box",
	"class": "deloitteicon-144"
}, {
	"name": "Passer",
	"class": "deloitteicon-43"
}, {
	"name": "PDF sign",
	"class": "deloitteicon-145"
}, {
	"name": "Person with fiche",
	"class": "deloitteicon-15"
}, {
	"name": "Person with pencil",
	"class": "deloitteicon-42"
}, {
	"name": "Phone",
	"class": "deloitteicon-159"
}, {
	"name": "Phone & fax",
	"class": "deloitteicon-116"
}, {
	"name": "Photo camera",
	"class": "deloitteicon-48"
}, {
	"name": "Picture sign",
	"class": "deloitteicon-83"
}, {
	"name": "Pie chart",
	"class": "deloitteicon-16"
}, {
	"name": "Pil",
	"class": "deloitteicon-90"
}, {
	"name": "Placeholder",
	"class": "deloitteicon-61"
}, {
	"name": "Plane",
	"class": "deloitteicon-96"
}, {
	"name": "Plaster",
	"class": "deloitteicon-131"
}, {
	"name": "Plus sign",
	"class": "deloitteicon-64"
}, {
	"name": "Power button",
	"class": "deloitteicon-156"
}, {
	"name": "Presentation board",
	"class": "deloitteicon-30"
}, {
	"name": "Printer",
	"class": "deloitteicon-84"
}, {
	"name": "Puzzle",
	"class": "deloitteicon-14"
}, {
	"name": "Question mark",
	"class": "deloitteicon-97"
}, {
	"name": "Quote marks (2)",
	"class": "deloitteicon-120"
}, {
	"name": "Quote sign",
	"class": "deloitteicon-100"
}, {
	"name": "Radio",
	"class": "deloitteicon-140"
}, {
	"name": "Refresh",
	"class": "deloitteicon-82"
}, {
	"name": "Regulation",
	"class": "deloitteicon-37"
}, {
	"name": "Retro timing",
	"class": "deloitteicon-49"
}, {
	"name": "Rocket",
	"class": "deloitteicon-99"
}, {
	"name": "Satelite",
	"class": "deloitteicon-13"
}, {
	"name": "Satelite (Space)",
	"class": "deloitteicon-85"
}, {
	"name": "Scope",
	"class": "deloitteicon-75"
}, {
	"name": "Shopping basket",
	"class": "deloitteicon-102"
}, {
	"name": "Shopping bucket",
	"class": "deloitteicon-79"
}, {
	"name": "Soundbeat",
	"class": "deloitteicon-62"
}, {
	"name": "Speaking out loud",
	"class": "deloitteicon-23"
}, {
	"name": "Speech balloon",
	"class": "deloitteicon-78"
}, {
	"name": "Speech bubbles (2)",
	"class": "deloitteicon-125"
}, {
	"name": "Speech bubble with text",
	"class": "deloitteicon-124"
}, {
	"name": "Speedometer",
	"class": "deloitteicon-1"
}, {
	"name": "Star",
	"class": "deloitteicon-4"
}, {
	"name": "Stock exchance building",
	"class": "deloitteicon-63"
}, {
	"name": "Streaming",
	"class": "deloitteicon-73"
}, {
	"name": "Streaming antenna",
	"class": "deloitteicon-55"
}, {
	"name": "Structure (Hierarchy)",
	"class": "deloitteicon-139"
}, {
	"name": "Tablet & phone",
	"class": "deloitteicon-60"
}, {
	"name": "Target",
	"class": "deloitteicon-57"
}, {
	"name": "Team",
	"class": "deloitteicon-70"
}, {
	"name": "Telescope",
	"class": "deloitteicon-76"
}, {
	"name": "Tie",
	"class": "deloitteicon-71"
}, {
	"name": "Time refrech",
	"class": "deloitteicon-50"
}, {
	"name": "Transition",
	"class": "deloitteicon-2"
}, {
	"name": "Tree",
	"class": "deloitteicon-81"
}, {
	"name": "Tumbs up",
	"class": "deloitteicon-104"
}, {
	"name": "Umbrella",
	"class": "deloitteicon-3"
}, {
	"name": "Warning sign",
	"class": "deloitteicon-65"
}, {
	"name": "Water tap",
	"class": "deloitteicon-115"
}, {
	"name": "Winning Cup",
	"class": "deloitteicon-53"
}, {
	"name": "Wrench",
	"class": "deloitteicon-8"
}, {
	"name": "Writing pen",
	"class": "deloitteicon-12"
}];