import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/css/all.css';
import App from './App';
import { wrapApplication } from './services/authentication';
import { loadEnvironment } from './services/environment';
import * as $ from 'jquery';
// @ts-ignore
window["$"] = $;
// @ts-ignore
window["jQuery"] = $;

const startApplication = () => {
	loadEnvironment().then(() => {
		wrapApplication(() => {
			ReactDOM.render(<App />, document.getElementById('root'));
		})
	}).catch((err) => {
		console.log(err);
	});
};

startApplication();

export default startApplication;
