///<reference path= "../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { editorWelcomeLetter } from '../../../services/environment';
import { Button } from '@progress/kendo-react-buttons';
import { Input, Switch, NumericTextBox } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { getMyProfile } from '../../../services/authentication';
import GridPanel from '../../global/gridpanel';
import { GridToolbar, GridColumn } from '@progress/kendo-react-grid';
import cellButton from '../../global/cellbutton';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import translations, { getCurrentLanguage } from '../../../translations';
import { triggerListeners } from '../../../services/listeners';
import repoProposals from '../../../repositories/proposals/proposals';
import { useLanguages } from '../../../repositories/settings/countrylanguages';
import { useBusinessUnits } from '../../../repositories/settings/businessunits';
import { useUsers, usePartners } from '../../../repositories/security/users';
import repoServices from '../../../repositories/settings/services';
import repoIndustries from '../../../repositories/settings/industries';
import repoTemplates from '../../../repositories/settings/templates';
import repoWelcomeLetters from '../../../repositories/settings/welcomeletters';
import repoCoverImages from '../../../repositories/settings/coverimages';
import _ from 'lodash';
//@ts-ignore
import FroalaEditor from 'froala-editor';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { show, close, confirm, showLoader, hideLoader } from '../../../services/renderer';
import NewTeamMember from './newteammember';
import NewContact from './newcontact';
import { notify, alert } from '../../../services/notification';
import DeloitteButton from '../../global/deloittebutton';

let delayResize: NodeJS.Timeout;

const Wizard: React.FC = (props: any) => {
	const [record, setRecord]: [any, Function] = useState({
		id: "00000000-0000-0000-0000-000000000000",
		companyName: "",
		companyAddress: "",
		sapReference: "JO-",
		creatorID: getMyProfile().userID,
		signingPartnerID: "00000000-0000-0000-0000-000000000000",
		signingPartner: {},
		engagementPartnerID: "00000000-0000-0000-0000-000000000000",
		engagementPartner: {},
		welcomeLetter: "",
		title: "",
		subtitle: "",
		languageID: "00000000-0000-0000-0000-000000000000",
		language: {},
		businessUnitID: getMyProfile().businessUnitID,
		industryID: "00000000-0000-0000-0000-000000000000",
		serviceID: "00000000-0000-0000-0000-000000000000",
		expectedRevenue: 0,
		landscape: false,
		hideWelcomeLetter: false,
		creationDate: new Date(),
		proposalDate: new Date(),
		deadlineDate: new Date(),
		type: "New",
		emailBody: "ToBeReplaced",
		templateID: "00000000-0000-0000-0000-000000000000",
		contacts: new Array(),
		pursuitTeam: new Array(),
		coverImageID: "00000000-0000-0000-0000-000000000000",
		coverImage: {
			name: "",
			image: {
				filename: "",
			}
		}
	});
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedTemplate, setSelectedTemplate]: [any, Function] = useState(null);
	const users = useUsers();
	const partners = usePartners();
	const languages = useLanguages();
	const [welcomeLetters, setWelcomeLetters] = useState([]);
	const [templates, setTemplates] = useState([]);
	const [services, setServices] = useState([]);
	const [industries, setIndustries] = useState([]);
	const [sections, setSections] = useState([]);
	const [teamMembers, setTeamMembers]: [Array<any>, Function] = useState([]);
	const [contacts, setContacts]: [Array<any>, Function] = useState([]);
	const [coverImageSelectorVisible, setCoverImageSelectorVisible] = useState(false);
	const [coverImages, setCoverImages] = useState([]);
	const ref: any = useRef(null);
	const [height, setHeight] = useState(0);
	const [editorConfig] = useState({
		...editorWelcomeLetter,
		placeholderText: translations.welcomeletters_content_placeholder,
		language: getCurrentLanguage(),
		height: 400,
	});

	useEffect(() => {
		repoServices.subServices.getActive(getMyProfile().businessUnitID).then((result) => {
			setServices(result.map((service: any) => {
				return {
					id: service.id,
					name: service.mainOffer.mainOffering + " > " + service.subOffering
				}
			}));
		});
		repoIndustries.getActive().then((result) => {
			setIndustries(result.map((industry: any) => {
				return {
					id: industry.id,
					name: industry.name
				}
			}));
		});
		window.addEventListener("resize", changeHeight);
		return () => {
			window.removeEventListener("resize", changeHeight);
		};
	}, []);

	useLayoutEffect(() => {
		var newHeight = ref.current.clientHeight;
		setHeight(newHeight);
	});

	function changeHeight() {
		clearTimeout(delayResize);
		delayResize = setTimeout(() => {
			var newHeight = ref.current.clientHeight;
			setHeight(newHeight);
		}, 500);
	}

	useEffect(() => {
		if (ref && ref.current && ref.current.getElementsByClassName("fr-wrapper").length == 1 && ref.current.getElementsByClassName("fr-fullscreen").length == 0) {
			ref.current.getElementsByClassName("fr-wrapper")[0].style.height = (height - 160).toString() + "px";
		}
	}, [height]);

	function setClasses(tab: number) {
		let className = "";
		if (tab == selectedTab) className += "active ";
		if (tab < selectedTab) className += "completed ";
		if (tab == (selectedTab - 1)) className += "last";
		return className;
	}

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			case "industryID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				break;
			case "serviceID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				break;
			case "languageID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				newRecord.language = event.target.value;
				break;
			case "signingPartnerID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.userID : "00000000-0000-0000-0000-000000000000";
				newRecord.signingPartner = event.target.value;
				break;
			case "engagementPartnerID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.userID : "00000000-0000-0000-0000-000000000000";
				newRecord.engagementPartner = event.target.value;
				break;
			case "welcomeletterID":
				newRecord.welcomeLetter = event.target.value ? event.target.value.content : "";
				break;
			default:
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value;
		}
		if (newRecord.industryID !== "00000000-0000-0000-0000-000000000000" && newRecord.serviceID !== "00000000-0000-0000-0000-000000000000") {
			repoCoverImages.getActive({
				industryID: newRecord.industryID,
				serviceID: newRecord.serviceID
			}).then((result) => {
				setCoverImages(result.map((coverImage: any) => {
					return {
						id: coverImage.id,
						name: coverImage.name,
						image: coverImage.image,
						backgroundColor: coverImage.backgroundColor
					}
				}));
			});
		}
		setRecord(newRecord);
	}

	function changeContent(newContent: string) {
		let newRecord = { ...record };
		newRecord.welcomeLetter = newContent;
		setRecord(newRecord);
	}

	function selectTemplate(template: any) {
		templates.forEach((templateItem: any) => {
			templateItem.selected = false;
		});
		template.selected = true;
		setSelectedTemplate(template);		
		setTemplates([...templates]);
		repoTemplates.get(template.id).then((result) => {
			setSections(result.sections);
		});
		record.templateID = template.id;
		setRecord({ ...record });
	}

	function save() {
		if (record.coverImageID == "00000000-0000-0000-0000-000000000000" ||
			record.title == "" ||
			record.subtitle == "" ||
			record.sapReference == "" ||
			record.sapReference == "JO-" ||
			record.signingPartnerID == "00000000-0000-0000-0000-000000000000" ||
			record.engagementPartnerID == "00000000-0000-0000-0000-000000000000" ||
			record.languageID == "00000000-0000-0000-0000-000000000000" ||
			record.industryID == "00000000-0000-0000-0000-000000000000" ||
			record.serviceID == "00000000-0000-0000-0000-000000000000" ||
			record.templateID == "00000000-0000-0000-0000-000000000000" ||
			record.welcomeLetter == ""
		) {
			alert("You need to enter all required fields first!");
		} else {
			showLoader();
			var recordToSave = { ...record };
			recordToSave.contacts = contacts;
			// @ts-ignore
			recordToSave.pursuitTeam = teamMembers.map((teamMember: any) => {
				return {
					id: "00000000-0000-0000-0000-000000000000",
					userID: teamMember.userID,
					editProfile: {}
				};
			});
			delete recordToSave.signingPartner;
			delete recordToSave.engagementPartner;
			delete recordToSave.language;
			delete recordToSave.coverImage;
			repoProposals.save(recordToSave).then((result) => {
				props.save();
				hideLoader();
				notify(translations.general_savedsuccess);
			}).catch((err) => {
				alert(err);
			});
		}
	}

	async function getTeamMembers() {
		var memberList: Array<any> = [];
		memberList.push({
			id: 'creator',
			userID: getMyProfile().userID,
			user: getMyProfile(),
			role: "Creator",
			editProfile: { "general": true, "welcomeLetter": true, "contacts": true, "pursuitTeam": true, "sections": ["c1742822-eed7-4956-ba61-b6a800255606 ", "68f56aa3-acbc-4243-8859-c838ce3f265a ", "2655d1ae-ff3e-4dcc-9882-1776a29874a0"] },
			type: 'static'
		});
		if (record.signingPartnerID !== "00000000-0000-0000-0000-000000000000") {
			memberList.push({
				id: 'signingpartner',
				userID: record.signingPartnerID,
				user: record.signingPartner,
				role: "Signing partner",
				editProfile: { "general": true, "welcomeLetter": true, "contacts": true, "pursuitTeam": true, "sections": ["c1742822-eed7-4956-ba61-b6a800255606 ", "68f56aa3-acbc-4243-8859-c838ce3f265a ", "2655d1ae-ff3e-4dcc-9882-1776a29874a0"] },
				type: 'static'
			});
		}
		if (record.engagementPartnerID !== "00000000-0000-0000-0000-000000000000") {
			memberList.push({
				id: 'engagementpartner',
				userID: record.engagementPartnerID,
				user: record.engagementPartner,
				role: "Engagement partner",
				editProfile: { "general": true, "welcomeLetter": true, "contacts": true, "pursuitTeam": true, "sections": ["c1742822-eed7-4956-ba61-b6a800255606 ", "68f56aa3-acbc-4243-8859-c838ce3f265a ", "2655d1ae-ff3e-4dcc-9882-1776a29874a0"] },
				type: 'static'
			});
		}
		memberList = memberList.concat(teamMembers);
		return {
			total: memberList.length, data: memberList
		};
	}

	function saveNewTeamMember(user: any, editProfile: any) {
		teamMembers.push({ 
			id: "00000000-0000-0000-0000-000000000000",	
			userID: user.userID, 
			user: user, 
			role: (editProfile && editProfile.general) ? "Elevated contributor" : "Contributor", 
			editProfile: {}, 
			type: "contributor" 
		});
		setTeamMembers([...teamMembers]);
		close("newteammember");
	}

	function removeTeamMember(teamMember: any) {
		confirm("Remove team member?", "Do you really want to remove " + teamMember.user.fullName + " as team member?", () => {
			// @ts-ignore
			_.remove(teamMembers, { type: "contributor", userID: teamMember.userID });
			setTeamMembers([...teamMembers]);
		}, "Yes", () => { }, "No");
	}

	async function getContacts() {
		return {
			total: contacts.length, data: contacts
		};
	}

	function saveNewContact(contact: any) {
		contacts.push(contact);
		setContacts([...contacts]);
		close("newcontact");
	}

	function removeContact(contact: any) {
		confirm("Remove contact?", "Do you really want to remove " + contact.firstName + " " + contact.lastName + " contact?", () => {
			// @ts-ignore
			_.remove(contacts, { email: contact.email });
			setContacts([...contacts]);
		}, "Yes", () => { }, "No");
	}

	function hideCoverImageSelector(e: any) {
		setCoverImageSelectorVisible(false);
		window.removeEventListener('click', hideCoverImageSelector, false);
	}

	function selectCoverImage() {
		window.removeEventListener('click', hideCoverImageSelector, false);
		setCoverImageSelectorVisible(true);
		setTimeout(() => {
			window.addEventListener('click', hideCoverImageSelector, false);
		}, 400);
	}

	function changeCoverImage(coverImage: any) {
		let newRecord = { ...record };
		newRecord.coverImageID = coverImage.id;
		newRecord.coverImage = coverImage;
		setRecord(newRecord);
		setCoverImageSelectorVisible(false);
	}

	function goToNextTab() {
		switch (selectedTab) {
			case 0:
				if (record.coverImageID == "00000000-0000-0000-0000-000000000000" ||
					record.title == "" ||
					record.subtitle == "" ||
					record.sapReference == "" ||
					record.sapReference == "JO-" ||
					record.signingPartnerID == "00000000-0000-0000-0000-000000000000" ||
					record.engagementPartnerID == "00000000-0000-0000-0000-000000000000" ||
					record.languageID == "00000000-0000-0000-0000-000000000000" ||
					record.industryID == "00000000-0000-0000-0000-000000000000" ||
					record.serviceID == "00000000-0000-0000-0000-000000000000"
					) {
					alert("You need to enter all required fields first!")
				} else {
					if (record.expectedRevenue > 0) {
						setSelectedTab(selectedTab + 1);
						if (record.industryID !== "00000000-0000-0000-0000-000000000000" && record.serviceID !== "00000000-0000-0000-0000-000000000000" && record.languageID !== "00000000-0000-0000-0000-000000000000") {
							repoTemplates.getActive({
								languageID: record.languageID,
								businessUnitID: record.businessUnitID,
								landscape: record.landscape ? record.landscape : false,
								industryID: record.industryID,
								serviceID: record.serviceID
							}).then((result) => {
								setTemplates(result.map((template: any) => ({
									id: template.id,
									title: template.title,
									shortDescription: template.shortDescription,
									selected: false
								})));
							});
							repoWelcomeLetters.getActive({
								type: record.type,
								languageID: record.languageID,
								businessUnitID: record.businessUnitID,
								industryID: record.industryID,
								serviceID: record.serviceID
							}).then((result) => {
								setWelcomeLetters(result.map((letter: any) => ({
									id: letter.id,
									name: letter.name,
									content: letter.content
								})));
							});
						}
					} else {
						alert("You need to enter an expected revenue greater than 0!");
					}
				}
				break;
			case 1:
			case 2:
				setSelectedTab(selectedTab + 1);
				break;
			case 3:
				if (record.templateID == "00000000-0000-0000-0000-000000000000") {
					alert("You need to select a Template first!");
				} else {
					if (selectedTemplate) {
						onChange({
							target: {
								props: {
									name: 'hideWelcomeLetter'
								},
								value: selectedTemplate.hideWelcomeLetter === true
							}
						});
					}
					setSelectedTab(selectedTab + 1);
				}
				break;
		}
	}

	return (
		<div className="wizard-container">
			<div className="wizard-innercontainer">
				<div className="wizard-close" onClick={props.cancel}><i className="deloitte-icon icon-cross_special" aria-hidden="true"></i></div>
				<h1 className="wizard-h1"><span>{getMyProfile().firstName}</span>{translations.proposalwizard_title}</h1>
				<h3 className="wizard-h3">{translations.proposalwizard_requireopportunity}</h3>
				<ul className="wizard-ul">
					<li className={setClasses(0)}>
						<a onClick={() => { }}>
							<div className="wizard-icon"><i className="deloitte-icon icon-slider_special" aria-hidden="true"></i></div>
							<div className="wizard-bullet"> </div>
							<div className="wizard-steplabel">{translations.proposalwizard_steps_start}</div>
						</a>
					</li>
					<li className={setClasses(1)}>
						<a onClick={() => { }}>
							<div className="wizard-icon"><i className="deloitte-icon icon-profile_2_special" aria-hidden="true"></i></div>
							<div className="wizard-bullet"> </div>
							<div className="wizard-steplabel">{translations.proposalwizard_steps_team}</div>
						</a>
					</li>
					<li className={setClasses(2)}>
						<a onClick={() => { }}>
							<div className="wizard-icon"><i className="deloitte-icon icon-edit_profile_special" aria-hidden="true"></i></div>
							<div className="wizard-bullet"> </div>
							<div className="wizard-steplabel">{translations.proposalwizard_steps_contacts}</div>
						</a>
					</li>
					<li className={setClasses(3)}>
						<a onClick={() => { }}>
							<div className="wizard-icon"><i className="deloitte-icon icon-options_special" aria-hidden="true"></i></div>
							<div className="wizard-bullet"> </div>
							<div className="wizard-steplabel">{translations.proposalwizard_steps_template}</div>
						</a>
					</li>
					<li className={setClasses(4)}>
						<a onClick={() => { }}>
							<div className="wizard-icon"><i className="deloitte-icon icon-file_edit_special" aria-hidden="true"></i></div>
							<div className="wizard-bullet"> </div>
							<div className="wizard-steplabel">{translations.proposalwizard_steps_letter}</div>
						</a>
					</li>
				</ul>
				<div className="tab-bar-container">
					<div className={selectedTab === 0 ? "tab-fixed-height" : "tab-fixed-height hidden"}>
						<h2>{translations.proposalwizard_start_title}</h2>
						<div className="tab-fixed-height-content">
							<form className="k-form">
								<div className="row">
									<div className="col-md-9">
										<label className="k-form-field">
											<span>{translations.proposalwizard_opportunity}</span>
											<Input readOnly={false} required={true} name="sapReference" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + translations.proposalwizard_opportunity + " (Format: JO-XXXXXXX)..."} value={(record && record.sapReference ? record.sapReference : "")} onChange={onChange} validationMessage={translations.general_validationmessage + " " + translations.proposalwizard_opportunity} />
										</label>
										<label className="k-form-field">
											<span>{translations.proposalwizard_customer}</span>
											<Input readOnly={false} required={true} name="companyName" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + translations.proposalwizard_customer + "..."} value={(record && record.companyName ? record.companyName : "")} onChange={onChange} validationMessage={translations.general_validationmessage + " " + translations.proposalwizard_customer} />
										</label>
										<label className="k-form-field">
											<span>{translations.proposalwizard_address}</span>
											<Input readOnly={false} required={true} name="companyAddress" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + translations.proposalwizard_address + "..."} value={(record && record.companyAddress ? record.companyAddress : "")} onChange={onChange} validationMessage={translations.general_validationmessage + " " + translations.proposalwizard_address} />
										</label>
									</div>
									<div className="col-md-3">
										<div className={record.coverImageID == "00000000-0000-0000-0000-000000000000" ? "coverimages-selectedimage isrequired" : "coverimages-selectedimage"} onClick={selectCoverImage} style={{ backgroundColor: record.coverImage.backgroundColor == "Black" ? "black" : "transparent" }}><span className={record.coverImageID == "00000000-0000-0000-0000-000000000000" ? "deloitte-icon icon-download_from_cloud_special" : "hidden"}></span>
											{record.coverImageID != "00000000-0000-0000-0000-000000000000" && (
												<img src={"/api/content/files/get/" + record.coverImage.image.internalFilename + "/" + record.coverImage.image.filename} className={"img-responsive"} alt={record.coverImage.name} />
											)}
										</div>
										{coverImageSelectorVisible && (
											<div className="coverimages-popup">
												<div className="coverimages-arrow"></div>
												<div className="coverimages-container">
													{coverImages.length == 0 && (
														<div className="coverimages-noimages">
															Please select an industry and service first.
														</div>	
													)}
													{coverImages.map((coverImage: any) => (
														<div className="coverimages-imageblock" key={coverImage.id} onClick={() => {
															changeCoverImage(coverImage);
														}}>
															<img src={"/api/content/files/get/" + coverImage.image.internalFilename + "/" + coverImage.image.filename} className="img-responsive" alt={coverImage.name} />
														</div>
													))}
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<label className="k-form-field">
											<span>{translations.proposalwizard_titlefield}</span>
											<Input name="title" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + translations.proposalwizard_titlefield + "..."} value={(record && record.title ? record.title : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " " + translations.proposalwizard_titlefield} />
										</label>
									</div>
									<div className="col-md-6">
										<label className="k-form-field">
											<span>{translations.proposalwizard_subtitle}</span>
											<Input name="subtitle" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + translations.proposalwizard_subtitle + "..."} value={(record && record.subtitle ? record.subtitle : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " " + translations.proposalwizard_subtitle} />
										</label>
									</div>
								</div>
								<div className="row">
									<div className="col-md-5">
										<label className="k-form-field">
											<span>{translations.proposalwizard_signingpartner}</span>
											<ComboBox
												data={partners}
												name="signingPartnerID"
												style={{ width: "100%" }}
												dataItemKey="userID"
												textField="fullName"
												onChange={onChange}
												required={true}
												suggest={true}
											/>
										</label>
									</div>
									<div className="col-md-5">
										<label className="k-form-field">
											<span>{translations.proposalwizard_engagementpartner}</span>
											<ComboBox
												data={partners}
												name="engagementPartnerID"
												style={{ width: "100%" }}
												dataItemKey="userID"
												textField="fullName"
												onChange={onChange}
												required={true}
												suggest={true}
											/>
										</label>
									</div>
									<div className="col-md-2">
										<label className="k-form-field">
											<span>{translations.proposalwizard_expectedrevenue}</span>
											<NumericTextBox name="expectedRevenue" width={"100%"} className={record && record.expectedRevenue > 0 ? " " : "k-state-invalid" } placeholder={translations.general_placeholder_prefix + " " + translations.proposalwizard_expectedrevenue + "..."} value={(record && record.expectedRevenue ? record.expectedRevenue : 0)} onChange={onChange} />
										</label>
									</div>
								</div>
								<div className="row">
									<div className="col-md-4">
										<label className="k-form-field">
											<span>{translations.proposalwizard_language}</span>
											<ComboBox
												data={languages}
												name="languageID"
												style={{ width: "100%" }}
												dataItemKey="id"
												textField="name"
												onChange={onChange}
												required={true}
												suggest={true}
											/>
										</label>
									</div>
									<div className="col-md-3">
										<label className="k-form-field">
											<span>{translations.proposalwizard_proposaldate}</span>
											<DatePicker name="proposalDate" width={"100%"} value={(record && record.proposalDate ? record.proposalDate : new Date())} onChange={onChange} format="yyyy/MM/dd" />
										</label>
									</div>
									<div className="col-md-3">
										<label className="k-form-field">
											<span>{translations.proposalwizard_deadlinedate}</span>
											<DatePicker name="deadlineDate" width={"100%"} value={(record && record.deadlineDate ? record.deadlineDate : new Date())} onChange={onChange} format="yyyy/MM/dd" />
										</label>
									</div>
									<div className="col-md-2">
										<label className="k-form-field">
											<span>{translations.general_orientation}</span>
											{/*
											// @ts-ignore*/}
											<Switch name="landscape" checked={record.landscape} onChange={onChange} onLabel="Landscape" offLabel="Portrait" className="page-orientation-switch"></Switch>
										</label>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<label className="k-form-field">
											<span>{translations.proposalwizard_industry}</span>
											<ComboBox
												data={industries}
												name="industryID"
												style={{ width: "100%" }}
												dataItemKey="id"
												textField="name"
												onChange={onChange}
												required={true}
												suggest={true}
											/>
										</label>
									</div>
									<div className="col-md-6">
										<label className="k-form-field">
											<span>{translations.proposalwizard_service}</span>
											<ComboBox
												data={services}
												name="serviceID"
												style={{ width: "100%" }}
												dataItemKey="id"
												textField="name"
												onChange={onChange}
												required={true}
												suggest={true}
											/>
										</label>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div className={selectedTab === 1 ? "" : "hidden"}>
						<h2>{translations.proposalwizard_team_title}</h2>
						<div className="wizard-gridpanel">
							{selectedTab === 1 ? <GridPanel reloadKey="reloadTeam" loadData={getTeamMembers} filterable={false} sortable={false} key={teamMembers.length} pageable={false}>
								<GridToolbar >
									<div>
										<button title="Add new" className="k-button k-primary" onClick={() => {
											show(NewTeamMember, "newteammember", {
												save: saveNewTeamMember,
												cancel: () => {
													close("newteammember");
												}
											});
										}}>
											{translations.proposalwizard_team_addnew}
										</button>
									</div>
								</GridToolbar>
								<GridColumn field="user.firstName" title={translations.users_firstname} editable={false} />
								<GridColumn field="user.lastName" title={translations.users_lastname} editable={false} />
								<GridColumn field="user.function" title={translations.users_funtion} editable={false} />
								<GridColumn field="user.phone" title={translations.myprofile_phone} editable={false} />
								<GridColumn field="role" title={translations.roles_windowtitlesingle} editable={false} />
								<GridColumn filterable={false} width="120px" editable={false} title="Remove" cell={cellButton(removeTeamMember, "Remove", (rowRecord: any) => {
									return rowRecord.type !== "contributor"
								})} />
							</GridPanel> : null}
						</div>
					</div>
					<div className={selectedTab === 2 ? "" : "hidden"}>
						<h2>{translations.proposalwizard_contacts_title}</h2>
						<div className="wizard-gridpanel">
							{selectedTab === 2 ? <GridPanel reloadKey="reloadContacts" loadData={getContacts} filterable={false} sortable={false} key={contacts.length} pageable={false}>
								<GridToolbar >
									<div>
										<button title="Add new" className="k-button k-primary" onClick={() => {
											show(NewContact, "newcontact", {
												save: saveNewContact,
												cancel: () => {
													close("newcontact");
												}
											});
										}}>
											{translations.proposalwizard_contacts_addnew}
										</button>
									</div>
								</GridToolbar>
								<GridColumn field="salutation" title="Salutation" editable={false} />
								<GridColumn field="firstName" title={translations.users_firstname} editable={false} />
								<GridColumn field="lastName" title={translations.users_lastname} editable={false} />
								<GridColumn field="email" title={translations.users_email} editable={false} />
								<GridColumn field="mobile" title={translations.myprofile_mobile} editable={false} />
								<GridColumn filterable={false} width="120px" editable={false} title="Remove" cell={cellButton(removeContact, "Remove")} />
							</GridPanel> : null}
						</div>
					</div>
					<div className={selectedTab === 3 ? "" : "hidden"}>
						<h2>{translations.proposalwizard_template_title}</h2>
						<div className="wizard-templates-list">
							{
								templates.map((template: any) => (
									<div key={template.id} className={"wizard-template-container " + (record.templateID == template.id ? "active" : "")} onClick={() => { selectTemplate(template); }}>
										<div className="wizard-template-title">{template.title}</div>
										<div className="wizard-template-description">{template.shortDescription}</div>
									</div>
								))
							}
						</div>
						<div className="wizard-templates-preview">
							<h3>Preview</h3>
							<div className="wizard-templates-preview-container">
								{
									sections.map((section: any) => (
										<div key={section.id} className="wizard-template-preview-row">
											<div className={"wizard-template-preview-icon " + section.icon}></div>
											<div className="wizard-template-preview-description">{section.name}</div>
										</div>
									))
								}
							</div>
						</div>
					</div>
					<div className={selectedTab === 4 ? "tab-fixed-height" : "tab-fixed-height hidden"}>
						<h2>{translations.proposalwizard_letter_title}</h2>
						<div ref={ref} className="tab-fixed-height-content">
							<form className="k-form">
								<div className="row">
									<div className="col-md-9">
										<label className="k-form-field">
											<span>Choose a welcomeletter</span>
											<ComboBox
												data={welcomeLetters}
												name="welcomeletterID"
												style={{ width: "100%" }}
												dataItemKey="id"
												textField="name"
												onChange={onChange}
												required={true}
											/>
										</label>
									</div>
									<div className="col-md-3">
										<label className="k-form-field">
											<span>{translations.proposalwizard_hidewelcomeletter}</span>
											{/*
											// @ts-ignore*/}
											<Switch name="hideWelcomeLetter" checked={record.hideWelcomeLetter} onChange={onChange}></Switch>
										</label>
									</div>
								</div>
								<br />
								<FroalaEditorComponent config={editorConfig} model={record.welcomeLetter} onModelChange={changeContent}></FroalaEditorComponent>
							</form>
						</div>
					</div>
				</div>
				<div className="wizard-buttonbar">
					{selectedTab > 0 && (<div className="wizard-button wizard-goback" onClick={() => setSelectedTab(selectedTab - 1)}>{translations.proposalwizard_buttons_previous}</div>)}
					{selectedTab < 4 && (<div className="wizard-button wizard-next" onClick={goToNextTab}>{translations.proposalwizard_buttons_next}</div>)}
					{selectedTab === 4 && (<div className="wizard-button wizard-save" onClick={save}>{translations.proposalwizard_buttons_save}</div>)}
				</div>
			</div>
		</div>
	);
}

export default Wizard;
