import axios from 'axios';
import { getToken } from '../../services/authentication';
import { useState, useEffect } from 'react';

const baseURL = "/api/settings/security/users/";

const repository = {
	getAll: async (conf: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "getall", conf, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	getActive: async () => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "getactive", {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	getActivePartners: async () => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "getactivepartners", {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	searchActive: async (search?: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "searchactive?search=" + search, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	getById: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "get/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	save: async (record: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "save", record, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
};

export default repository;

export const useUsers = () => {
	const [users, setUsers] = useState([]);
	useEffect(() => {
		let isSame = true;
		repository.getActive().then((result) => {
			if (isSame) setUsers(result);
		});
		return () => { isSame = false; }
	}, []);
	return users;
};

export const usePartners = () => {
	const [partners, setPartners] = useState([]);
	useEffect(() => {
		let isSame = true;
		repository.getActivePartners().then((result) => {
			if (isSame) setPartners(result);
		});
		return () => { isSame = false; }
	}, []);
	return partners;
};
