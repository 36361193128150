import React, { useState, useEffect } from 'react';
import { NavLink as Link } from "react-router-dom";
import '../../../assets/css/menubar.scss';
import { logOut } from '../../../services/authentication';
import { isAuthorized } from '../../../services/authorization';
import translations from '../../../translations';

const Menu: React.FC = () => {
	
	const [menuBarOpen, setMenuBarOpen] = useState(false);
	const [hoverMenuItem, setHoverMenuItem] = useState();

	const menuItems = [
		{ 
			icon: "deloitte-icon icon-house_special",
			label: translations.menu_home,
			url: "/",
			exact: true
		},
		{ 
			icon: "deloitte-icon icon-document_2_special",
			label: translations.menu_proposals,
			url: "/proposals",
			securityKey: "Proposals"
		},
		{ 
			icon: "deloitte-icon icon-list_special",
			label: translations.menu_tasks,
			url: "/tasks",
			securityKey: ""
		},
		{ 
			icon: "deloitte-icon icon-file_edit_special",
			label: translations.menu_content,
			url: "/content",
			securityKey: "Sections|Templates|WelcomeLetters|Publications|LegalDocuments|Credentials|CoverImages"
		},
		{ 
			icon: "deloitte-icon icon-cog_special",
			label: translations.menu_settings,
			url: "/settings",
			securityKey: "BusinessUnits|Industries|Offers|Languages"
		},
		{ 
			icon: "deloitte-icon icon-shield_special",
			label: translations.menu_security,
			url: "/security",
			securityKey: "Users|Roles|MonitoringLogging|ErrorLogging"
		},
	]

	return (
		<div className={ "sidebar-left-container " +  (menuBarOpen ? "open" : "") } >
			<div className="menu-container" onMouseLeave={() => { setHoverMenuItem(undefined); } }>
				<div className="main-menu">
					<ul onMouseEnter={() => { setMenuBarOpen(true); } }  onMouseLeave={() => {setMenuBarOpen(false); } }>
						{ 
							menuItems.map((item, i) => {
								return (item.securityKey === "" || !item.securityKey || isAuthorized(item.securityKey, "*") ? (
									<Link to={item.url} exact={item.exact} key={i} activeClassName="active">
										<li className={(hoverMenuItem && hoverMenuItem.icon === item.icon ? "hover" : "")} onMouseEnter={() => { setMenuBarOpen(true); setHoverMenuItem(item); }} onClick={() => { setMenuBarOpen(false); }} >
											<div className="highlight"></div>
											<i className={item.icon} aria-hidden="true"></i>
										</li>
									</Link>) : null);
							})
						}
					</ul>
					<div className="menu-logout" onClick={logOut}>
						<div className="highlight"></div>
						<i className="deloitte-icon icon-exit_special" aria-hidden="true"></i>
						<div className="menu-logout-tooltip">{translations.menu_logout}</div>
					</div>
				</div>
				<div className="sub-menu" onMouseEnter={() => {setMenuBarOpen(true); } }  onMouseLeave={() => {setMenuBarOpen(false); } }>
					<div className="sub-menu-title">
						<ul>
							{ 
								menuItems.map((item, i) => {
									return (item.securityKey === "" || !item.securityKey || isAuthorized(item.securityKey, "*") ? (
										<Link to={item.url} exact={item.exact} key={i} activeClassName="active">
											<li className={ (hoverMenuItem && hoverMenuItem.icon === item.icon ? "hover" : "") } onMouseEnter={() => { setHoverMenuItem(item); } } onClick={ () => { setMenuBarOpen(false); } } >
												{ item.label }
											</li>
										</Link>) : null);
								})
							}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Menu;
