///<reference path= "../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect } from 'react';
//@ts-ignore
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import repoSections from '../../../repositories/settings/sections';
import _ from 'lodash';
import { confirmChanged } from '../../../services/renderer';
import translations from '../../../translations';

interface NewProps {
	save: (record: any) => void,
	cancel: () => void,
	sectionQuery: Query,
	sectionTypes: Array<any>,
	showEmptySection?: boolean
}

interface Query {
	languageID: string,
	businessUnitID: string,
	landscape: boolean,
	industryIDs: Array<string>,
	serviceIDs: Array<string>
}

const New: React.FC<NewProps> = (props) => {

	const [dataChanged] = useState(false);
	const [sectionTypes, setSectionTypes] = useState(new Array());

	useEffect(() => {
		repoSections.getActive(props.sectionQuery).then((sections) => {
			var newSectionListing = new Array();
			if (props.showEmptySection === true) {
				newSectionListing.push({
					key: Date.now().toString(),
					type: "Static",
					sectionID: "",
					name: "Empty section",
					description: "Use this section for additional content.",
					content: "",
					icon: "deloitteicon-18",
					selected: false
				});
				newSectionListing.push({
					key: "Attachments" + Date.now().toString(),
					type: "Attachments",
					sectionID: null,
					name: "Empty attachments section",
					description: "Use this section for attachments.",
					icon: "deloitteicon-18",
					selected: false
				});
			}
			newSectionListing = newSectionListing.concat(sections.map((section: any) => {
				return {
					key: section.id,
					type: "Static",
					sectionID: section.id,
					name: section.name,
					description: section.description,
					icon: section.icon,
					selected: false
				}
			}));
			newSectionListing = newSectionListing.concat([
				{
					key: "Resumes",
					type: "Resumes",
					sectionID: null,
					name: translations.sections_dynamic_resumes_name,
					description: translations.sections_dynamic_resumes_description,
					icon: "deloitteicon-15",
					selected: false
				},
				{
					key: "LegalDocuments",
					type: "LegalDocuments",
					sectionID: null,
					name: translations.sections_dynamic_legaldocuments_name,
					description: translations.sections_dynamic_legaldocuments_description,
					icon: "deloitteicon-37",
					selected: false
				},
				{
					key: "IconCredentials",
					type: "IconCredentials",
					sectionID: null,
					name: translations.sections_dynamic_iconcredentials_name,
					description: translations.sections_dynamic_iconcredentials_description,
					icon: "deloitteicon-103",
					selected: false
				},
				{
					key: "ShortCredentials",
					type: "ShortCredentials",
					sectionID: null,
					name: translations.sections_dynamic_shortcredentials_name,
					description: translations.sections_dynamic_shortcredentials_description,
					icon: "deloitteicon-103",
					selected: false
				},
				{
					key: "LongCredentials",
					type: "LongCredentials",
					sectionID: null,
					name: translations.sections_dynamic_longcredentials_name,
					description: translations.sections_dynamic_longcredentials_description,
					icon: "deloitteicon-103",
					selected: false
				},
				{
					key: "ShortAchievements",
					type: "ShortAchievements",
					sectionID: null,
					name: translations.sections_dynamic_shortachievements_name,
					description: translations.sections_dynamic_shortachievements_description,
					icon: "deloitteicon-53",
					selected: false
				},
				{
					key: "LongAchievements",
					type: "LongAchievements",
					sectionID: null,
					name: translations.sections_dynamic_longachievements_name,
					description: translations.sections_dynamic_longachievements_description,
					icon: "deloitteicon-53",
					selected: false
				},
				{
					key: "Publications",
					type: "Publications",
					sectionID: null,
					name: translations.sections_dynamic_publications_name,
					description: translations.sections_dynamic_publications_description,
					icon: "deloitteicon-52",
					selected: false
				},
			]);
			newSectionListing = _.differenceBy(newSectionListing, props.sectionTypes, "key");
			setSectionTypes(newSectionListing);
		});
	}, []);

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		props.save(sectionTypes.filter((sectionType: any) => sectionType.selected));
	}

	function pickSectionType(sectionType: any) {
		props.save([ sectionType ]);
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	function selectSectionType(sectionType: any) {
		sectionType.selected = !sectionType.selected;
		setSectionTypes([...sectionTypes]);
	}

	return (
		<Dialog
			onClose={cancel}
			title={translations.templatesections_new}
			width="60%"
		>
			<form className="k-form" onSubmit={onSubmit}>
				<div>{translations.templatesections_new_message}</div>
				<div>&nbsp;</div>
				<div className="sectionListing">
					{
						sectionTypes.map((sectionType: any) => (<div key={sectionType.key} className={"sectionListingEntry " + (sectionType.type == "Static" ? "static " : "dynamic ") + (sectionType.selected ? "active" : "")} onClick={() => {
							selectSectionType(sectionType);
						}} onDoubleClick={() => {
							pickSectionType(sectionType);
						}}>
							<span className={sectionType.icon}></span>
							<div className="sectionListingEntryName">{sectionType.name}</div>
							<div className="sectionListingEntryDescription">{sectionType.description}</div>
						</div>
						))
					}
				</div>
			</form>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				<button className="k-button k-primary" onClick={save}>
					{translations.templatesections_new_addbutton}
				</button>
			</DialogActionsBar>
		</Dialog>
	);
}

New.defaultProps = {
	save: (record: any) => { },
	cancel: () => { }
};

export default New;