///<reference path= "../../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect } from 'react';
//@ts-ignore
import FroalaEditor from 'froala-editor';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { show, close, showLoader, hideLoader, confirm } from '../../../../services/renderer';
import repoUsers from '../../../../repositories/security/users';
import repoRoles from '../../../../repositories/security/roles';
import repoCountryLanguages from '../../../../repositories/settings/countrylanguages';
import repoUserResumes from '../../../../repositories/security/userresumes';
import { getMyProfile } from '../../../../services/authentication';
import sectionIcons from '../../../../services/sectionicons';
import _ from 'lodash';
import { alert } from '../../../../services/notification';
import translations from '../../../../translations';
import EditResume from './editresume';
import { isAuthorized } from '../../../../services/authorization';
import { preventDoubleClick } from '../../../../services/tools';

interface EditProps {
	dataItem: any,
	save: (record: any) => void,
	cancel: () => void
}

const Edit: React.FC<EditProps> = (props) => {

	const [record, setRecord] = useState(props.dataItem || null);
	const [selectedTab, setSelectedTab] = useState(0);
	const [roles, setRoles] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [resumes, setResumes] = useState([]);

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		props.save(record);
	}

	function cancel() {
		//if (dataChanged) {
		//	confirm("Continue?", "You have unsaved data, are you sure you want to leave without saving your changes?", () => { props.cancel(); }, "Yes", () => { }, "No");
		//} else {
		props.cancel();
		//}
	}

	function selectAllRoles(e: any) {
		let userRecord = { ...record };
		userRecord.userRoles = [];
		if (e.value) {
			roles.forEach((role: any) => {
				userRecord.userRoles.push({ roleID: role.id, userID: userRecord.userID });
			});
		}
		setRecord(userRecord);
	}

	function allRolesSelected() {
		return (roles && record && record.userRoles ? roles.length === record.userRoles.length : false);
	}

	function isRoleActive(role: any) {
		const matchedRole = (record.userRoles).find((userRole: any) => { return userRole.roleID === role.id });
		return (matchedRole ? true : false);
	}

	function changeRole(e: any) {
		let userRecord = { ...record };
		if (e.value) {
			userRecord.userRoles.push({ roleID: e.target.props.model.id, userID: userRecord.userID });
		} else {
			const matchedRole = (userRecord.userRoles).find((userRole: any) => { return userRole.roleID === e.target.props.model.id });
			if (matchedRole) {
				userRecord.userRoles.splice(userRecord.userRoles.indexOf(matchedRole), 1);
			}
		}
		setRecord(userRecord);
	}

	async function fetchData() {
		var user = await repoUsers.getById(props.dataItem.userID);
		setRecord(user);
		if (isAuthorized("Users", "UpdateRoles")) {
			var rolesFromRepo = await repoRoles.getAll({});
			setRoles(rolesFromRepo.data);
		}
		if (isAuthorized("Users", "UpdateCvs")) {
			repoCountryLanguages.getActive().then((resultLanguages) => {
				repoUserResumes.getAllForUser(record.userID).then((resultResumes) => {
					resultLanguages.forEach((language: { id: string }) => {
						var foundResume = _.find(resultResumes, {
							languageID: language.id
						});
						if (!foundResume) {
							var newResume = {
								languageID: language.id,
								userID: record.userID,
								content: ""
							};
							resultResumes.push(newResume);
						}
					});
					setResumes(resultResumes);
					setLanguages(resultLanguages);
					hideLoader();
				});
			});
		}
	}

	function editResume(languageID: string) {
		let resumeToEdit: any = _.find(resumes, {
			languageID: languageID
		});
		show(EditResume, "editresume", {
			save: (resume: any) => {
				showLoader();
				resumeToEdit.content = resume.resume;
				repoUserResumes.saveForUser(record.userID, resumeToEdit).then((userResume) => {
					resumeToEdit.id = userResume;
					hideLoader();
					close("editresume");
				});
			},
			cancel: () => {
				close("editresume");
			},
			dataItem: {
				fullName: record.firstName + ' ' + record.lastName,
				resume: resumeToEdit.content
			}
		})
	}

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<Dialog
			onClose={cancel}
			title={translations.general_edit + " " + (record && record.firstName ? record.firstName + " " : "") + (record && record.lastName ? record.lastName : "")}
			width="70%"
		>
			<div className="tab-bar">
				<ul>
					<li className={selectedTab === 0 ? "active" : ""} onClick={() => { setSelectedTab(0); }}>{translations.general_tab_details}</li>
					{isAuthorized("Users", "UpdateRoles") ? <li className={selectedTab === 1 ? "active" : ""} onClick={() => { setSelectedTab(1); }}>{translations.users_tab_roles}</li> : null}
					{isAuthorized("Users", "UpdateCvs") ? <li className={selectedTab === 2 ? "active" : ""} onClick={() => { setSelectedTab(2); }}>{translations.users_tab_resumes}</li> : null}
				</ul>
			</div>
			<div className="tab-bar-container">
				<div className={selectedTab === 0 ? "" : "hidden"}>
					<div className="row myprofile-overview" style={{ padding: "20px" }} >
						<div className="col-md-3">
							<div className="myprofile-image">
								{record.image.size > 0 ? <img src={"/api/content/files/get/" + record.image.internalFilename + "/" + record.image.filename}></img> : <i className="deloitte-icon icon-profile_special" aria-hidden="true"></i>}
							</div>
						</div>
						<div className="col-md-9">
							<div className="col-12 myprofile-name">{record.firstName} {record.lastName}</div>

							<div className="myprofile-fields">
								<div className="col-12 myprofile-field">{translations.myprofile_function} <span className="myprofile-fieldvalue">{record.function}</span></div>
								<div className="col-12 myprofile-field">{translations.myprofile_businessunit} <span className="myprofile-fieldvalue">{record.businessUnit ? record.businessUnit.name : ""}</span></div>
								<div className="col-12 myprofile-field">{translations.myprofile_location} <span className="myprofile-fieldvalue">{record.location}</span></div>

								<div className="col-12 myprofile-spacer">&nbsp;</div>

								<div className="col-12 myprofile-field">{translations.myprofile_email} <span className="myprofile-fieldvalue">{record.email}</span></div>
								<div className="col-12 myprofile-field">{translations.myprofile_phone} <span className="myprofile-fieldvalue">{record.phone}</span></div>
								<div className="col-12 myprofile-field">{translations.myprofile_mobile} <span className="myprofile-fieldvalue">{record.mobile}</span></div>
							</div>
						</div>
					</div>
				</div>
				{isAuthorized("Users", "UpdateRoles") ? <div className={selectedTab === 1 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allRolesSelected()} onChange={selectAllRoles} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{roles.map((role: any) => (
							<div className="row spaced-row switch-row" key={role.id}>
								<div className="col-md-11">
									<span className={role.checked ? "checked-row" : ""}>{role.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={role} checked={isRoleActive(role)} onChange={changeRole} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div> : null}
				{isAuthorized("Users", "UpdateCvs") ? <div className={selectedTab === 2 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						{languages.map((language: any) => (
							<div key={language.id} className={"row rowhiglighter"}>
								<div className="col-md-9">
									<h4 style={{color: _.find(resumes, (resume: any) => { return resume.id && resume.languageID == language.id }) ? "green" : "red"}}>{language.name}</h4>
								</div>
								<div className="col-md-3">
									<div className="rowhiglighter-button" onClick={() => { editResume(language.id); }}><span className="deloitte-icon icon-edit_profile_special"></span></div>
								</div>
							</div>
						))}
					</form>
				</div> : null}
			</div>

			<form className="k-form no-spaced-form" onSubmit={onSubmit}>

			</form>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				{isAuthorized("Users", "UpdateRoles") ? <button className="k-button k-primary" onClick={() => preventDoubleClick(save)}>
					{translations.general_save}
				</button> : null}
			</DialogActionsBar>
		</Dialog>
	);
}

Edit.defaultProps = {
	dataItem: null,
	save: (record: any) => { },
	cancel: () => { }
};

export default Edit;