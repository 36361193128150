import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Grid } from '@progress/kendo-react-grid';
import { addListener, removeListener } from '../../services/listeners';
import { Guid } from "guid-typescript";

let delayGetData: NodeJS.Timeout;
let delayResize: NodeJS.Timeout;

interface GridPanelProps {
	parentID?: string,
	loadData: (conf: any, parentID?: string) => Promise<any>,
	reloadKey: string,
	filter?: any,
	filterable?: boolean,
	sortable?: boolean,
	pageable?: boolean,
	onRowDoubleClick?: (dataItem: any) => void
}

const GridPanel: React.FC<GridPanelProps> = (props) => {

	const [loading, setLoading] = useState(false);
	const [gridTotal, setGridTotal] = useState(0);
	const [gridState, setGridState] = useState({ skip: 0, take: 10 });
	const [gridData, setGridData] = useState([]);
	const ref: any = useRef(null);
	const [height, setHeight] = useState(0);

	function dataStateChange(e: any) {
		var newState = e.data;
		//Set gridState equal to e.data
		setGridState(newState);
		//Call getData with new gridState
		if (newState.filter) {
			clearTimeout(delayGetData);
			delayGetData = setTimeout(() => { getData(newState); }, 500);
		} else {
			getData(newState);
		}
	}

	function getData(conf?: any) {
		//Show loading icon
		setLoading(true);
		//Call API
		(props.parentID ? props.loadData(conf, props.parentID) : props.loadData(conf)).then((result: any) => {
			//Set total record count
			setGridTotal(result.total);
			//Set grid data
			setGridData(result.data.map((item: any) => Object.assign({ inEdit: true }, item)));
			//Hide loading icon
			setLoading(false);
		}).catch((error: any) => {
			//Hide loading icon
			console.log(error);
			setLoading(false);
		});
	}

	function reloadData() {
		getData(gridState);
	}

	//Initial data fetch
	useEffect(() => {
		if (props.filter) {
			var newGridState = { ...gridState, filter: props.filter };
			setGridState(newGridState);
		}
		window.addEventListener("resize", changeHeight);
		return () => {
			window.removeEventListener("resize", changeHeight);
		};
	}, []);

	useEffect(() => {
		addListener(props.reloadKey, reloadData);
		return () => {
			removeListener(props.reloadKey);
		};
	}, [gridState]);

	function changeHeight() {
		clearTimeout(delayResize);
		delayResize = setTimeout(() => {
			if (ref && ref.current) {
				var newHeight = ref.current.clientHeight;
				if (ref.current.getElementsByClassName("k-grid-container").length > 0) {
					newHeight = ref.current.getElementsByClassName("k-grid-container")[0].clientHeight;
				}
				setHeight(newHeight);
			}
		}, 500);
	}


	useLayoutEffect(() => {
		changeHeight();
	});

	useEffect(() => {
		var innerHeight = height;
		var avgNumOfRows = Math.floor(innerHeight / 45);
		if (avgNumOfRows > 0) {
			var newSkip = Math.floor(gridState.skip / avgNumOfRows) * avgNumOfRows;
			var newGridState = { ...gridState, skip: newSkip, take: avgNumOfRows };
			setGridState(newGridState);
			getData(newGridState);
		}
	}, [height]);

	const rowRender = (trElement: any, dataItem: any) => {
		const trProps = {
			...trElement.props,
			onDoubleClick: () => {
				if (props.onRowDoubleClick && dataItem.dataItem) props.onRowDoubleClick(dataItem.dataItem);
			},
			onContextMenu: (e: any) => {
				e.preventDefault();
				// Do not yet anything
			},
		};
		return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
	};

	return (
		<div>
			{loading &&
				<div className="k-loading-mask">
					<span className="k-loading-text">Loading</span>
					<div className="k-loading-image"></div>
					<div className="k-loading-color"></div>
				</div>
			}
			<div ref={ref} style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
				<Grid
					style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
					className="grid-fullscreen"
					total={gridTotal}
					{...gridState}
					onDataStateChange={dataStateChange}
					data={gridData}
					selectedField="selected"
					filterable={props.filterable != undefined ? props.filterable : true}
					sortable={props.sortable != undefined ? props.sortable : true}
					editField="inEdit"
					pageable={props.pageable !== false ? {} : false}
					rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
					//pageSize={currentPageSize}
				>
					{props.children}
				</Grid>
			</div>
		</div>
	);
};

export default GridPanel;