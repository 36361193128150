import React, { useState, useEffect } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { confirmChanged } from '../../../services/renderer';
import translations from '../../../translations';
import { notify, alert } from '../../../services/notification';

interface NewProps {
	save: (record: any) => void,
	cancel: () => void,
	dataItem?: any
}

const New: React.FC<NewProps> = (props) => {

	const [record, setRecord] = useState({
		id: props.dataItem && props.dataItem.id ? props.dataItem.id : "00000000-0000-0000-0000-000000000000",
		salutation: props.dataItem && props.dataItem.salutation ? props.dataItem.salutation : "",
		firstName: props.dataItem && props.dataItem.firstName ? props.dataItem.firstName : "",
		lastName: props.dataItem && props.dataItem.lastName ? props.dataItem.lastName : "",
		email: props.dataItem && props.dataItem.email ? props.dataItem.email : "",
		mobile: props.dataItem && props.dataItem.mobile ? props.dataItem.mobile : ""
	});
	const [dataChanged, setDataChanged] = useState(false);

	function onChange(event: any) {
		let newRecord = { ...record };
		// @ts-ignore
		newRecord[event.target.props.name] = event.target.value;
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		if (record.firstName.trim() != "" && record.lastName.trim() != "" && record.email.trim() != "" && record.mobile.trim() != "") {
			const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/gm;
			if (regex.test(record.mobile.trim())) {
				props.save(record);
			} else {
				alert("You need to enter a correct mobile phone number!");
			}
		} else {
			alert("You need to fill in all required fields!");
		}
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	return (
		<Dialog onClose={cancel} title={props.dataItem ? translations.general_edit : translations.general_new} width={400}>
			<form className="k-form" onSubmit={onSubmit}>
				<label className="k-form-field">
					<span>Salutation</span>
					<Input name="salutation" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + "Salutation" + "..."} value={(record && record.salutation ? record.salutation : "")} onChange={onChange} />
				</label>
				<label className="k-form-field">
					<span>First name</span>
					<Input name="firstName" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + "First name" + "..."} value={(record && record.firstName ? record.firstName : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " " + "First name"} />
				</label>
				<label className="k-form-field">
					<span>Last name</span>
					<Input name="lastName" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + "Last name" + "..."} value={(record && record.lastName ? record.lastName : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " " + "Last name"} />
				</label>
				<label className="k-form-field">
					<span>Email</span>
					<Input name="email" type="email" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + "Email" + "..."} value={(record && record.email ? record.email : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " " + "Email"} />
				</label>
				<label className="k-form-field">
					<span>Mobile</span>
					<Input name="mobile" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + "Mobile" + "..."} value={(record && record.mobile ? record.mobile : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " " + "Mobile"} />
				</label>
			</form>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>{translations.general_cancel}</button>
				<button className="k-button k-primary" onClick={save}>{translations.general_save}</button>
			</DialogActionsBar>
		</Dialog>
	);
}

New.defaultProps = {
	save: (record: any) => { },
	cancel: () => { }
};

export default New;