import React, { useState, useEffect } from 'react';
import GridPanel from '../../../global/gridpanel';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import cellButton from "../../../global/cellbutton";
import Edit from "./edit";
import { show, close, showLoader, hideLoader } from '../../../../services/renderer';
import repoRoles from '../../../../repositories/security/roles';
import { notify, alert } from '../../../../services/notification';
import { triggerListeners } from '../../../../services/listeners';
import { cloneObject } from '../../../../services/tools';
import { isAuthorized } from '../../../../services/authorization';
import translations from '../../../../translations';

const List: React.FC = (props) => {

	function edit(dataItem: any) {
		show(Edit, "rolesedit", {
			dataItem: cloneObject(dataItem),
			save: save,
			cancel: cancel,
		});
	}

	function cancel() {
		close("rolesedit");
		close("rolesnew");
	}


	function save(record: any) {
		showLoader();
		repoRoles.save(record).then(result => {
			notify(translations.general_savedsuccess);
			triggerListeners("reloadRoles");
			cancel();
			hideLoader();
		}).catch((error: any) => {
			hideLoader();
			alert(error);
		});
	}

	return (
		<div>
			<GridPanel reloadKey="reloadRoles" loadData={repoRoles.getAll} onRowDoubleClick={edit}>
				<GridColumn field="name" title={translations.roles_name} editable={false} />
				{isAuthorized("Roles", "Update") ? <GridColumn filterable={false} width="120px" title={translations.general_edit} cell={cellButton(edit, translations.general_edit)} /> : null}
			</GridPanel>
		</div>
	);
}

export default List;