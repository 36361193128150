import React, { useState, useEffect } from 'react';
import GridPanel from '../../../global/gridpanel';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { show, close, showLoader, hideLoader } from '../../../../services/renderer';
import repoUsers from '../../../../repositories/security/users';
import { notify, alert } from '../../../../services/notification';
import { triggerListeners } from '../../../../services/listeners';
import { cloneObject } from '../../../../services/tools';
import cellButton from "../../../global/cellbutton";
import Edit from "./edit";
import { isAuthorized } from '../../../../services/authorization';
import translations from '../../../../translations';

const List: React.FC = (props) => {

	function edit(dataItem: any) {
		show(Edit, "usersedit", {
			dataItem: cloneObject(dataItem),
			save: save,
			cancel: cancel,
		});
	}

	function cancel() {
		close("usersedit");
	}

	function save(record: any) {
		showLoader();
		repoUsers.save(record).then(result => {
			notify(translations.general_savedsuccess);
			triggerListeners("reloadUsers");
			cancel();
			hideLoader();
		}).catch((error: any) => {
			hideLoader();
			alert(error);
		});
	}

	return (
		<div>
			<GridPanel reloadKey="reloadUsers" loadData={repoUsers.getAll} filter={{
				logic: "and",
				filters: [
					{
						field: "active",
						operator: "eq",
						value: true
					}
				]
			}} onRowDoubleClick={edit}>
				<GridColumn field="firstName" title={translations.users_firstname} editable={false} />
				<GridColumn field="lastName" title={translations.users_lastname} editable={false} />
				<GridColumn field="email" title={translations.users_email} editable={false} />
				<GridColumn field="function" title={translations.users_funtion} editable={false} />
				<GridColumn field="location" title={translations.users_location} editable={false} />
				<GridColumn field="active" title={translations.general_active} width="125px" editor="boolean" filter={'boolean'} />
				{isAuthorized("Users", "UpdateRoles|UpdateCvs") ? <GridColumn filterable={false} width="120px" editable={false} title={translations.general_edit} cell={cellButton(edit, translations.general_edit)} /> : null }
			</GridPanel>
		</div>
	);
}

export default List;