import React, { useState, useEffect } from 'react';
import { Route, NavLink as Link } from "react-router-dom";
import BusinessUnitsList from './businessunits/list';
import IndustriesList from './industries/list';
import ServicesList from './services/list';
import LanguagesList from './languages/list';
import { isAuthorized } from '../../../services/authorization';
import translations from '../../../translations';

const Settings: React.FC = (props: any) => {

	const items = [		
		{ 
			label: translations.businessunits_windowtitle,
			url: "/businessunits",
			securityKey: "BusinessUnits",
			key: "businessunits"
		},
		{ 
			label: translations.industries_windowtitle,
			url: "/industries",
			securityKey: "Industries",
			key: "industries"
		},
		{ 
			label: translations.services_windowtitle,
			url: "/services",
			securityKey: "Offers",
			key: "services"
		},	
		{ 
			label: translations.languages_windowtitle,
			url: "/languages",
			securityKey: "Languages",
			key: "languages"
		},
		/*{ 
			label: translations.salutations_windowtitle,
			url: "/salutations",
			securityKey: "Salutations"
		},*/
	]

	return (
		<div>
			<div className="top-bar">
				<ul>
					{
						items.map( (item, i) => {
							return (item.securityKey === "" || !item.securityKey || isAuthorized(item.securityKey, "*") ? (
								<Link to={props.match.url + item.url} key={i} activeClassName="active"><li> {item.label} </li></Link>
							) : null);
						})
					}
				</ul>
			</div>
			<div className="content-container">
				<Route path={props.match.url}>
					<div className="pages-general pages-settings">
						<h1>Settings</h1>
						<div className="pages-general-row">
							{items.map((item, i) => {
								return (item.securityKey === "" || !item.securityKey || isAuthorized(item.securityKey, "*") ? (
									<Link to={props.match.url + item.url} key={i}>
										<div className={"pages-general-block pages-settings-" + item.key}>
											<span>{item.label}</span>
										</div>
									</Link>
								) : null);
							})}
						</div>
					</div>
				</Route>
				{isAuthorized("BusinessUnits", "*") && (<Route path={props.match.url + "/businessunits"} component={BusinessUnitsList} />)}
				{isAuthorized("Industries", "*") && (<Route path={props.match.url + "/industries"} component={IndustriesList} />)}
				{isAuthorized("Offers", "*") && (<Route path={props.match.url + "/services"} component={ServicesList} />)}
				{isAuthorized("Languages", "*") && (<Route path={props.match.url + "/languages"} component={LanguagesList} />)}
			</div>
		</div>
	);
}

export default Settings;
