import axios from 'axios';
import { getToken } from '../../services/authentication';
import _ from 'lodash';

const baseURL = "/api/security/";

const repository = {
	getAll: async (conf: any) => {
		try {
			var accessToken = await getToken();
			if (!conf.sort) conf.sort = [{ field: "date", dir: "desc" }];
			let gridConf = _.cloneDeep(conf);
			if (gridConf.filter && gridConf.filter.filters && gridConf.filter.filters.length > 0) {
				for (let i = gridConf.filter.filters.length - 1; i >= 0; i--) {
					if (gridConf.filter.filters[i].field == "date" && gridConf.filter.filters[i].operator == "eq") {
						var selectedDate = new Date(gridConf.filter.filters[i].value);
						var untilDate = new Date(gridConf.filter.filters[i].value);
						untilDate.setDate(selectedDate.getDate() + 1);
						gridConf.filter.filters.splice(i, 1);
						gridConf.filter.filters.push({
							field: "date",
							operator: "gte",
							value: selectedDate
						});
						gridConf.filter.filters.push({
							field: "date",
							operator: "lte",
							value: untilDate
						});
					}
				}
			}
			var result = await axios.post(baseURL + "errorlog/getall", gridConf, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return { total: result.data.total, data: result.data.data.map((record: any) => { return { ...record, date: new Date(record.date) } }) };
		} catch (error) {
			throw error;
		}
	},
	getById: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "errorlog/get/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
};

export default repository;
