import axios from 'axios';
import config from '../config.json';
//@ts-ignore
import { getToken } from '../services/authentication';

let environment = {};

export const loadEnvironment = async () => {
	var environmentResult = await axios.get('/api/environment');
	environment = environmentResult.data;
	return true;
};

export const getEnvironmentData: any = () => {
	return environment;
};

const editorBase: any = {
	key: config.froala.key,
	attribution: false,
	enter: 2,
	wordPasteKeepFormatting: false,
	paragraphMultipleStyles: false,
	htmlRemoveTags: ['script', 'style', 'base'],
	pasteDeniedAttrs: ['class', 'id', 'style', 'width', 'height'],
	pastePlain: true,
	videoUpload: false,
};

export const minimalEditor: any = {
	...editorBase,
	htmlAllowedTags: [
		'br', 'div', 'p', 'span',
		'ul', 'ol', 'li',
		'strong', 'sub', 'sup', 'em', 'i', 'u', 'strike',
	],
	pasteDeniedTags: [
		'html', 'body', 'script',
		'frame', 'frameset', 'iframe',
		'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
		'a', 'b', 'blockquote', 'caption', 'font', 'style', 'hr',
		'img', 'svg', 'video', 'canvas', 'embed',
		'form', 'input', 'textarea', 'button', 'label',
		'table', 'tr', 'td'
	],
	imagePaste: false,
	imageUpload: false,
	toolbarButtons: {
		moreText: {
			buttons: [
				"bold",
				"italic",
				"underline",
				"subscript",
				"superscript",
			],
			align: "left",
			buttonsVisible: 5,
		},
		moreParagraph: {
			buttons: [
				"clearFormatting",
				"align",
				"formatOL",
				"formatUL",
				"indent",
				"outdent",
			],
			align: "left",
			buttonsVisible: 6,
		},
		moreMisc: {
			buttons: [
				"copyRawHTML",
				"pasteRawHTML",
				"undo",
				"redo",
				"fullscreen",
			],
			align: "right",
			buttonsVisible: 2,
		}
	},
};

export const editorWelcomeLetter: any = {
	...editorBase,
	pastePlain: true,
	toolbarButtons: {
		moreText: {
			buttons: [
				"customSnippetsSections",
				"bold",
				"italic",
				"fontSize",
				"lineHeight",
				"textColor",
				"underline",
				"subscript",
				"superscript",
			],
			align: "left",
			buttonsVisible: 7,
		},
		moreParagraph: {
			buttons: [
				"paragraphFormat",
				"paragraphStyle",
				"clearFormatting",
				"align",
				"formatOL",
				"formatUL",
				"indent",
				"outdent",
			],
			align: "left",
			buttonsVisible: 8,
		},
		moreRich: {
			buttons: [
				"insertLink",
				"insertImage",
				"insertVideo",
				"insertTable"
			],
			align: "left",
			buttonsVisible: 4,
		},
		moreMisc: {
			buttons: [
				"copyRawHTML",
				"pasteRawHTML",
				"undo",
				"redo",
				"html",
				"fullscreen",
			],
			align: "right",
			buttonsVisible: 2,
		}
	},
	paragraphStyles: {
		sectionIntro: 'Section Intro',
		quoteS: 'Quote Small',
		quoteM: 'Quote Medium',
		quoteL: 'Quote Large',
		quoteXL: 'Quote Extra Large'
	},
	paragraphFormat: {
		N: 'Normal',
		H1: 'H1',
		H2: 'H2',
		H3: 'H3',
		H4: 'H4',
		H5: 'H5',
		H6: 'H6',
	},
	fontFamilySelection: false,
	colorsText: ['#ffffff', '#000000', '#86BC25', '#43B02A', '#26890D', '#046A38', '#0D8390', '#007CB0', '#DA291C'],
	tableColors: ['#ffffff', '#000000', '#86BC25', '#C4D600', '#43B02A', '#046A38', '#2C5234', '#0097A9', '#62B5E5', '#00A3E0', '#0076A8', '#012169', '#28324E', '#D0D0CE', '#BBBCBC', '#97999B', '#75787B', '#53565A', 'REMOVE'],
	tableStyles: {
		deloitteTable: 'Deloitte Table',
		deloitteNoBorders: 'No borders'
	},
	tableCellMultipleStyles: false,
	tableCellStyles: {
		textColorWhite: 'Text color white',
		cellMargin: 'Cell margin'
	},
	tableResizerOffset: 10,
	tableResizingLimit: 1,
	htmlUntouched: true,
	linkEditButtons: ['linkEdit', 'linkRemove'],
    imageUpload: true,
    // Set max image size to 5MB.
    imageMaxSize: 5 * 1024 * 1024,
    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
    events: {
		'image.beforeUpload': function(images: any) {
			// Before image is uploaded
			var editor:any = this;
			const data = new FormData();
			data.append('file', images[0]);
			getToken().then(accessToken => {
				axios.post('/api/content/files/save', data, {
					headers: {
						"Authorization": "Bearer " + accessToken
					}
				}).then(res => {
					editor.image.insert(res.data.link, null, null, editor.image.get());
				}).catch(err => {
					console.log(err);
				});	
			}).catch(err => {
				console.log(err);
			});
			return false;
		}
    },
	imageInsertButtons: ['imageBack', '|', 'imageUpload'],
	imageEditButtons: ['imageReplace', 'imageAlign', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageAlt', 'imageSize'],
	videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
	videoEditButtons: ['videoReplace', 'videoRemove', '|', 'videoAlign', 'videoSize'],
	linkList: [
		{
			text: 'Deloitte',
			href: 'https://www.deloitte.com',
			target: '_blank',
			rel: 'nofollow'
		}
	],
	pasteDeniedTags: [
		'html', 'body', 'script',
		'frame', 'frameset', 'iframe',
		'svg', 'video', 'canvas', 'embed',
		'form', 'input', 'textarea', 'button'
	],
};

export const editorSection: any = {
	...editorBase,
	pastePlain: true,
	toolbarButtons: {
		moreText: {
			buttons: [
				"customSnippetsSections",
				"bold",
				"italic",
				"fontSize",
				"lineHeight",
				"textColor",
				"underline",
				"subscript",
				"superscript",
			],
			align: "left",
			buttonsVisible: 7,
		},
		moreParagraph: {
			buttons: [
				"paragraphFormat",
				"paragraphStyle",
				"clearFormatting",
				"align",
				"formatOL",
				"formatUL",
				"indent",
				"outdent",
			],
			align: "left",
			buttonsVisible: 8,
		},
		moreRich: {
			buttons: [
				"insertLink",
				"insertImage",
				"insertVideo",
				"insertTable"
			],
			align: "left",
			buttonsVisible: 4,
		},
		moreMisc: {
			buttons: [
				"copyRawHTML",
				"pasteRawHTML",
				"undo",
				"redo",
				"html",
				"fullscreen",
			],
			align: "right",
			buttonsVisible: 2,
		}
	},
	paragraphStyles: {
		sectionIntro: 'Section Intro',
		quoteS: 'Quote Small',
		quoteM: 'Quote Medium',
		quoteL: 'Quote Large',
		quoteXL: 'Quote Extra Large'
	},
	paragraphFormat: {
		N: 'Normal',
		H1: 'H1',
		H2: 'H2',
		H3: 'H3',
		H4: 'H4',
		H5: 'H5',
		H6: 'H6',
	},
	fontFamilySelection: false,
	colorsText: ['#ffffff', '#000000', '#86BC25', '#43B02A', '#26890D', '#046A38', '#0D8390', '#007CB0', '#DA291C'],
	tableColors: ['#ffffff', '#000000', '#86BC25', '#C4D600', '#43B02A', '#046A38', '#2C5234', '#0097A9', '#62B5E5', '#00A3E0', '#0076A8', '#012169', '#28324E', '#D0D0CE', '#BBBCBC', '#97999B', '#75787B', '#53565A', 'REMOVE'],
	tableStyles: {
		deloitteTable: 'Deloitte Table',
		deloitteNoBorders: 'No borders'
	},
	tableCellMultipleStyles: false,
	tableCellStyles: {
		textColorWhite: 'Text color white',
		cellMargin: 'Cell margin'
	},
	tableResizerOffset: 10,
	tableResizingLimit: 1,
	htmlUntouched: true,
	linkEditButtons: ['linkEdit', 'linkRemove'],
    imageUpload: true,
    // Set max image size to 5MB.
    imageMaxSize: 5 * 1024 * 1024,
    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
    events: {
		'image.beforeUpload': function(images: any) {
			// Before image is uploaded
			var editor:any = this;
			const data = new FormData();
			data.append('file', images[0]);
			getToken().then(accessToken => {
				axios.post('/api/content/files/save', data, {
					headers: {
						"Authorization": "Bearer " + accessToken
					}
				}).then(res => {
					editor.image.insert(res.data.link, null, null, editor.image.get());
				}).catch(err => {
					console.log(err);
				});	
			}).catch(err => {
				console.log(err);
			});
			return false;
		}
    },
	imageInsertButtons: ['imageBack', '|', 'imageUpload'],
	imageEditButtons: ['imageReplace', 'imageAlign', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageAlt', 'imageSize'],
	videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
	videoEditButtons: ['videoReplace', 'videoRemove', '|', 'videoAlign', 'videoSize'],
	linkList: [
		{
			text: 'Deloitte',
			href: 'https://www.deloitte.com',
			target: '_blank',
			rel: 'nofollow'
		}
	],
	pasteDeniedTags: [
		'html', 'body', 'script',
		'frame', 'frameset', 'iframe',
		'svg', 'video', 'canvas', 'embed',
		'form', 'input', 'textarea', 'button'
	],
	codeMirrorOptions: {
		indentWithTabs: true,
		lineNumbers: true,
		lineWrapping: true,
		mode: 'text/html',
		tabMode: 'indent',
		indentUnit: 4,
		extraKeys: {
			"Shift-Tab": (cm: any) => {
				var range:any = {
					from: cm.getCursor(true),
					to: cm.getCursor(false)
				};
				cm.autoFormatRange(range.from, range.to);
			}
		}
	},
};

export const editorSendProposal: any = {
	...editorBase,
	htmlAllowedTags: [
		'br', 'div', 'p', 'span',
		'ul', 'ol', 'li', 'a',
		'strong', 'sub', 'sup', 'em', 'i', 'u', 'strike',
	],
	pasteDeniedTags: [
		'html', 'body', 'script',
		'frame', 'frameset', 'iframe',
		'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
		'a', 'b', 'blockquote', 'caption', 'font', 'style', 'hr',
		'img', 'svg', 'video', 'canvas', 'embed',
		'form', 'input', 'textarea', 'button', 'label',
		'table', 'tr', 'td'
	],
	imagePaste: false,
	imageUpload: false,
	toolbarButtons: {
		moreText: {
			buttons: [
				"customSnippetsEmailBody",
				"bold",
				"italic",
				"underline",
				"subscript",
				"superscript",
			],
			align: "left",
			buttonsVisible: 6,
		},
		moreParagraph: {
			buttons: [
				"clearFormatting",
				"align",
				"formatOL",
				"formatUL",
				"indent",
				"outdent",
			],
			align: "left",
			buttonsVisible: 6,
		},
		moreMisc: {
			buttons: [
				"undo",
				"redo",
				"fullscreen",
			],
			align: "right",
		}
	},
};