import React, { useState, useEffect } from 'react';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import New from './new';
import Edit from './edit';
import SubList from './sublist';
import { show, close, showLoader, hideLoader } from '../../../../services/renderer';
import repoServices from '../../../../repositories/settings/services';
import cellButton from '../../../global/cellbutton';
import { notify, alert } from '../../../../services/notification';
import GridPanel from '../../../global/gridpanel';
import { triggerListeners } from '../../../../services/listeners';
import { cloneObject } from '../../../../services/tools';
import { isAuthorized } from '../../../../services/authorization';
import translations from '../../../../translations';
import DeloitteButton from '../../../global/deloittebutton';

const List: React.FC = (props) => {

	function edit(dataItem: any) {
		show(Edit, "serviceedit", { dataItem: cloneObject(dataItem), save: save, cancel: cancel });
	}

	function editSubServices(dataItem: any) {
		show(SubList, "sublistedit", { dataItem: cloneObject(dataItem), cancel: cancelSubServices });
	}

	function cancel() {
		close("serviceedit");
		close("servicenew");
	}

	function cancelSubServices() {
		close("sublistedit");
	}

	function save(record: any) {
		showLoader();
		repoServices.save(record).then((result) => {
			notify(translations.general_savedsuccess);
			triggerListeners("reloadIndustries");
			cancel();
			hideLoader();
		}).catch((error) => {
			hideLoader();
			alert(error);
		});
	}

	return (
		<GridPanel reloadKey="reloadIndustries" loadData={repoServices.getAll} filter={{
			logic: "and",
			filters: [
				{
					field: "active",
					operator: "eq",
					value: true
				}
			]
		}} onRowDoubleClick={edit}>
			{isAuthorized("Offers", "Create") ? <GridToolbar >
				<div>
					<DeloitteButton title={translations.general_addnew} onClick={() => { show(New, "servicenew", { save: save, cancel: cancel }); }} >
						{translations.general_addnew}
					</DeloitteButton>
				</div>
			</GridToolbar> : null}
			<GridColumn field="mainOffering" title={translations.services_name} editable={false} />
			<GridColumn field="businessUnit.name" title={translations.services_businessunit} editable={false} />
			<GridColumn field="active" title={translations.general_active} width="125px" editor="boolean" filter={'boolean'} />
			{isAuthorized("Offers", "Update") ? <GridColumn filterable={false} width="100px" editable={false} title={translations.general_edit} cell={cellButton(edit, translations.general_edit)} /> : 
				(!isAuthorized("Offers", "Update") && isAuthorized("Offers", "Read") ? <GridColumn filterable={false} width="120px" editable={false} title={translations.general_view} cell={cellButton(edit, translations.general_view)} /> : null)} 
			{isAuthorized("Offers", "Update") ? <GridColumn filterable={false} width="150px" editable={false}
				title={translations.services_subservices}
				cell={cellButton(editSubServices, translations.services_editsubservices)}
			/> : null}
		</GridPanel>
	);
}

export default List;