///<reference path= "../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect } from 'react';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { confirmChanged } from '../../../services/renderer';
import translations, { getCurrentLanguage } from '../../../translations';
import config from '../../../config.json';

interface EditResumeProps {
	save: (record: any) => void,
	cancel: () => void,
	dataItem?: any
}

const EditResume: React.FC<EditResumeProps> = (props) => {

	const [record, setRecord] = useState({
		id: props.dataItem && props.dataItem.userID ? props.dataItem.userID : "00000000-0000-0000-0000-000000000000",
		fullName: props.dataItem && props.dataItem.fullName ? props.dataItem.fullName : "",
		function: props.dataItem && props.dataItem.function ? props.dataItem.function : "",
		resume: props.dataItem && props.dataItem.resume ? props.dataItem.resume : ""
	});
	const [dataChanged, setDataChanged] = useState(false);
	const editorConfig = {
		key: config.froala.key,
		attribution: false,
		placeholderText: translations.myprofile_resume_placeholder,
		language: getCurrentLanguage(),
		height: 400,
		enter: 2,
		wordPasteKeepFormatting: false,
		pastePlain: true,
		toolbarButtons: {
			moreText: {
				buttons: [
					"bold",
					"italic",
					"underline",
					"subscript",
					"superscript",
				],
				align: "left",
				buttonsVisible: 5,
			},
			moreParagraph: {
				buttons: [
					"clearFormatting",
					"align",
					"formatOL",
					"formatUL",
					"indent",
					"outdent",
				],
				align: "left",
				buttonsVisible: 6,
			},
			moreMisc: {
				buttons: [
					"undo",
					"redo",
					"fullscreen",
				],
				align: "right",
			}
		},
	};

	function onChange(event: any) {
		let newRecord = { ...record };
		// @ts-ignore
		newRecord[event.target.props.name] = event.target.value;
		setRecord(newRecord);
		setDataChanged(true);
	}

	function changeResume(newContent: string) {
		record.resume = newContent;
		setRecord(record);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		props.save(record);
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	return (
		<Dialog onClose={cancel} title={"Edit resume for " + record.fullName} width={"50%"}>
			<form className="k-form">
				<label className="k-form-field">
					<span>Team member</span>
					<Input name="fullName" readOnly={true} style={{ width: "100%" }} value={(record && record.fullName ? record.fullName : "")} />
				</label>
				<label className="k-form-field">
					<span>Function</span>
					<Input name="function" style={{ width: "100%" }} value={(record && record.function ? record.function : "")} onChange={onChange} />
				</label>
				<label className="k-form-field">
					<span>Resumé</span>
				</label>
				<FroalaEditorComponent config={editorConfig} model={record.resume} onModelChange={changeResume}></FroalaEditorComponent>
			</form>

			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>{translations.general_cancel}</button>
				<button className="k-button k-primary" onClick={save}>{translations.general_save}</button>
			</DialogActionsBar>
		</Dialog>
	);
}

export default EditResume;