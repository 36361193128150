import React, { useState, useEffect } from 'react';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Upload, UploadOnAddEvent } from '@progress/kendo-react-upload';
import FilePreview from '../../global/filepreview';
import { alert } from '../../../services/notification';
import { confirmChanged } from '../../../services/renderer';
import translations from '../../../translations';
import { newGuid } from '../../../services/tools';

interface NewProps {
	save: (record: any) => void,
	cancel: () => void
}

const New: React.FC<NewProps> = (props) => {

	const [record, setRecord] = useState({
		key: newGuid(),
		description: "",
		file: {}
	});
	const [file, setFile] = useState();
	const [dataChanged, setDataChanged] = useState(false);

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			default:
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value;
		}
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		if (file && record.description !== "") {
			// @ts-ignore
			record.file = file;
			// @ts-ignore
			props.save(record);
		} else {
			alert(translations.general_fileupload_pdfrequired);
		}
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	// @ts-ignore
	function addFile(e: UploadOnAddEvent) {
		if (e && e.affectedFiles && e.affectedFiles.length > 0 && e.affectedFiles[0]) {
			if (e.affectedFiles[0].size && e.affectedFiles[0].size > 10000000) {
				// @ts-ignore
				setFile(null);
				alert(translations.general_fileupload_above10mb);
				return;
			}
			// @ts-ignore
			setFile(e.affectedFiles[0]);
			setDataChanged(true);
		} else {
			// @ts-ignore
			setFile(null);
			alert(translations.general_fileupload_novalidfile);
		}
	}

	return (
		<Dialog
			onClose={cancel}
			title={translations.general_new + " " + translations.proposaleditor_attachments_attachment}
			width={550}
		>
			<form className="k-form" onSubmit={onSubmit}>
				<label className="k-form-field">
					<span>{translations.proposaleditor_attachments_title}</span>
					<Input
						name="description"
						style={{ width: "100%" }}
						placeholder={translations.general_placeholder_prefix + " " + translations.proposaleditor_attachments_title + "..."}
						value={record && record.description ? record.description : ""}
						onChange={onChange}
						required={true}
						validationMessage={translations.general_validationmessage + " " + translations.proposaleditor_attachments_title}
					/>
				</label>
				<label className="k-form-field">
					<span>{translations.proposaleditor_attachments_attachment}</span>
					<div className={!file ? "isrequired" : ""}>
						<Upload
							autoUpload={false}
							multiple={false}
							files={file ? [file] : []}
							onAdd={addFile}
							listItemUI={FilePreview}
							onRemove={() => {
								setFile(undefined);
							}}
							className="hidden-upload-button-in-preview"
						/>
					</div>
				</label>
			</form>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				<button className="k-button k-primary" onClick={save}>
					{translations.general_save}
				</button>
			</DialogActionsBar>
		</Dialog>
	);
}

New.defaultProps = {
	save: (record: any) => { },
	cancel: () => { }
};

export default New;