import React, { useState, useEffect } from 'react';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { confirmChanged } from '../../../services/renderer';
import translations from '../../../translations';
import repoUsers from '../../../repositories/security/users';
import _ from 'lodash';

interface NewProps {
	save: (record: any, editProfile: any) => void,
	cancel: () => void,
	exclude?: any
}

const New: React.FC<NewProps> = (props) => {

	const [record, setRecord] = useState({
		user: {},
		elevated: false,
		editProfile: {}
	});
	const [dataChanged, setDataChanged] = useState(false);
	const [users, setUsers] = useState(new Array());
	useEffect(() => {
		repoUsers.searchActive("").then((result) => {
			setUsers(_.differenceBy(result, props.exclude, "userID"));
		});
	}, []);

	function onChange(event: any) {
		let newRecord = { ...record };
		// @ts-ignore
		if (event.target.props.name === 'userID') {
			newRecord.user = event.target.value;
		}
		if (event.target.props.name === 'elevated') {
			if (event.target.value === true) {
				newRecord.editProfile = { "general": true, "welcomeLetter": true, "contacts": true, "pursuitTeam": true, "sections": ["c1742822-eed7-4956-ba61-b6a800255606 ", "68f56aa3-acbc-4243-8859-c838ce3f265a ", "2655d1ae-ff3e-4dcc-9882-1776a29874a0"] };
				newRecord.elevated = true;
			} else {
				newRecord.editProfile = {};
				newRecord.elevated = false;
			}
		}
		setRecord(newRecord);
		setDataChanged(true);
	}

	function filterChange(event: any) {
		repoUsers.searchActive((event.filter && event.filter.value) ? event.filter.value : '').then((result) => {
			setUsers(_.differenceBy(result, props.exclude, "userID"));
		});
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		props.save(record.user, record.editProfile);
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	return (
		<Dialog onClose={cancel} title={translations.general_new } width={400}>
			<form className="k-form" onSubmit={onSubmit}>
				<label className="k-form-field">
					<span>Select a new team member</span>
					<ComboBox
						data={users}
						name="userID"
						style={{ width: "100%" }}
						dataItemKey="userID"
						textField="fullName"
						onChange={onChange}
						required={true}
						filterable={true}
						onFilterChange={filterChange}
					/>
				</label>
				<label className="k-form-field">
					<span>Elevated contributor</span>
					{/*
					// @ts-ignore*/ }
					<Switch name="elevated" checked={(record && record.elevated ? record.elevated : false)} onChange={onChange}
						onLabel={translations.general_switch_on}
						offLabel={translations.general_switch_off} />
				</label>
			</form>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>{translations.general_cancel}</button>
				<button className="k-button k-primary" onClick={save}>{translations.general_save}</button>
			</DialogActionsBar>
		</Dialog>
	);
}

New.defaultProps = {
	save: (record: any) => { },
	cancel: () => { }
};

export default New;