import React, { useState, useEffect } from 'react';
import { NavLink as Link } from "react-router-dom";
import '../../assets/css/home.scss';
import { getMyProfile } from '../../services/authentication';
import translations from '../../translations';
import ProposalListing from '../pages/proposals';
import Wizard from '../pages/proposals/wizard';
import { show, close, showLoader, hideLoader } from '../../services/renderer';
import { triggerListeners } from '../../services/listeners';
import { isAuthorized } from '../../services/authorization';

const Home: React.FC = () => {
	const [myProfile] = useState(getMyProfile());

	function cancel() {
		triggerListeners("reloadProposals");
		close("proposalnew");
	}

	function save() {
		triggerListeners("reloadProposals");
		cancel();
	}

	return (
		<div className="container home-container">
			<div className="row ">
				<div className="col">
					<div className="title">{translations.home_welcomeback} <span className="title-bold">{myProfile.firstName}!</span></div>
				</div>
			</div>
			<div className="row">
				{isAuthorized("Proposals", "Create") && (
					<div className="col-md">
						<div className="home-box home-box-newproposal" style={{ cursor: "pointer" }} onClick={() => { show(Wizard, "proposalnew", { save: save, cancel: cancel }); }} >
							<span className="home-box-title">{translations.home_createnewproposal}</span>
						</div>
					</div>
				)}
				<div className="col-md">
					<Link to="/myprofile">
						<div className="home-box home-box-updateresume">
							<span className="home-box-title">{translations.home_updateresume}</span>
						</div>
					</Link>
				</div>
			</div>
			{isAuthorized("Proposals", "*") && (
				<div className="row">
					<div className="col-md">
						<div style={{ padding: 20, backgroundColor: "white", borderRadius: 13, boxShadow: "0px 11px 34px -5px rgba(0, 0, 0, 0.1)"}}>
							<span className="home-box-title" style={{ fontWeight: "bold", fontSize: "1.5em", paddingBottom: 20, marginLeft: 15}}>{translations.home_myproposals}</span>
							<div style={{ height: 600, marginLeft: 15, marginRight: 15, marginTop: 20, position: "relative"}}><ProposalListing isHome={true}></ProposalListing></div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Home;
