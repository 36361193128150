///<reference path= "../../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect } from 'react';
import { Upload } from '@progress/kendo-react-upload';
import FilePreview from '../../../global/filepreview';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import repoIndustries from '../../../../repositories/settings/industries';
import repoServices from '../../../../repositories/settings/services';
import { getMyProfile } from '../../../../services/authentication';
import _ from 'lodash';
import { alert } from '../../../../services/notification';
import { confirmChanged } from '../../../../services/renderer';
import translations from '../../../../translations';
import { preventDoubleClick } from '../../../../services/tools';

interface NewProps {
	save: (record: any) => void,
	cancel: () => void
}

const New: React.FC<NewProps> = (props) => {

	const [record, setRecord] = useState({
		id: "00000000-0000-0000-0000-000000000000",
		name: "",
		backgroundColor: "",
		active: false,
		industries: new Array(),
		services: new Array(),
		file: {}
	});
	const [industries, setIndustries] = useState([]);
	const [allIndustriesChecked, setAllIndustriesChecked] = useState(false);
	const [services, setServices] = useState([]);
	const [allServicesChecked, setAllServicesChecked] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [file, setFile] = useState();
	const [dataChanged, setDataChanged] = useState(false);

	useEffect(() => {
		repoIndustries.getActive().then((result) => {
			setIndustries(result.map((industry: any) => {
				return {
					id: industry.id,
					name: industry.name,
					checked: false
				}
			}));
		});
		repoServices.subServices.getActiveForCountry().then((result) => {
			setServices(result.map((service: any) => {
				return {
					id: service.id,
					name: service.mainOffer.mainOffering + " > " + service.subOffering,
					checked: false
				}
			}));
		});
	}, []);

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			case "backgroundColor":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.key : "White";
				break;
			default:
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value;
		}
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		if (file) {
			record.file = file;
			// @ts-ignore
			record.image_fileAction = "change";
			record.industries.length = 0;
			industries.forEach((industry: any) => {
				if (industry.checked) record.industries.push(industry.id);
			});
			record.services.length = 0;
			services.forEach((service: any) => {
				if (service.checked) record.services.push(service.id);
			});
			props.save(record);
		} else {
			alert(translations.general_fileupload_imagerequired);
		}
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	function changeIndustry(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllIndustriesChecked(false);
		setIndustries([...industries]);
		setDataChanged(true);
	}

	function selectAllIndustries(e: any) {
		setAllIndustriesChecked(e.target.value);
		industries.forEach((industry: any) => {
			industry.checked = e.target.value;
		});
		setIndustries([...industries]);
		setDataChanged(true);
	}

	function changeService(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllServicesChecked(false);
		setServices([...services]);
		setDataChanged(true);
	}

	function selectAllServices(e: any) {
		setAllServicesChecked(e.target.value);
		services.forEach((service: any) => {
			service.checked = e.target.value;
		});
		setServices([...services]);
		setDataChanged(true);
	}

	// @ts-ignore
	function addFile(event: UploadOnAddEvent) {
		if (event && event.affectedFiles && event.affectedFiles.length > 0 && event.affectedFiles[0]) {
			if (event.affectedFiles[0].size && event.affectedFiles[0].size > 4000000) {
				setFile(null);
				alert(translations.general_fileupload_above4mb);
				return;
			}
			if (".gif.jpg.png".indexOf(event.affectedFiles[0].extension.toLowerCase() || "") == -1) {
				setFile(null);
				alert(translations.general_fileupload_noimage);
				return;
			}
			setFile(event.affectedFiles[0]);
			setDataChanged(true);
		} else {
			setFile(null);
			alert(translations.general_fileupload_novalidfile);
		}
	}

	return (
		<Dialog
			onClose={cancel}
			title={translations.general_new + " " + translations.coverimages_windowtitlesingle}
			width="70%"
		>
			<div className="tab-bar">
				<ul>
					<li className={selectedTab === 0 ? "active" : ""} onClick={() => { setSelectedTab(0); }}>{translations.general_tab_details}</li>
					<li className={selectedTab === 1 ? "active" : ""} onClick={() => { setSelectedTab(1); }}>{translations.general_tab_industries}</li>
					<li className={selectedTab === 2 ? "active" : ""} onClick={() => { setSelectedTab(2); }}>{translations.general_tab_services}</li>
				</ul>
			</div>
			<div className="tab-bar-container">
				<div className={selectedTab === 0 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<label className="k-form-field">
							<span>{translations.coverimages_name}</span>
							<Input
								name="name"
								style={{ width: "100%" }}
								placeholder={translations.general_placeholder_prefix + " " + translations.coverimages_name + "..."}
								value={record && record.name ? record.name : ""}
								onChange={onChange}
								required={true}
								validationMessage={translations.general_validationmessage + translations.coverimages_name}
							/>
						</label>
						<div className="row">
							<div className="col-md-6">
								<label className="k-form-field">
									<span>{translations.coverimages_image}</span>
									<div className={!file ? "isrequired" : ""}>
										<Upload
											autoUpload={false}
											multiple={false}
											accept=".gif,.jpg,.png"
											files={file ? [file] : []}
											onAdd={addFile}
											listItemUI={FilePreview}
											onRemove={() => {
												setFile(undefined);
											}}
											className="hidden-upload-button-in-preview"
										/>
									</div>
								</label>
							</div>
							<div className="col-md-6">
								<label className="k-form-field">
									<span>{translations.coverimages_backgroundcolor}</span>
									<ComboBox
										data={[
											{ key: "White", value: translations.coverimages_backgroundcolor_white },
											{ key: "Black", value: translations.coverimages_backgroundcolor_black }
										]}
										name="backgroundColor"
										dataItemKey="key"
										textField="value"
										style={{ width: "100%" }}
										onChange={onChange}
										required={true}
									/>
								</label>
							</div>
						</div>
						<label className="k-form-field">
							<span>{translations.general_active}</span>
							{/*
							// @ts-ignore*/}
							<Switch name="active"
								checked={
									record && record.active ? record.active : false
								}
								onChange={onChange}
								onLabel={translations.general_switch_on}
								offLabel={translations.general_switch_off}
							/>
						</label>
					</form>
				</div>
				<div className={selectedTab === 1 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allIndustriesChecked} onChange={selectAllIndustries} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{industries.map((industry: any) => (
							<div className="row spaced-row switch-row" key={industry.id}>
								<div className="col-md-11">
									<span className={industry.checked ? "checked-row" : ""}>{industry.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={industry} checked={industry.checked} onChange={changeIndustry} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
				<div className={selectedTab === 2 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allServicesChecked} onChange={selectAllServices} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{services.map((service: any) => (
							<div className="row spaced-row switch-row" key={service.id}>
								<div className="col-md-11">
									<span className={service.checked ? "checked-row" : ""}>{service.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={service} checked={service.checked} onChange={changeService} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
			</div>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				<button className="k-button k-primary" onClick={() => preventDoubleClick(save)}>
					{translations.general_save}
				</button>
			</DialogActionsBar>
		</Dialog>
	);
}

New.defaultProps = {
	save: (record: any) => { },
	cancel: () => { }
};

export default New;