import axios from 'axios';
import { getToken } from '../../services/authentication';
import translations from '../../translations';

const baseURL = "/api/settings/offers/";

interface Record {
	id: string,
	mainOffering: string,
	businessUnitID: string,
	active: boolean
}

interface SubRecord {
	id: string,
	subOffering: string,
	mainOfferID: string,
	active: boolean
}

const repository = {
	getAll: async (conf: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "getall", conf, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	save: async (record: Record) => {
		try {
			if (!record.id || !record.mainOffering || !record.businessUnitID || record.businessUnitID === "00000000-0000-0000-0000-000000000000") throw translations.general_fillinallrequiredfields;
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "save", record, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}

	},
	subServices: {
		getAll: async (conf: any, parentID?: string) => {
			try {
				var accessToken = await getToken();
				var result = await axios.post(baseURL + "suboffers/getall?mainOfferID=" + parentID, conf, {
					headers: {
						"Authorization": "Bearer " + accessToken
					}
				});
				return result.data;
			} catch (error) {
				throw error;
			}
		},
		getActive: async (businessUnitID: string) => {
			try {
				var accessToken = await getToken();
				var result = await axios.get(baseURL + "suboffers/getactive?businessUnitID=" + businessUnitID, {
					headers: {
						"Authorization": "Bearer " + accessToken
					}
				});
				return result.data;
			} catch (error) {
				throw error;
			}
		},
		getActiveForCountry: async () => {
			try {
				var accessToken = await getToken();
				var result = await axios.get(baseURL + "suboffers/getactiveforcountry", {
					headers: {
						"Authorization": "Bearer " + accessToken
					}
				});
				return result.data;
			} catch (error) {
				throw error;
			}
		},
		save: async (record: SubRecord) => {
			try {
				if (!record.id || !record.subOffering) throw "Please fill in all required fields!";
				var accessToken = await getToken();
				var result = await axios.put(baseURL + "suboffers/save", record, {
					headers: {
						"Authorization": "Bearer " + accessToken
					}
				});
				return result.data;
			} catch (error) {
				throw error;
			}

		}
	}
};

export default repository;