import React, { useState, useEffect } from "react";

interface CheckboxProps {
	label?: string | undefined,
	additionalClass?: string | undefined,
	checked?: boolean | undefined,
	onClick?: ((checked: boolean | undefined) => void) | undefined,
	disabled?: boolean | undefined,
	hidden?: boolean | undefined,
	key?: string | number | undefined
}


const DeloitteCheckbox: React.FC<CheckboxProps> = (props) => {
	const [isChecked, setIsChecked] = useState(props.checked);

	const onClickCheckbox = () => {
		if (props.disabled !== true && props.onClick) {
			props.onClick(!isChecked);
			setIsChecked(!isChecked);
		}
	}

	return (
		<div key={props.key} className={"deloitte-checkbox-container " + props.additionalClass + (props.disabled === true ? " disabled" : "")} hidden={props.hidden} onClick={onClickCheckbox}>
			{isChecked !== true && (<span className="deloitte-icon icon-checkbox_empty_special"></span>)}
			{isChecked === true && (<span className="deloitte-icon icon-checkbox_full_special"></span>)}
			<span className="deloitte-checkbox-label">{props.label}</span>
		</div>
	);
};

export default DeloitteCheckbox;