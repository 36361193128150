///<reference path= "../../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect } from 'react';
import { editorSection } from '../../../../services/environment';
//@ts-ignore
import FroalaEditor from 'froala-editor';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
//@ts-ignore
import { CirclePicker } from 'react-color';
import repoCountryLanguages from '../../../../repositories/settings/countrylanguages';
import repoIndustries from '../../../../repositories/settings/industries';
import repoServices from '../../../../repositories/settings/services';
import { useBusinessUnits } from '../../../../repositories/settings/businessunits';
import repoUsers from '../../../../repositories/security/users';
import { getMyProfile } from '../../../../services/authentication';
import sectionIcons from '../../../../services/sectionicons';
import _ from 'lodash';
import { showLoader, hideLoader, confirmChanged } from '../../../../services/renderer';
import translations, { getCurrentLanguage } from '../../../../translations';
import { preventDoubleClick } from '../../../../services/tools';

interface NewProps {
	save: (record: any) => void,
	cancel: () => void
}

const New: React.FC<NewProps> = (props) => {

	const [record, setRecord] = useState({
		id: "00000000-0000-0000-0000-000000000000",
		businessUnitID: "00000000-0000-0000-0000-000000000000",
		ownerID: "00000000-0000-0000-0000-000000000000",
		name: "",
		icon: "",
		version: "",
		content: "",
		description: "",
		active: false,
		industries: new Array(),
		services: new Array(),
		languages: new Array(),
		file: {},
		landscape: false,
		hideTitle: false,
		hideHeaderAndFooter: false,
		backgroundColor: "#ffffff",
	});
	const [iconSelectorVisible, setIconSelectorVisible] = useState(false);
	const businessunits = useBusinessUnits();
	const [users, setUsers] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [allLanguagesChecked, setAllLanguagesChecked] = useState(false);
	const [industries, setIndustries] = useState([]);
	const [allIndustriesChecked, setAllIndustriesChecked] = useState(false);
	const [services, setServices] = useState([]);
	const [allServicesChecked, setAllServicesChecked] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [editorConfig] = useState({
		...editorSection,
		placeholderText: translations.sections_content_placeholder,
		language: getCurrentLanguage(),
		height: 400,
	});
	const [dataChanged, setDataChanged] = useState(false);
	const [colorPickerVisible, setColorPickerVisible] = useState(false);

	useEffect(() => {
		repoUsers.getActive().then((result) => {
			setUsers(result);
		});
		repoCountryLanguages.getActive().then((result) => {
			setLanguages(result.map((language: any) => {
				return {
					id: language.id,
					name: language.name,
					checked: false
				}
			}));
		});
		repoIndustries.getActive().then((result) => {
			setIndustries(result.map((industry: any) => {
				return {
					id: industry.id,
					name: industry.name,
					checked: false
				}
			}));
		});
		setFroalaEditorBackgroundColor("#ffffff");
	}, []);

	function loadServices(activeRecord: any, businessUnitID: any) {
		showLoader();
		repoServices.subServices.getActive(businessUnitID).then((result) => {
			setServices(result.map((service: any) => {
				return {
					id: service.id,
					name: service.mainOffer.mainOffering + " > " + service.subOffering,
					checked: _.some(activeRecord.services, { serviceID: service.id })
				}
			}));
			hideLoader();
		});
	}

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			case "ownerID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.userID : "00000000-0000-0000-0000-000000000000";
				break;
			case "businessUnitID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				loadServices(newRecord, event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000001");
				break;
			default:
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value;
		}
		setRecord(newRecord);
		setDataChanged(true);
	}

	function changeContent(newContent: string) {
		let newRecord = { ...record };
		newRecord.content = newContent;
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		record.industries.length = 0;
		industries.forEach((industry: any) => {
			if (industry.checked) record.industries.push(
				{
					industryID: industry.id
				}
			);
		});
		record.services.length = 0;
		services.forEach((service: any) => {
			if (service.checked) record.services.push(
				{
					serviceID: service.id
				}
			);
		});
		record.languages.length = 0;
		languages.forEach((language: any) => {
			if (language.checked) record.languages.push(
				{
					languageID: language.id
				}
			);
		});
		props.save(record);
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	function changeIndustry(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllIndustriesChecked(false);
		setIndustries([...industries]);
		setDataChanged(true);
	}

	function selectAllIndustries(e: any) {
		setAllIndustriesChecked(e.target.value);
		industries.forEach((industry: any) => {
			industry.checked = e.target.value;
		});
		setIndustries([...industries]);
		setDataChanged(true);
	}

	function changeService(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllServicesChecked(false);
		setServices([...services]);
		setDataChanged(true);
	}

	function selectAllServices(e: any) {
		setAllServicesChecked(e.target.value);
		services.forEach((service: any) => {
			service.checked = e.target.value;
		});
		setServices([...services]);
		setDataChanged(true);
	}

	function changeLanguage(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllLanguagesChecked(false);
		setLanguages([...languages]);
		setDataChanged(true);
	}

	function selectAllLanguages(e: any) {
		setAllLanguagesChecked(e.target.value);
		languages.forEach((language: any) => {
			language.checked = e.target.value;
		});
		setLanguages([...languages]);
		setDataChanged(true);
	}

	function selectIcon() {
		setIconSelectorVisible(!iconSelectorVisible);
	}

	function changeIcon(icon: string) {
		let newRecord = { ...record };
		newRecord.icon = icon;
		setRecord(newRecord);
		setIconSelectorVisible(false);
		setDataChanged(true);
	}

	function toggleColorPicker() {
		setColorPickerVisible(!colorPickerVisible);
	}

	function changeBackgroundColor(color: any) {
		let newRecord = { ...record };
		newRecord.backgroundColor = color.hex;
		setFroalaEditorBackgroundColor(color.hex);
		setRecord(newRecord);
		setDataChanged(true);
		toggleColorPicker();
	}

	function setFroalaEditorBackgroundColor(color: any) {
		if (!color || color == "") color = "#ffffff";
		var style:any = document.getElementById("froalaBackgroundStyles");
		if (style === null) {
			style = document.createElement("style");
			style.id = "froalaBackgroundStyles";
			style.type = "text/css";
			document.getElementsByTagName("head")[0].appendChild(style);
		}
		style.innerHTML = ".froalaEditorInSections .fr-view { background-color: " + color + " !important; color: white; }";
	}

	return (
		<Dialog
			onClose={cancel}
			title={translations.general_new + " " + translations.sections_windowtitlesingle}
			width="70%"
		>
			<div className="tab-bar">
				<ul>
					<li className={selectedTab === 0 ? "active" : ""} onClick={() => { setSelectedTab(0); }}>{translations.general_tab_details}</li>
					<li className={selectedTab === 4 ? "active" : ""} onClick={() => { setSelectedTab(4); }}>{translations.sections_content}</li>
					<li className={selectedTab === 1 ? "active" : ""} onClick={() => { setSelectedTab(1); }}>{translations.general_tab_industries}</li>
					<li className={selectedTab === 2 ? "active" : ""} onClick={() => { setSelectedTab(2); }}>{translations.general_tab_services}</li>
					<li className={selectedTab === 3 ? "active" : ""} onClick={() => { setSelectedTab(3); }}>{translations.general_tab_languages}</li>
				</ul>
			</div>
			<div className="tab-bar-container">
				<div className={selectedTab === 0 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<label className="k-form-field">
							<span>{translations.sections_name}</span>
							<Input
								name="name"
								style={{ width: "100%" }}
								placeholder={translations.general_placeholder_prefix + " " + translations.sections_name + "..."}
								value={record && record.name ? record.name : ""}
								onChange={onChange}
								required={true}
								validationMessage={translations.general_validationmessage + " " + translations.sections_name}
							/>
						</label>
						<div className="row">
							<div className="col-md-6">
								<label className="k-form-field">
									<span>{translations.sections_icon}</span>
									<div className={record.icon == "" ? "sectionicons-selectedicon isrequired" : "sectionicons-selectedicon"} onClick={selectIcon}><span className={record.icon == "" ? "deloitte-icon icon-download_from_cloud_special" : record.icon}></span></div>
								</label>
								{iconSelectorVisible && (
								<div className="sectionicons-popup">
									<div className="sectionicons-arrow"></div>
									<div className="sectionicons-container">
										{sectionIcons.map((icon: any) => (
											<div className="sectionicons-icon" key={icon.class} onClick={() => {
												changeIcon(icon.class);
											}}>
												<span className={icon.class} title={icon.name}></span>
											</div>
										))}
									</div>
									</div>
								)}
							</div>
							<div className="col-md-6">
								<label className="k-form-field">
									<span>{translations.sections_businessunit}</span>
									<ComboBox
										data={businessunits}
										name="businessUnitID"
										dataItemKey="id"
										textField="name"
										style={{ width: "100%" }}
										onChange={onChange}
										required={true}
										suggest={true}
									/>
								</label>
								<label className="k-form-field">
									<span>{translations.sections_owner}</span>
									<ComboBox
										data={users}
										name="ownerID"
										dataItemKey="userID"
										textField="fullName"
										style={{ width: "100%" }}
										onChange={onChange}
										required={true}
										suggest={true}
									/>
								</label>
								<label className="k-form-field">
									<span>{translations.sections_version}</span>
									<Input
										name="version"
										style={{ width: "100%" }}
										placeholder={translations.general_placeholder_prefix + " " + translations.sections_version + "..."}
										value={record && record.version ? record.version : ""}
										onChange={onChange}
										required={true}
										validationMessage={translations.general_validationmessage + " " + translations.sections_version}
									/>
								</label>
							</div>
						</div>
						<label className="k-form-field">
							<span>{translations.sections_description}</span>
							<Input
								name="description"
								style={{ width: "100%" }}
								placeholder={translations.general_placeholder_prefix + " " + translations.sections_description + "..."}
								value={record && record.description ? record.description : ""}
								onChange={onChange}
								required={true}
								validationMessage={translations.general_validationmessage + " " + translations.sections_description}
							/>
						</label>
						<div className="row">
							<div className="col-md-3">
								<label className="k-form-field">
									<span>{translations.general_active}</span>
									{/*
									// @ts-ignore*/}
									<Switch name="active"
										checked={
											record && record.active ? record.active : false
										}
										onChange={onChange}
										onLabel={translations.general_switch_on}
										offLabel={translations.general_switch_off}
									/>
								</label>
							</div>
							<div className="col-md-3">
								<label className="k-form-field">
									<span>{translations.general_orientation}</span>
									{/*
									// @ts-ignore*/}
									<Switch name="landscape" checked={record && record.landscape ? record.landscape : false} onChange={onChange} onLabel="Landscape" offLabel="Portrait" className="page-orientation-switch"></Switch>
								</label>
							</div>
							<div className="col-md-6">
								<label className="k-form-field">
									<span>Background color</span>
									<div>
										<div className="general-colorpicker-swatch" onClick={toggleColorPicker}>
											<div className="general-colorpicker-color" style={{ backgroundColor: record.backgroundColor }}></div>
										</div>
										{
											colorPickerVisible ? <div className="general-colorpicker-popover">
												<div className="general-colorpicker-cover" onClick={toggleColorPicker} />
												<CirclePicker color={record.backgroundColor} onChangeComplete={changeBackgroundColor} colors={["#ffffff", "#86BC25", "#26890D", "#0D8390", "#007CB0"]} />
											</div> : null
										}
									</div>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col-md-3">
								<label className="k-form-field">
									<span>{translations.sections_hidetitle}</span>
									{/*
									// @ts-ignore*/}
									<Switch name="hideTitle"
										checked={
											record && record.hideTitle ? record.hideTitle : false
										}
										onChange={onChange}
										onLabel={translations.general_switch_on}
										offLabel={translations.general_switch_off}
									/>
								</label>
							</div>
							<div className="col-md-3">
								<label className="k-form-field">
									<span>{translations.sections_hideHeaderAndFooter}</span>
									{/*
									// @ts-ignore*/}
									<Switch name="hideHeaderAndFooter"
										checked={
											record && record.hideHeaderAndFooter ? record.hideHeaderAndFooter : false
										}
										onChange={onChange}
										onLabel={translations.general_switch_on}
										offLabel={translations.general_switch_off}
									/>
								</label>
							</div>
						</div>
					</form>
				</div>
				<div className={selectedTab === 4 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row">
							<div className="col-md-12 froalaEditorInSections">
								<div className={record.content == "" ? "isrequired" : ""}><FroalaEditorComponent config={editorConfig} model={record.content} onModelChange={changeContent}></FroalaEditorComponent></div>
							</div>
						</div>
					</form>
				</div>
				<div className={selectedTab === 1 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allIndustriesChecked} onChange={selectAllIndustries} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{industries.map((industry: any) => (
							<div className="row spaced-row switch-row" key={industry.id}>
								<div className="col-md-11">
									<span className={industry.checked ? "checked-row" : ""}>{industry.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={industry} checked={industry.checked} onChange={changeIndustry} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off}  />
								</div>
							</div>
						))}
					</form>
				</div>
				<div className={selectedTab === 2 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allServicesChecked} onChange={selectAllServices} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{services.length == 0 && <div style={{ color: "red" }}>{translations.welcomeletters_selectbusinessunitfirst}</div>}
						{services.map((service: any) => (
							<div className="row spaced-row switch-row" key={service.id}>
								<div className="col-md-11">
									<span className={service.checked ? "checked-row" : ""}>{service.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={service} checked={service.checked} onChange={changeService} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
				<div className={selectedTab === 3 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allLanguagesChecked} onChange={selectAllLanguages} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{languages.map((language: any) => (
							<div className="row spaced-row switch-row" key={language.id}>
								<div className="col-md-11">
									<span className={language.checked ? "checked-row" : ""}>{language.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={language} checked={language.checked} onChange={changeLanguage} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
			</div>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				<button className="k-button k-primary" onClick={() => preventDoubleClick(save)}>
					{translations.general_save}
				</button>
			</DialogActionsBar>
		</Dialog>
	);
}

New.defaultProps = {
	save: (record: any) => { },
	cancel: () => { }
};

export default New;