import React, { useState, useEffect } from 'react';

const Privacy: React.FC = () => {
	return (
		<div className="container freepage-container">
			<div className="row myprofile-title">
				<div className="col">
					<h1>Privacy Statement for Proposal Factory (&ldquo;Platform&rdquo;)</h1>
				</div>
			</div>
			<div className="row myprofile-overview">
				<div className="col">
					<p>Last revised: 21 October 2019</p>
					<h3>Introduction</h3>
					<p>Proposal Factory is a web-based tool to improve the entire proposal process (the &ldquo;Platform&rdquo;). The online tool helps you to create a proposal in just clicks and keeps track of administrative tasks, milestones, deadlines, and progress. While you can focus on content, Proposal Factory takes care of all the rest. From resumes to credentials, reviews, and design.</p>
					<h3><strong>Who this privacy statement applies to and what it covers? </strong></h3>
					<p>This Privacy Statement applies to the Belgian Deloitte Company by which you are employed or engaged (or to which you are seconded) and Deloitte Services &amp; Investments NV as "joint controllers", being the entities that jointly determine the purposes and means for processing your personal data as part of the Platform. A complete list of the joint controllers can be found at the end of this Privacy Statement, along with their full address.</p>
					<p>We are committed to protecting your privacy and handling your information in an open and transparent manner. This Privacy Statement sets out how we will collect, handle, store and protect information about you as part of this Platform. It also contains information about when we share your personal data with third parties.</p>
					<p>In this Privacy Statement, your information is sometimes called &ldquo;personal data&rdquo; or &ldquo;personal information&rdquo;. We may also sometimes collectively refer to handling, collecting, protecting and storing your personal information as &ldquo;processing&rdquo; such personal information.</p>
					<h3><strong>What information do we collect?</strong></h3>
					<p>We will collect or obtain the following personal data about you as part of the Platform:</p>
					<ul>
						<li>First name</li>
						<li>Last name</li>
						<li>Level/role</li>
						<li>Address details of the office you work for</li>
						<li>Professional e-mail address</li>
						<li>Professional telephone number</li>
						<li>Professional mobile phone number</li>
						<li>Profile picture (but only in case you have provided your completely voluntary consent in the Platform )</li>
						<li>Professional resume text</li>
					</ul>
					<h3><strong>How we use information about you? </strong></h3>
					<p>We use your personal data for the purposes of providing proposals to perform services to our prospects or clients for which you are being proposed as a Deloitte resource and/or in the course of correspondence with such prospects or clients regarding the proposals.</p>
					<h3><strong>The legal grounds we use for processing personal information </strong></h3>
					<p>We are required by law to set out in this Privacy Statement the legal grounds on which we rely in order to process your personal data.&nbsp;</p>
					<p>As a result, we use your personal data for the purposes outlined above because (i) of our legitimate interests in winning new business with prospects or existing clients (Art. 6.1, f GDPR) or, solely for the use of your picture, (ii) you have given us your consent to do so (Art. 6.1, a GDPR).</p>
					<h3><strong>Who we disclose your information to? </strong></h3>
					<p>In connection with one or more of the purposes outlined in the &ldquo;How we use information about you?&rdquo; section above, we may disclose personal data about you with:</p>
					<ul>
						<li>prospects or clients;</li>
						<li>other employees, (sub)contractors and third parties (including DTTL and other members of the DTTL network or their affiliated companies) who need such information for such purposes or to help us manage or protect our company</li>
					</ul>
					<p>We may also need to disclose your personal data if required to do so by law, a regulator or during legal proceedings.</p>
					<p>When we share your personal data as mentioned above, the third parties with whom we share the personal data may be in the European Economic Area (&ldquo;EEA&rdquo;) or in countries outside the EEA, including countries where the legislation may not offer the same level of data protection. In the latter cases, we will ensure that there are sufficient safeguards to protect your personal data that meet our legal obligations (for example through standard provisions for the transfer of personal data).</p>
					<p>For more information about the third parties we work with and how they treat your personal data, or for information on the appropriate measures we take with regard to data transfers to countries outside the EEA where laws do not offer the same level of data protection, please contact the Privacy Officer by sending an email to belgiumprivacy@deloitte.com.</p>
					<h3><strong>Protection of your personal information</strong></h3>
					<p>We use a range of physical, electronic and managerial measures to ensure that we keep your personal data secure, accurate and up to date. These measures include:</p>
					<ul>
						<li>education and training to relevant staff to ensure they are aware of our privacy obligations when handling personal data</li>
						<li>administrative and technical controls to restrict access to personal data on a &lsquo;need to know&rsquo; basis</li>
						<li>technological security measures, including firewalls, encryption and anti-virus software</li>
						<li>physical security measures, such as staff security passes to access our premises.</li>
					</ul>
					<p>Although we use appropriate security measures once we have received your personal data, the transmission of data over the internet (including by e-mail) is never completely secure. We endeavor to protect personal data, but we cannot guarantee the security of data transmitted to us or by us.</p>
					<h3><strong>How long we keep your information? </strong></h3>
					<p>We will hold your personal data on our systems for the following periods: (i) finalized proposals including your personal data will be kept active on the Platform for 150 days after which they will be archived in the Platform; (ii) archived proposals including your personal data will be deleted from the Platform after 150 days; and (iii) your resume/CV texts will be kept in the Platform for the shortest of the duration of your employment contract with us or until you change to an internal function or some other position within Deloitte for which holding your CV is no longer necessary.</p>
					<h3><strong>Your rights</strong></h3>
					<p>You have various rights in relation to your personal data. In particular, you have a right to:</p>
					<ul>
						<li>access the personal data that we hold about you;</li>
						<li>obtain confirmation that we are processing your personal data and request a copy of the personal data we hold about you</li>
						<li>request that we update or correct the personal data that we hold about you if you believe that it is incorrect or incomplete;</li>
						<li>request that we delete personal data that we hold about you, or restrict the way in which we use such personal data</li>
						<li>object to our processing of your personal data when the processing is not the result of our legal obligation to do so or the data does not have to be processed for the execution of our agreement (s)</li>
						<li>obtain the personal data that you have provided to us in a structured, current and machine-readable form (data portability).</li>
					</ul>
					<p>However, your rights are not absolute. There are cases where applicable laws or legal requirements limit these rights and allow or oblige us to refuse to meet your requests, such as confidentiality obligations, the privacy rights of others, the protection of our legitimate business interests or the interests of our employees or clients.</p>
					<p>We do everything reasonably possible to ensure that your personal data is correct for the intended use. It is your responsibility to inform us of changes to your personal data. You can directly update or correct inaccuracies in your resume text in the Platform. You will be requested to review and update the content of your resume text as part of every proposal in which your profile text will be inserted.</p>
					<p>To exercise your rights, or in case of questions about this Privacy Statement or our use of your personal data as part of the Platform, you can contact the Privacy Office at <a href="mailto:belgiumprivacy@deloitte.com">belgiumprivacy@deloitte.com</a>. When you exercise your rights, please note that the Privacy Office may ask you for specific information to enable them to confirm your identity and the existence and scope of your invoked right.</p>
					<h3><strong>Right to complain </strong></h3>
					<p>If you are not satisfied with the way we have processed your personal data, or with the way we have handled your privacy question or request, you have the right to file a complaint with the Belgian Data Protection Authority:</p>
					<p>
						Data protection authority - Authority for protection of data - Data Protection Authority<br />
						Drukpersstraat 35<br />
						1000 Brussels<br />
						https://www.gegevensbeschermingsautoriteit.be<br />
					</p>
					<h3><strong>Changes to this privacy statement </strong></h3>
					<p>We may modify or amend this privacy statement from time to time.</p>
					<p>To let you know when we make changes to this privacy statement, we will amend the revision date at the top of this page. The new modified or amended privacy statement will apply from that revision date. Therefore, we encourage you to periodically review this Privacy Statement to be informed about how we are protecting your information.</p>
					<h3><strong>List of joint controllers</strong></h3>
					<table cellSpacing={10}>
						<tbody>
							<tr>
								<td>
									<p><strong>Controller</strong></p>
								</td>
								<td>
									<p><strong>Address</strong></p>
								</td>
							</tr>
							<tr>
								<td>
									<p><strong>Deloitte Services &amp; Investments SA/NV </strong></p>
								</td>
								<td>
									<p>
										Gateway Building<br />
										Luchthaven Brussel Nationaal 1J<br />
										1930 Zaventem
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p><strong>Deloitte Accountancy SCRL/CVBA</strong></p>
								</td>
								<td>
									<p>
										Gateway Building<br />
										Luchthaven Brussel Nationaal 1J<br />
										1930 Zaventem
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p><strong>Deloitte Consulting &amp; Advisory SCRL/CVBA</strong></p>
								</td>
								<td>
									<p>
										Gateway Building<br />
										Luchthaven Brussel Nationaal 1J<br />
										1930 Zaventem
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p><strong>Deloitte Bedrijfsrevisoren/Reviseurs d&rsquo;entreprises SCRL/CVBA</strong></p>
								</td>
								<td>
									<p>
										Gateway Building<br />
										Luchthaven Brussel Nationaal 1J<br />
										1930 Zaventem
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p><strong>Deloitte Belastingconsulenten / Conseils fiscaux SCRL/CVBA</strong></p>
								</td>
								<td>
									<p>
										Gateway Building<br />
										Luchthaven Brussel Nationaal 1J<br />
										1930 Zaventem
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p><strong>Deloitte Global Tax Center (Europe) SCRL/CVBA</strong></p>
								</td>
								<td>
									<p>
										Gateway Building<br />
										Luchthaven Brussel Nationaal 1J<br />
										1930 Zaventem
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p><strong>Deloitte Finance SCRL/CVBA</strong></p>
								</td>
								<td>
									<p>
										Gateway Building<br />
										Luchthaven Brussel Nationaal 1J<br />
										1930 Zaventem
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default Privacy;
