import React, { useState, useEffect } from 'react';

interface ButtonProps {
	title?: string | undefined,
	additionalClass?: string | undefined,
	onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
	disabled?: boolean | undefined,
	hidden?: boolean | undefined,
	key?: string | number | undefined
}

const DeloitteButton: React.FC<ButtonProps> = (props) => {
	return (
		<button key={props.key} title={props.title} className={"k-button k-primary deloitte-button " + props.additionalClass} onClick={props.onClick} disabled={props.disabled} hidden={props.hidden} >
			{props.children}
		</button>
	);
};

export default DeloitteButton;