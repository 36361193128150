import React, { useState, useEffect } from 'react';
import GridPanel from '../../global/gridpanel';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import cellButton from '../../global/cellbutton';
import Wizard from './wizard';
import Editor from './editor';
import { show, close, showLoader, hideLoader } from '../../../services/renderer';
import repoProposals from '../../../repositories/proposals/proposals';
import repoTasks from '../../../repositories/tasks/tasks';
import { notify, alert } from '../../../services/notification';
import { triggerListeners } from '../../../services/listeners';
import { cloneObject } from '../../../services/tools';
import { isAuthorized } from '../../../services/authorization';
import translations, { getEmailBodyForProposal } from '../../../translations';
import DeloitteButton from '../../global/deloittebutton';
import IconButton from '../../global/iconbutton';
import SendProposal from './send';
import { getMyProfile } from '../../../services/authentication';

interface ProposalProps {
	isHome: boolean
}

const List: React.FC<ProposalProps> = (props) => {

	function edit(dataItem: any) {
		show(Editor, "proposaledit", { dataItem: cloneObject(dataItem), save: save, cancel: cancel });
	}

	function preview(dataItem: any) {
		showLoader();
		repoProposals.preview(dataItem.id).then((result) => {
			reportWhenDone(result, false);
		});
	}

	function previewPDF(dataItem: any) {
		showLoader();
		repoProposals.previewPDF(dataItem.id).then((result) => {
			reportWhenDone(result, true);
		});
	}

	function reportWhenDone(taskID: string, isPDF: boolean) {
		repoTasks.get(taskID).then((result) => {
			if (result.status == "Done") {
				showPreview(result.payload, isPDF);
			} else if (result.status == "Error") {
				hideLoader();
				alert(result.errorMessage);
			} else {
				setTimeout(() => {
					reportWhenDone(taskID, isPDF);
				}, 1000);
			}
		});
	}

	function showPreview(proposalID: string, isPDF: boolean) {
		repoProposals.getPreviewCookie(proposalID).then(() => {
			hideLoader();
			if (isPDF) {
				window.open("/proposals/" + proposalID + "/pdf/0/proposal.pdf");
			} else {
				window.open("/proposals/" + proposalID);
			}
		});
	}

	function cancel() {
		triggerListeners("reloadProposals");
		close("proposaledit");
		close("proposalnew");
	}

	function save() {
		triggerListeners("reloadProposals");
		cancel();
	}

	const statusCell = (cellProps: any) => {
		let status = "";
		if (cellProps.dataItem.status == "InProgress") status = "In progress";
		if (cellProps.dataItem.status == "ReadyForReview") status = "Ready for review";
		if (cellProps.dataItem.status == "InReview") status = "In review";
		if (cellProps.dataItem.status == "ReadyToSend") status = "Ready to send";
		if (cellProps.dataItem.status == "Sending") status = "Sending";
		if (cellProps.dataItem.status == "Defend") status = "Defend";
		if (cellProps.dataItem.status == "Won") status = "Won";
		if (cellProps.dataItem.status == "Lost") status = "Lost";
		return (
			<td>
				{status}
			</td>
		);
	};

	const actionsCell = (cellProps: any) => {
		return (
			<td>
				{cellProps.dataItem.status !== "Sending" && (
						<IconButton
							title="Edit"
							iconClass="deloitte-icon icon-file_edit_special"
						onClick={() => { edit(cellProps.dataItem); }}
						/>
				)}
				{cellProps.dataItem.status !== "Sending" && (
						<IconButton
							title="Preview microsite"
							iconClass="deloitte-icon icon-globe_special"
						onClick={() => { preview(cellProps.dataItem); }}
						/>
				)}
				{cellProps.dataItem.status !== "Sending" && (
						<IconButton
							title="Preview PDF"
							iconClass="deloitte-icon icon-file_pdf_special"
						onClick={() => { previewPDF(cellProps.dataItem); }}
						/>
				)}
				{cellProps.dataItem.status == "ReadyToSend" && cellProps.dataItem.signingPartnerID == getMyProfile().userID && (
					<IconButton
						title="Send Proposal to client"
						iconClass="deloitte-icon icon-mail_special"
						onClick={() => { sendProposal(cellProps.dataItem); }}
					/>
				)}
			</td>
		);
	};

	function sendProposal(record: any) {
		show(SendProposal, "sendproposal", {
			save: (sendproposal: any) => {
				showLoader();
				repoProposals.save({
					...record,
					emailBody: sendproposal.emailBody
				}).then(() => {
					repoProposals.send(record.id).then(() => {
						hideLoader();
						close("sendproposal");
					}).catch((err: any) => {
						alert(err);
					});;
				}).catch((err: any) => {
					alert(err);
				});
			},
			cancel: () => {
				close("sendproposal");
			},
			dataItem: {
				emailBody: getEmailBodyForProposal(record),
				title: record.companyName
			}
		})
	}

	return (
		<div>
			<GridPanel reloadKey="reloadProposals" loadData={repoProposals.getForOwner} onRowDoubleClick={edit}>
				{props.isHome !== true && (<GridToolbar >
					<div>
						<DeloitteButton title={translations.general_addnew} onClick={() => { show(Wizard, "proposalnew", { save: save, cancel: cancel }); }} >
							{translations.general_addnew}
						</DeloitteButton>
					</div>
				</GridToolbar>)}
				<GridColumn field="sapReference" title={translations.proposals_opportunity} width={140} editable={false} />
				{props.isHome !== true && (<GridColumn field="creationDate" title={translations.proposals_creationdate} editable={false} filter={'date'} format="{0:yyyy/MM/dd}" />)}
				<GridColumn field="companyName" title={translations.proposals_customer} width={180} editable={false} />
				<GridColumn field="title" title={translations.proposals_title} editable={false} />
				{props.isHome !== true && (<GridColumn field="opportunity.businessUnit.name" title={translations.proposals_businessunit} editable={false} />)}
				<GridColumn field="status" title={translations.proposals_status} width={140} cell={statusCell} editable={false} />
				<GridColumn filterable={false} editable={false} width={160} title="Actions" cell={actionsCell} />
			</GridPanel>
		</div>
	);
}

export default List;