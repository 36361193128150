import axios from "axios";
import { IUser, getMyProfile } from "./authentication";
import _ from "lodash";


export const isAuthorized = (where: string, what: string) => {
	const authenticatedUser: IUser = getMyProfile();
	let returnValue = false;
	if (authenticatedUser) {

		const splits = where.split('|');
		
		for (let i = 0; i < splits.length; i++) {

			if (returnValue === true) break;
			
			if (what === "*") {
				let filteredPermissions = _.filter(Object.keys(authenticatedUser.permissions), (key: string) => {
					return (key.indexOf(splits[i]) > -1 && authenticatedUser.permissions[key] === true);
				});
				returnValue = filteredPermissions.length > 0;
			} else {
				const splitsWhat = what.split('|');
				for (let j = 0; j < splitsWhat.length; j++) {
					if (returnValue === true) break;
					returnValue = (authenticatedUser.permissions[splits[i] + "_" + splitsWhat[j]] ? true : false);
				}
			}
		}
	} 

	return returnValue;
};
