///<reference path= "../../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect } from 'react';
import { minimalEditor } from '../../../../services/environment';
import { Upload } from '@progress/kendo-react-upload';
import FilePreview from '../../../global/filepreview';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Input, Switch, NumericTextBox } from '@progress/kendo-react-inputs';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import repoCountryLanguages from '../../../../repositories/settings/countrylanguages';
import repoIndustries from '../../../../repositories/settings/industries';
import repoServices from '../../../../repositories/settings/services';
import repoAchievements from '../../../../repositories/settings/achievements';
import { useBusinessUnits } from '../../../../repositories/settings/businessunits';
import repoUsers from '../../../../repositories/security/users';
import _ from 'lodash';
import { alert } from '../../../../services/notification';
import { showLoader, hideLoader, confirmChanged } from '../../../../services/renderer';
import translations, { getCurrentLanguage } from '../../../../translations';
import { preventDoubleClick } from '../../../../services/tools';

interface NewProps {
	save: (record: any) => void,
	cancel: () => void
}

const New: React.FC<NewProps> = (props) => {

	const [record, setRecord] = useState({
		id: "00000000-0000-0000-0000-000000000000",
		businessUnitID: "00000000-0000-0000-0000-000000000000",
		projectPartnerID: "00000000-0000-0000-0000-000000000000",
		projectManagerID: "00000000-0000-0000-0000-000000000000",
		clientName: "",
		governementLevel: "",
		projectName: "",
		projectType: "",
		policyArea: "",
		currency: "",
		budget: undefined,
		numberOfInvolvedStaff: undefined,
		period: undefined,
		contactClient: "",
		subcontractorsPartners: false,
		percentBudget: undefined,
		subcontractorDetails: "",
		description: "",
		shortDescription: "",
		industries: new Array(),
		services: new Array(),
		languages: new Array(),
		active: false,
	});
	const businessunits = useBusinessUnits();
	const [users, setUsers] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [allLanguagesChecked, setAllLanguagesChecked] = useState(false);
	const [industries, setIndustries] = useState([]);
	const [allIndustriesChecked, setAllIndustriesChecked] = useState(false);
	const [services, setServices] = useState([]);
	const [projectTypes, setProjectTypes] = useState([]);
	const [allServicesChecked, setAllServicesChecked] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [editorConfig] = useState({
		...minimalEditor,
		placeholderText: translations.achievements_description_placeholder,
		language: getCurrentLanguage(),
		height: 250,
	});
	const [editorConfigShort] = useState({
		...minimalEditor,
		placeholderText: translations.achievements_description_placeholder,
		language: getCurrentLanguage(),
		height: 250,
	});
	const [dataChanged, setDataChanged] = useState(false);

	useEffect(() => {
		repoUsers.searchActive("").then((result) => {
			setUsers(result);
		});
		repoAchievements.getProjectTypes().then((result) => {
			setProjectTypes(result);
		});
		repoCountryLanguages.getActive().then((result) => {
			setLanguages(result.map((language: any) => {
				return {
					id: language.id,
					name: language.name,
					checked: false
				}
			}));
		});
		repoIndustries.getActive().then((result) => {
			setIndustries(result.map((industry: any) => {
				return {
					id: industry.id,
					name: industry.name,
					checked: false
				}
			}));
		});
	}, []);

	function filterChange(event: any) {
		repoUsers.searchActive((event.filter && event.filter.value) ? event.filter.value : '').then((result) => {
			setUsers(result);
		});
	}

	function loadServices(activeRecord: any, businessUnitID: any) {
		showLoader();
		repoServices.subServices.getActive(businessUnitID).then((result) => {
			setServices(result.map((service: any) => {
				return {
					id: service.id,
					name: service.mainOffer.mainOffering + " > " + service.subOffering,
					checked: _.some(activeRecord.services, { serviceID: service.id })
				}
			}));
			hideLoader();
		});
	}

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			case "projectPartnerID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.userID : "00000000-0000-0000-0000-000000000000";
				break;
			case "projectManagerID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.userID : "00000000-0000-0000-0000-000000000000";
				break;
			case "businessUnitID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				loadServices(newRecord, event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000001");
				break;
			case "currency":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.key : "en-US,USD";
				break;
			default:
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value;
		}
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onChangePeriod(event: any) {
		let newRecord = { ...record };
		newRecord.period = event.value;
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onChangeSubcontactorDetails(event: any) {
		let newRecord = { ...record };
		newRecord.subcontractorDetails = event.target.value;
		setRecord(newRecord);
		setDataChanged(true);
	}

	function changeContent(newContent: string) {
		let newRecord = { ...record };
		newRecord.description = newContent;
		setRecord(newRecord);
		setDataChanged(true);
	}

	function changeContentShort(newContent: string) {
		let newRecord = { ...record };
		newRecord.shortDescription = newContent;
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		// @ts-ignore
		record.industries.length = 0;
		industries.forEach((industry: any) => {
			if (industry.checked) record.industries.push(
				{
					industryID: industry.id
				}
			);
		});
		record.services.length = 0;
		services.forEach((service: any) => {
			if (service.checked) record.services.push(
				{
					serviceID: service.id
				}
			);
		});
		record.languages.length = 0;
		languages.forEach((language: any) => {
			if (language.checked) record.languages.push(
				{
					languageID: language.id
				}
			);
		});
		props.save(record);
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	function changeIndustry(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllIndustriesChecked(false);
		setIndustries([...industries]);
		setDataChanged(true);
	}

	function selectAllIndustries(e: any) {
		setAllIndustriesChecked(e.target.value);
		industries.forEach((industry: any) => {
			industry.checked = e.target.value;
		});
		setIndustries([...industries]);
		setDataChanged(true);
	}

	function changeService(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllServicesChecked(false);
		setServices([...services]);
		setDataChanged(true);
	}

	function selectAllServices(e: any) {
		setAllServicesChecked(e.target.value);
		services.forEach((service: any) => {
			service.checked = e.target.value;
		});
		setServices([...services]);
		setDataChanged(true);
	}

	function changeLanguage(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllLanguagesChecked(false);
		setLanguages([...languages]);
		setDataChanged(true);
	}

	function selectAllLanguages(e: any) {
		setAllLanguagesChecked(e.target.value);
		languages.forEach((language: any) => {
			language.checked = e.target.value;
		});
		setLanguages([...languages]);
		setDataChanged(true);
	}

	return (
		<Dialog
			onClose={cancel}
			title={translations.general_new + " " + translations.achievements_windowtitlesingle}
			width="70%"
		>
			<div className="tab-bar">
				<ul>
					<li className={selectedTab === 0 ? "active" : ""} onClick={() => { setSelectedTab(0); }}>{translations.general_tab_details}</li>
					<li className={selectedTab === 4 ? "active" : ""} onClick={() => { setSelectedTab(4); }}>{translations.achievements_shortdescription}</li>
					<li className={selectedTab === 5 ? "active" : ""} onClick={() => { setSelectedTab(5); }}>{translations.achievements_description}</li>
					<li className={selectedTab === 1 ? "active" : ""} onClick={() => { setSelectedTab(1); }}>{translations.general_tab_industries}</li>
					<li className={selectedTab === 2 ? "active" : ""} onClick={() => { setSelectedTab(2); }}>{translations.general_tab_services}</li>
					<li className={selectedTab === 3 ? "active" : ""} onClick={() => { setSelectedTab(3); }}>{translations.general_tab_languages}</li>
				</ul>
			</div>
			<div className="tab-bar-container">
				<div className={selectedTab === 0 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row">
							<div className="col-md-6">
								<label className="k-form-field">
									<span>{translations.achievements_clientname}</span>
									<Input
										name="clientName"
										style={{ width: "100%" }}
										placeholder={translations.general_placeholder_prefix + " " + translations.achievements_clientname + "..."}
										value={record && record.clientName ? record.clientName : ""}
										onChange={onChange}
										required={true}
										validationMessage={translations.general_validationmessage + " " + translations.achievements_clientname}
									/>
								</label>
							</div>
							<div className="col-md-6">
								<label className="k-form-field">
									<span>{translations.achievements_governementlevel}</span>
									<Input
										name="governementLevel"
										style={{ width: "100%" }}
										placeholder={translations.general_placeholder_prefix + " " + translations.achievements_governementlevel + "..."}
										value={record && record.governementLevel ? record.governementLevel : ""}
										onChange={onChange}
										required={true}
										validationMessage={translations.general_validationmessage + " " + translations.achievements_governementlevel}
									/>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<label className="k-form-field">
									<span>{translations.achievements_projectname}</span>
									<Input
										name="projectName"
										style={{ width: "100%" }}
										placeholder={translations.general_placeholder_prefix + " " + translations.achievements_projectname + "..."}
										value={record && record.projectName ? record.projectName : ""}
										onChange={onChange}
										required={true}
										validationMessage={translations.general_validationmessage + " " + translations.achievements_projectname}
									/>
								</label>
							</div>
							<div className="col-md-6">
								<label className="k-form-field">
									<span>{translations.achievements_projecttype}</span>
									<ComboBox
										data={projectTypes}
										name="projectType"
										value={record && record.projectType ? record.projectType : undefined}
										style={{ width: "100%" }}
										onChange={onChange}
										required={true}
										allowCustom={true}
									/>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<label className="k-form-field">
									<span>{translations.achievements_policyarea}</span>
									<Input
										name="policyArea"
										style={{ width: "100%" }}
										placeholder={translations.general_placeholder_prefix + " " + translations.achievements_policyarea + "..."}
										value={record && record.policyArea ? record.policyArea : ""}
										onChange={onChange}
										required={true}
										validationMessage={translations.general_validationmessage + " " + translations.achievements_policyarea}
									/>
								</label>
							</div>
							<div className="col-md-6">
								<label className="k-form-field">
									<span>{translations.achievements_contactclient}</span>
									<Input
										name="contactClient"
										style={{ width: "100%" }}
										placeholder={translations.general_placeholder_prefix + " " + translations.achievements_contactclient + "..."}
										value={record && record.contactClient ? record.contactClient : ""}
										onChange={onChange}
										required={true}
										validationMessage={translations.general_validationmessage + " " + translations.achievements_contactclient}
									/>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<label className="k-form-field">
									<span>{translations.achievements_currency}</span>
									<ComboBox
										data={[
											{ key: "en-US,USD", value: "$" },
											{ key: "nl-BE,EUR", value: "€" }
										]}
										name="currency"
										dataItemKey="key"
										textField="value"
										style={{ width: "100%" }}
										onChange={onChange}
										required={true}
									/>
								</label>
							</div>
							<div className="col-md-4">
								<label className="k-form-field">
									<span>{translations.achievements_budget}</span>
									<NumericTextBox name="budget" onChange={onChange} value={record && record.budget ? record.budget : undefined} required={true} min={0} format="#,###.##"></NumericTextBox>
								</label>
							</div>
							<div className="col-md-4">
								<label className="k-form-field">
									<span>{translations.achievements_numberofinvolvedstaff}</span>
									<NumericTextBox name="numberOfInvolvedStaff" onChange={onChange} value={record && record.numberOfInvolvedStaff ? record.numberOfInvolvedStaff : undefined} required={true} min={0} format="#"></NumericTextBox>
								</label>
							</div>
						</div>
						<label className="k-form-field">
							<span>{translations.achievements_period}</span>
							<DateRangePicker value={record.period} onChange={onChangePeriod} format="MM/yyyy" calendarSettings={{ bottomView: 'year', topView: 'year', views: 2 }}></DateRangePicker>
						</label>
						<div className="row">
							<div className="col-md-6">
								<label className="k-form-field">
									<span>{translations.achievements_projectpartner}</span>
									<ComboBox
										data={users}
										name="projectPartnerID"
										defaultValue={record && record.projectPartnerID ? record.projectPartnerID : undefined}
										dataItemKey="userID"
										textField="fullName"
										style={{ width: "100%" }}
										onChange={onChange}
										required={true}
										filterable={true}
										onFilterChange={filterChange}
									/>
								</label>
							</div>
							<div className="col-md-6">
								<label className="k-form-field">
									<span>{translations.achievements_projectmanager}</span>
									<ComboBox
										data={users}
										name="projectManagerID"
										defaultValue={record && record.projectManagerID ? record.projectManagerID : undefined}
										dataItemKey="userID"
										textField="fullName"
										style={{ width: "100%" }}
										onChange={onChange}
										required={true}
										filterable={true}
										onFilterChange={filterChange}
									/>
								</label>
							</div>
						</div>
						<label className="k-form-field">
							<span>{translations.achievements_businessunit}</span>
							<ComboBox
								data={businessunits}
								name="businessUnitID"
								dataItemKey="id"
								textField="name"
								style={{ width: "100%" }}
								onChange={onChange}
								required={true}
								suggest={true}
							/>
						</label>
						<label className="k-form-field">
							<span>{translations.achievements_subcontractorspartners}</span>
							{/*
							// @ts-ignore*/}
							<Switch name="subcontractorsPartners"
								checked={
									record && record.subcontractorsPartners ? record.subcontractorsPartners : false
								}
								onChange={onChange}
								onLabel={translations.general_switch_on}
								offLabel={translations.general_switch_off}
							/>
						</label>
						{record.subcontractorsPartners === true && (
							<div className="row">
								<div className="col-md-6">
									<label className="k-form-field">
										<span>{translations.achievements_percentbudget}</span>
										<NumericTextBox name="percentBudget" onChange={onChange} value={record && record.percentBudget ? record.percentBudget : undefined} min={0} format="#"></NumericTextBox>
									</label>
								</div>
								<div className="col-md-6">
									<label className="k-form-field">
										<span>{translations.achievements_subcontractordetails}</span>
										<textarea name="subcontractorDetails" style={{ height: 100, width: "100%" }} className="k-textarea" value={record && record.subcontractorDetails ? record.subcontractorDetails : ""} onChange={onChangeSubcontactorDetails}></textarea>
									</label>
								</div>
							</div>
						)}
						<label className="k-form-field">
							<span>{translations.general_active}</span>
							{/*
							// @ts-ignore*/}
							<Switch name="active"
								checked={
									record && record.active ? record.active : false
								}
								onChange={onChange}
								onLabel={translations.general_switch_on}
								offLabel={translations.general_switch_off}
							/>
						</label>
					</form>
				</div>
				<div className={selectedTab === 4 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row">
							<div className="col-md-12">
								<div className={record.shortDescription == "" ? "isrequired" : ""}><FroalaEditorComponent config={editorConfigShort} model={record.shortDescription} onModelChange={changeContentShort}></FroalaEditorComponent></div>
							</div>
						</div>
					</form>
				</div>
				<div className={selectedTab === 5 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row">
							<div className="col-md-12">
								<div className={record.description == "" ? "isrequired" : ""}><FroalaEditorComponent config={editorConfig} model={record.description} onModelChange={changeContent}></FroalaEditorComponent></div>
							</div>
						</div>
					</form>
				</div>
				<div className={selectedTab === 1 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allIndustriesChecked} onChange={selectAllIndustries} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{industries.map((industry: any) => (
							<div className="row spaced-row switch-row" key={industry.id}>
								<div className="col-md-11">
									<span className={industry.checked ? "checked-row" : ""}>{industry.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={industry} checked={industry.checked} onChange={changeIndustry} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
				<div className={selectedTab === 2 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allServicesChecked} onChange={selectAllServices} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{services.length == 0 && <div style={{ color: "red" }}>{translations.welcomeletters_selectbusinessunitfirst}</div>}
						{services.map((service: any) => (
							<div className="row spaced-row switch-row" key={service.id}>
								<div className="col-md-11">
									<span className={service.checked ? "checked-row" : ""}>{service.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={service} checked={service.checked} onChange={changeService} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
				<div className={selectedTab === 3 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allLanguagesChecked} onChange={selectAllLanguages} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{languages.map((language: any) => (
							<div className="row spaced-row switch-row" key={language.id}>
								<div className="col-md-11">
									<span className={language.checked ? "checked-row" : ""}>{language.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={language} checked={language.checked} onChange={changeLanguage} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
			</div>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				<button className="k-button k-primary" onClick={() => preventDoubleClick(save)}>
					{translations.general_save}
				</button>
			</DialogActionsBar>
		</Dialog>
	);
}

New.defaultProps = {
	save: (record: any) => { },
	cancel: () => { }
};

export default New;