import axios from 'axios';
import { getToken } from '../../services/authentication';

const baseURL = "/api/settings/languages/";

const repository = {
	getActive: async () => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "getactive", {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	}
};

export default repository;