///<reference path= "../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect } from 'react';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { getMyProfile } from '../../services/authentication';
import translations, { getCurrentLanguage } from '../../translations';
import '../../assets/css/myprofile.scss';
import repoCountryLanguages from '../../repositories/settings/countrylanguages';
import repoUserResumes from '../../repositories/security/userresumes';
import _ from 'lodash';
import { Promise } from 'bluebird';
import { Prompt } from 'react-router-dom';
import { showLoader, hideLoader } from '../../services/renderer';
import { Input, Switch } from '@progress/kendo-react-inputs';
import DeloitteButton from '../global/deloittebutton';
import config from '../../config.json';
	//const translations = getTranslations();

const MyProfile: React.FC = () => {
	const [myProfile] = useState(getMyProfile());
	const [profileChanged, setProfileChanged] = useState(false);
	const [activeLanguage, setActiveLanguage] = useState();
	const [languages, setLanguages] = useState([]);
	const [activeResume, setActiveResume] = useState({
		content: ""
	});
	const [resumes, setResumes] = useState([]);

	useEffect(() => {
		showLoader();
		repoCountryLanguages.getActive().then((resultLanguages) => {
			repoUserResumes.getAll().then((resultResumes) => {
				resultLanguages.forEach((language: {id: string}) => {
					var foundResume = _.find(resultResumes, {
						languageID: language.id
					});
					if (!foundResume) {
						var newResume = {
							languageID: language.id,
							content: ""
						};
						resultResumes.push(newResume);
					}
				});
				var resume = _.find(resultResumes, {
					languageID: resultLanguages[0].id
				});
				setActiveResume(resume);
				setResumes(resultResumes);
				setActiveLanguage(resultLanguages[0]);
				setLanguages(resultLanguages);
				hideLoader();
			});
		});
	}, []);

	const editorConfig = {
		key: config.froala.key,
		attribution: false,
		placeholderText: translations.myprofile_resume_placeholder,
		language: getCurrentLanguage(),
		height: 400,
		enter: 2,
		wordPasteKeepFormatting: false,
		pastePlain: true,
		toolbarButtons: {
			moreText: {
				buttons: [
					"bold",
					"italic",
					"underline",
					"subscript",
					"superscript",
				],
				align: "left",
				buttonsVisible: 5,
			},
			moreParagraph: {
				buttons: [
					"clearFormatting",
					"align",
					"formatOL",
					"formatUL",
					"indent",
					"outdent",
				],
				align: "left",
				buttonsVisible: 6,
			},
			moreMisc: {
				buttons: [
					"undo",
					"redo",
					"fullscreen",
				],
				align: "right",
			}
		},
	};

	function switchLanguage(language: any) {
		var resume: any = _.find(resumes, {
			languageID: language.id
		});
		if (resume) setActiveResume(resume);
		setActiveLanguage(language);
	}

	function changeResume(newContent: string) {
		if (activeLanguage) {
			var resume: any = _.find(resumes, {
				languageID: activeLanguage.id
			});
			if (resume) {
				resume.content = newContent;
				setProfileChanged(true);
				setActiveResume(resume);
			}
		}
	}

	function save() {
		showLoader();
		Promise.map(resumes, (resume) => {
			return repoUserResumes.save(resume);
		}).then(() => {
			hideLoader();
			setProfileChanged(false);
		}).catch(error => {
			hideLoader();
		});
	}

	return (
		<div className="container freepage-container">
			<Prompt when={profileChanged} message={translations.general_continue_message} />
			<div className="row myprofile-title">
				<div className="col">
					<h1>{translations.myprofile_yourprofile}</h1>
				</div>
			</div>
			<div className="row myprofile-overview">
				<div className="col-md-3">
					<div className="myprofile-image">
						{myProfile.image.size > 0 ? <img src={"/api/content/files/get/" + myProfile.image.internalFilename + "/" + myProfile.image.filename}></img> : <i className="deloitte-icon icon-profile_special" aria-hidden="true"></i>}
					</div>
				</div>
				<div className="col-md-9">
					<div className="col-12 myprofile-name">{myProfile.firstName} {myProfile.lastName}</div>

					<div className="myprofile-fields">
						<div className="col-12 myprofile-field">{translations.myprofile_function} <span className="myprofile-fieldvalue">{myProfile.function}</span></div>
						<div className="col-12 myprofile-field">{translations.myprofile_businessunit} <span className="myprofile-fieldvalue">{myProfile.businessUnit ? myProfile.businessUnit.name : ""}</span></div>
						<div className="col-12 myprofile-field">{translations.myprofile_location} <span className="myprofile-fieldvalue">{myProfile.location}</span></div>
						
						<div className="col-12 myprofile-spacer">&nbsp;</div>

						<div className="col-12 myprofile-field">{translations.myprofile_email} <span className="myprofile-fieldvalue">{myProfile.email}</span></div>
						<div className="col-12 myprofile-field">{translations.myprofile_phone} <span className="myprofile-fieldvalue">{myProfile.phone}</span></div>
						<div className="col-12 myprofile-field">{translations.myprofile_mobile} <span className="myprofile-fieldvalue">{myProfile.mobile}</span></div>

						<div className="col-12 myprofile-spacer">&nbsp;</div>

						<div className="col-12 myprofile-field">Privacy: <span className="myprofile-fieldvalue">
							<label className="k-form-field">
								<input type="checkbox" className="k-checkbox" checked={true} readOnly={true} />
								<label className="k-checkbox-label">I agree that my profile picture from the who is who page on the Deloitte intranet can be inserted into my CV as uploaded in this tool.</label>
							</label>
						</span></div>
					</div>
				</div>
			</div>
			<div className="row myprofile-title">
				<div className="col">
					<h1>{translations.myprofile_yourresumes}</h1>
				</div>
			</div>
			<div className="row myprofile-resumes">
				<div className="col-md-1">
					{languages.map((language: any) => (
						<div style={{ cursor: "pointer" }} key={language.id} className={"myprofile-resume-language " + (language.id === activeLanguage.id ? "active" : "")} onClick={() => { switchLanguage(language); }}>{language.code}</div>
					))}
				</div>
				{ languages.length > 0 && (
					<div className="col-md-11">
						<div className="myprofile-savebutton">
							<DeloitteButton onClick={save}>
								{translations.myprofile_save}
							</DeloitteButton>
						</div>
						<FroalaEditorComponent config={editorConfig} model={activeResume.content} onModelChange={changeResume}></FroalaEditorComponent>
					</div>
				)}
			</div>
		</div>
	);
}

export default MyProfile;
