import _ from 'lodash';
let listeners: any = [];

export const addListener = (key: string, callback: () => void) => {
	listeners.push({
		key: key,
		callback: callback
	});
};

export const removeListener = (key: string) => {
	// @ts-ignore
	_.remove(listeners, {
		key: key
	});
};

export const triggerListeners = (key: string, action?: string, payload?: any) => {
	_(listeners).filter({
		key: key
	}).forEach(listener => {
		listener.callback(action, payload);
	});
};