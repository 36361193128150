import React, { useState, useEffect } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

interface ConfirmProps {
	title: string,
	message: string,
	yes: () => void,
	no?: () => void,
	yesLabel?: string,
	noLabel?: string,
	cancel: () => void 
};

const Confirm: React.FC<ConfirmProps> = (props) => {
	return (
		<Dialog onClose={props.cancel} title={props.title} width={300}>
			<div dangerouslySetInnerHTML={{__html: props.message}}></div>
			<DialogActionsBar>
				{props.no && <button className="k-button" onClick={props.no}>{props.noLabel}</button>}
				<button className="k-button k-primary" onClick={props.yes}>{props.yesLabel}</button>
			</DialogActionsBar>
		</Dialog>
	);
};

export default Confirm;