import { newGuid } from './tools';

/*
	Notification Types:
		- success
		- info
		- error
		- warning
*/

export interface INotificationConfig {
	GUID?: string;
	type?: "success" | "error" | "none" | "warning" | "info" | undefined;
	message: string;
}

let notificationCollection = <INotificationConfig[]>[];
let eventHandlers = <Function[]>[];

function notify(conf: INotificationConfig | string) {
	var newConfig: INotificationConfig = {
		GUID: newGuid(),
		message: typeof conf === "string" ? conf : conf.message,
		type: (typeof conf !== "string" && conf.type) ? conf.type : "success"
	};
	
	//Create new instance of the collection, with the new conf included
	const newCollection: INotificationConfig[] = [...notificationCollection, newConfig];
	//Set new collection
	notificationCollection = newCollection;
	//Trigger event handlers
	triggerEventHandlers();


	setTimeout(function() {
		//Get index to remove from the colleciton
		const indexToDelete = notificationCollection.indexOf(newConfig);
		//Create new instance of the collection, else react wont recognize the change
		const newCollectionTemporary: INotificationConfig[] = [...notificationCollection];
		//Actual removal from collection
		newCollectionTemporary.splice(indexToDelete, 1);
		//Set new collection
		notificationCollection = newCollectionTemporary;
		//Trigger event handlers
		triggerEventHandlers();
	},5000);

}

function alert(message: string | any) {
	if (typeof message !== "string") {
		if (message && message.message) {
			message = message.message;
		} else {
			message = "An unexpected error has occurred!";
		}
	}
	notify({
		message: message,
		type: "error"
	});
}

function setEventHandler(fn: Function) {
	eventHandlers = <Function[]>[...eventHandlers, fn];
}

function triggerEventHandlers() {
	eventHandlers.forEach((handler) => handler() );
}

export { notificationCollection };
export { notify, alert };
export { setEventHandler as onCollectionChanged };