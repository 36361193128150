///<reference path= "../../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect } from 'react';
import { editorWelcomeLetter } from '../../../../services/environment';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { showLoader, hideLoader, confirmChanged } from '../../../../services/renderer';
import repoWelcomeLetters from '../../../../repositories/settings/welcomeletters';
import { useLanguages } from '../../../../repositories/settings/countrylanguages';
import { useBusinessUnits } from '../../../../repositories/settings/businessunits';
import repoIndustries from '../../../../repositories/settings/industries';
import repoServices from '../../../../repositories/settings/services';
import { getMyProfile } from '../../../../services/authentication';
import _ from 'lodash';
import { isAuthorized } from '../../../../services/authorization';
import translations, { getCurrentLanguage } from '../../../../translations';
import { preventDoubleClick } from '../../../../services/tools';

interface EditProps {
	dataItem: any,
	save: (record: any) => void,
	cancel: () => void
}

const Edit: React.FC<EditProps> = (props) => {

	const [record, setRecord] = useState<any>({});
	const businessunits = useBusinessUnits();
	const languages = useLanguages();
	const [industries, setIndustries] = useState([]);
	const [allIndustriesChecked, setAllIndustriesChecked] = useState(false);
	const [services, setServices] = useState([]);
	const [allServicesChecked, setAllServicesChecked] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [editorConfig] = useState({
		...editorWelcomeLetter,
		placeholderText: translations.welcomeletters_content_placeholder,
		language: getCurrentLanguage(),
		height: 400,
	});
	const [dataChanged, setDataChanged] = useState(false);
	const [permissions, setPermissions] = useState("none");

	useEffect(() => {
		showLoader();
		repoWelcomeLetters.get(props.dataItem.id).then((resultRecord) => {
			setRecord(resultRecord);
			repoIndustries.getActive().then((result) => {
				setIndustries(result.map((industry: any) => {
					return {
						id: industry.id,
						name: industry.name,
						checked: _.some(resultRecord.industries, { industryID: industry.id })
					}
				}));
			});
			loadServices(resultRecord, resultRecord.businessUnitID);
			hideLoader();
		});
		
		if (isAuthorized("WelcomeLetters", "Update")) {
			setPermissions("Update");
		} else if ((isAuthorized("WelcomeLetters", "Read"))) {
			setPermissions("Read");
		}

	}, []);

	function loadServices(activeRecord: any, businessUnitID: any) {
		repoServices.subServices.getActive(businessUnitID).then((result) => {
			setServices(result.map((service: any) => {
				return {
					id: service.id,
					name: service.mainOffer.mainOffering + " > " + service.subOffering,
					checked: _.some(activeRecord.services, { serviceID: service.id })
				}
			}));
		});
	}

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			case "businessUnitID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				loadServices(newRecord, event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000001");
				break;
			case "languageID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				break;
			case "type":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.key : "New";
				break;
			default:
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value;
		}
		setRecord(newRecord);
		setDataChanged(true);
	}

	function changeContent(newContent: string) {
		let newRecord = { ...record };
		newRecord.content = newContent;
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		record.industries.length = 0;
		industries.forEach((industry: any) => {
			if (industry.checked) record.industries.push(
				{
					welcomeLetterID: record.id,
					industryID: industry.id
				}
			);
		});
		record.services.length = 0;
		services.forEach((service: any) => {
			if (service.checked) record.services.push(
				{
					welcomeLetterID: record.id,
					serviceID: service.id
				}
			);
		});
		props.save(record);
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	function changeIndustry(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllIndustriesChecked(false);
		setIndustries([...industries]);
		setDataChanged(true);
	}

	function selectAllIndustries(e: any) {
		setAllIndustriesChecked(e.target.value);
		industries.forEach((industry: any) => {
			industry.checked = e.target.value;
		});
		setIndustries([...industries]);
		setDataChanged(true);
	}

	function changeService(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllServicesChecked(false);
		setServices([...services]);
		setDataChanged(true);
	}

	function selectAllServices(e: any) {
		setAllServicesChecked(e.target.value);
		services.forEach((service: any) => {
			service.checked = e.target.value;
		});
		setServices([...services]);
		setDataChanged(true);
	}

	return (
		<Dialog
			onClose={cancel}
			title={(permissions === "Update" ? translations.general_edit : translations.general_view) + " " + (record && record.name ? record.name : "")}
			width="70%"
		>
			<div className="tab-bar">
				<ul>
					<li className={selectedTab === 0 ? "active" : ""} onClick={() => { setSelectedTab(0); }}>{translations.general_tab_details}</li>
					<li className={selectedTab === 4 ? "active" : ""} onClick={() => { setSelectedTab(4); }}>{translations.welcomeletters_content}</li>
					<li className={selectedTab === 1 ? "active" : ""} onClick={() => { setSelectedTab(1); }}>{translations.general_tab_industries}</li>
					<li className={selectedTab === 2 ? "active" : ""} onClick={() => { setSelectedTab(2); }}>{translations.general_tab_services}</li>
				</ul>
			</div>
			<div className="tab-bar-container">
				<div className={selectedTab === 0 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<label className="k-form-field">
							<span>{translations.welcomeletters_name}</span>
							<Input
								name="name"
								style={{ width: "100%" }}
								placeholder={translations.general_placeholder_prefix + " " + translations.welcomeletters_name + "..."}
								value={record && record.name ? record.name : ""}
								onChange={onChange}
								required={true}
								validationMessage={translations.general_validationmessage + " " + translations.welcomeletters_name}
								readOnly={permissions === "Read"}
							/>
						</label>
						<div className="row">
							<div className="col-md-4">
								<label className="k-form-field">
									<span>{translations.welcomeletters_businessunit}</span>
									<ComboBox
										data={businessunits}
										name="businessUnitID"
										style={{ width: "100%" }}
										dataItemKey="id"
										textField="name"
										defaultValue={record && record.businessUnit ? record.businessUnit : ""}
										onChange={onChange}
										required={true}
										disabled={permissions === "Read"}
										suggest={true}
									/>
								</label>
							</div>
							<div className="col-md-4">
								<label className="k-form-field">
									<span>{translations.welcomeletters_language}</span>
									<ComboBox
										data={languages}
										name="languageID"
										style={{ width: "100%" }}
										dataItemKey="id"
										textField="name"
										onChange={onChange}
										defaultValue={record && record.language ? record.language : ""}
										required={true}
										disabled={permissions === "Read"}
										suggest={true}
									/>
								</label>
							</div>
							<div className="col-md-4">
								<label className="k-form-field">
									<span>{translations.welcomeletters_type}</span>
									<ComboBox
										data={[
											{ key: "New", value: translations.welcomeletters_type_new },
											{ key: "Renew", value: translations.welcomeletters_type_renew }
										]}
										name="type"
										dataItemKey="key"
										textField="value"
										style={{ width: "100%" }}
										onChange={onChange}
										defaultValue={{ key: record.type, value: record.type == translations.welcomeletters_type_new ? translations.welcomeletters_type_new : translations.welcomeletters_type_renew }}
										required={true}
										disabled={permissions === "Read"}
									/>
								</label>
							</div>
						</div>
						<label className="k-form-field">
							<span>{translations.general_active}</span>
							{/*
					// @ts-ignore*/}
							<Switch
								name="active"
								checked={
									record && record.active ? record.active : false
								}
								onChange={onChange}
								disabled={permissions === "Read"}
								onLabel={translations.general_switch_on}
								offLabel={translations.general_switch_off}
							/>
						</label>
					</form>
				</div>
				<div className={selectedTab === 4 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row">
							<div className="col-md-12">
								<div className={record.content == "" ? "isrequired" : ""}><FroalaEditorComponent config={editorConfig} model={record.content} onModelChange={changeContent}></FroalaEditorComponent></div>
							</div>
						</div>
					</form>
				</div>
				<div className={selectedTab === 1 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allIndustriesChecked} onChange={selectAllIndustries} disabled={permissions === "Read"} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{industries.map((industry: any) => (
							<div className="row spaced-row switch-row" key={industry.id}>
								<div className="col-md-11">
									<span className={industry.checked ? "checked-row" : ""}>{industry.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={industry} checked={industry.checked} onChange={changeIndustry} disabled={permissions === "Read"} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
				<div className={selectedTab === 2 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
									// @ts-ignore*/}
								<Switch checked={allServicesChecked} onChange={selectAllServices} disabled={permissions === "Read"} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{services.length == 0 && <div style={{ color: "red" }}>{translations.welcomeletters_selectbusinessunitfirst}</div>}
						{services.map((service: any) => (
							<div className="row spaced-row switch-row" key={service.id}>
								<div className="col-md-11">
									<span className={service.checked ? "checked-row" : ""}>{service.name}</span>
								</div>
								<div className="col-md-1">
									{/*
									// @ts-ignore*/}
									<Switch model={service} checked={service.checked} onChange={changeService} disabled={permissions === "Read"} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
			</div>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				{permissions === "Update" ? <button className="k-button k-primary" onClick={() => preventDoubleClick(save)}>
					{translations.general_save}
				</button> : null}
			</DialogActionsBar>
		</Dialog>
	);
}

Edit.defaultProps = {
	dataItem: null,
	save: (record: any) => { },
	cancel: () => { }
};

export default Edit;