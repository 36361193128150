import { newGuid } from './tools';
import { file } from '@babel/types';
import { getFileExtension } from './tools';

export interface IImageViewerConfig {
	image: string;
	extension: string;
	name: string
}

let imageToShow : IImageViewerConfig | undefined;
let eventHandlers = <Function[]>[];

function view(image: string | File, name?: string, type?: string) {

	if (typeof image === "string") {
		imageToShow = {
			image: image,
			extension: getFileExtension(name || ""),
			name: name || ""
		};

		//Trigger event handlers
		triggerEventHandlers();
	} else {
		const reader = new FileReader();

		reader.onloadend = (ev: any) => {
			if (ev.target && ev.target.result) {
				imageToShow = {
					image: ev.target.result,
					extension: getFileExtension(name || ""),
					name: image.name
				};
				//Trigger event handlers
				triggerEventHandlers();
			}
		};

		if (image) {
			reader.readAsDataURL(image);
		}
	}

}

function close() {
	imageToShow = undefined;
	//Trigger event handlers
	triggerEventHandlers();
}

function setEventHandler(fn: Function) {
	eventHandlers = <Function[]>[...eventHandlers, fn];
}

function triggerEventHandlers() {
	eventHandlers.forEach((handler) => handler());
}

export { imageToShow };
export { view };
export { close };
export { setEventHandler as onImageChanged };