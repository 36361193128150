import axios from 'axios';
import { getToken } from '../../services/authentication';

const baseURL = "/api/settings/sections/";

interface Record {
	id: string,
	name: string,
	icon: string,
	version: string,
	description: string,
	content: string,
	ownerID: string,
	active: boolean,
	landscape: boolean,
	hideTitle: boolean,
	hideHeaderAndFooter: boolean
}

interface Query {
	languageID: string,
	businessUnitID: string,
	landscape: boolean,
	industryIDs: Array<string>,
	serviceIDs: Array<string>
}

const repository = {
	getAll: async (conf: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "getall", conf, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	get: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "get/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	getActive: async (query: Query) => {
		try {
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "getactive", query, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	getActiveForTemplates: async (query: Query) => {
		try {
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "getactivefortemplates", query, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	save: async (record: Record) => {
		try {
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "save", record, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}

	}
};

export default repository;