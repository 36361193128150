import axios from 'axios';
import { getToken } from '../../services/authentication';
import { useState, useEffect } from 'react';
import translations from '../../translations';
import { getMyProfile } from '../../services/authentication';
import _ from 'lodash';
import { Guid } from 'guid-typescript';

const baseURL = "/api/proposals/";

interface Record {
	id: string,
	title: string
}

const repository = {
	getForOwner: async (conf: any) => {
		try {
			var accessToken = await getToken();
			let gridConf = _.cloneDeep(conf);
			if (gridConf.filter && gridConf.filter.filters && gridConf.filter.filters.length > 0) {
				for (let i = gridConf.filter.filters.length - 1; i >= 0; i--) {
					if (gridConf.filter.filters[i].field == "creationDate" && gridConf.filter.filters[i].operator == "eq") {
						var selectedDate = new Date(gridConf.filter.filters[i].value);
						var untilDate = new Date(gridConf.filter.filters[i].value);
						untilDate.setDate(selectedDate.getDate() + 1);
						gridConf.filter.filters.splice(i, 1);
						gridConf.filter.filters.push({
							field: "creationDate",
							operator: "gte",
							value: selectedDate
						});
						gridConf.filter.filters.push({
							field: "creationDate",
							operator: "lte",
							value: untilDate
						});
					}
				}
			}
			var result = await axios.post(baseURL + "getforowner/" + getMyProfile().userID, gridConf, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return { total: result.data.total, data: result.data.data.map((record: any) => { return {...record, creationDate: new Date(record.creationDate)} }) };
		} catch (error) {
			throw error;
		}
	},
	getForTasks: async (conf: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "getfortasks/" + getMyProfile().userID, conf, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	get: async (proposalID: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "get/" + proposalID, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	send: async (proposalID: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "render/" + proposalID, {}, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	preview: async (proposalID: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "render/" + proposalID + "?adHoc=true", {}, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	previewPDF: async (proposalID: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "renderpdf/" + proposalID + "?adHoc=true", {}, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	getPreviewCookie: async (proposalID: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "preview/" + proposalID, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	save: async (record: Record) => {
		try {
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "save", record, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	updateStatus: async (proposalID: string, status: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "updatestatus/" + proposalID, "\"" + status + "\"", {
				headers: {
					"Authorization": "Bearer " + accessToken,
					"Content-Type": 'application/json'
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	getProposalSectionsStatus: async (proposalID: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "proposalsections/status/" + proposalID, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	saveContact: async (record: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "proposalcontacts/save", record, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	deleteContact: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.delete(baseURL + "proposalcontacts/delete/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	saveTeamMember: async (record: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "proposalpursuitteam/save", record, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	deleteTeamMember: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.delete(baseURL + "proposalpursuitteam/delete/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	getSection: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "proposalsections/get/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	saveSection: async (record: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "proposalsections/save", record, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	deleteSection: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.delete(baseURL + "proposalsections/delete/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	readyForApprovalSection: async (id: string, active: boolean) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "proposalsections/readyforapproval/" + id + "?active=" + (active ? "true" : "false"), {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	approveSection: async (id: string, active: boolean) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "proposalsections/approve/" + id + "?active=" + (active ? "true" : "false"), {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	lockSection: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "proposalsections/lock/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	unlockSection: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "proposalsections/unlock/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	changeSectionSequences: async (sequenceChanges: Array<any>) => {
		try {
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "proposalsections/changesequences/", sequenceChanges, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	getResume: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "proposalsectionresumes/get/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	saveResume: async (record: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "proposalsectionresumes/save", record, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	deleteResume: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.delete(baseURL + "proposalsectionresumes/delete/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	notifyPursuitTeam: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "notifypursuitteam/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	notifyDeliveryTeam: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "notifydeliveryteam/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	saveAttachment: async (record: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "attachments/save", record, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
};

export default repository;
