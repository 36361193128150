import React, { useState, useEffect } from 'react';
import { notify } from '../../services/notification';
import { view } from "../../services/imageviewer";
import { getFileExtension } from '../../services/tools';

const FilePreview: React.FC<any> = (props) => {

	const [loading, setLoading] = useState(true);
	const [files, setFiles] = useState<any[]>([]);

	useEffect(() => {
		if (props && props.files) {
			props.files.forEach(function (file: any) {
				if (file.content && file.size && file.size > 0) {
					setFiles([...files, {
						...file,
						fromAPI: true
					}]);
				} else {
					const reader = new FileReader();

					reader.onloadend = (ev: any) => {
						if (ev.target && ev.target.result) {
							file.preview = ev.target.result;
							setFiles([...files, file]);
						}
					};

					if (file && file.getRawFile) {
						const fileBlob: any = file.getRawFile();
						reader.readAsDataURL(fileBlob);
					}
				}
			});
			setLoading(false);
		}
	}, [])

	const openPreview = (e: any, file: any) => { 
		e.preventDefault();
		if (file.fromAPI) {
			view(file.content, file.name, file.type);
		} else {
			view(file.getRawFile());
		}
	}

	const renderFile = (file: any) => {
		if (file.fromAPI) {
			return (
				<li key={file.name} onClick={(e) => { e.preventDefault() }}>
					<span className={"file-preview-name"}>{file.name}</span>
					<br />
					{file.content ? (
						<div className={"file-preview-thumbnail"} onClick={(e) => { openPreview(e, file); }}>
							{/\.(gif|jpg|jpeg|tiff|png)$/i.test(getFileExtension(file.name)) ? (
								<img src={file.content} alt={"image preview"} style={{ width: 200, margin: 10, cursor: "pointer" }} />
							) : (
									<embed src={file.content} style={{ width: 200, margin: 10 }} />
								)}
						</div>
					) : (
							undefined
						)}
				</li>				
			);
		} else {
			return (
				<li key={file.name}>
					<span className={"file-preview-name"}>{file.name}</span>
					<br />
					{file.preview ? (
						<div className={"file-preview-thumbnail"} onClick={(e) => { openPreview(e, file); }}>
							{/\.(gif|jpg|jpeg|tiff|png)$/i.test(getFileExtension(file.name)) ? (
								<img src={file.preview} alt={"image preview"} style={{ width: 200, margin: 10, cursor: "pointer" }} />
							) : (
									<embed src={file.preview} style={{ width: 200, margin: 10 }} />
								)}
						</div>
					) : (
							undefined
						)}
				</li>				
			);
		}
	};

	return (
		<div className={"file-preview"} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
			{loading &&
				<div className="k-loading-mask">
					<span className="k-loading-text">Loading</span>
					<div className="k-loading-image"></div>
					<div className="k-loading-color"></div>
				</div>
			}
			<ul>
				{files.map((file: any, i: number) => renderFile(file))}
			</ul>
		</div>
	);
};

export default FilePreview;