///<reference path= "../../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect } from 'react';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { useLanguages } from '../../../../repositories/settings/countrylanguages';
import { useBusinessUnits } from '../../../../repositories/settings/businessunits';
import repoIndustries from '../../../../repositories/settings/industries';
import repoServices from '../../../../repositories/settings/services';
import repoTemplates from '../../../../repositories/settings/templates';
import _ from 'lodash';
import { show, close, confirmChanged, showLoader, hideLoader } from '../../../../services/renderer';
import translations from '../../../../translations';
import NewSection from './sectionnew';
import { alert } from '../../../../services/notification';
import { preventDoubleClick } from '../../../../services/tools';

interface EditProps {
	dataItem: any,
	save: (record: any) => void,
	cancel: () => void
}

interface Query {
	languageID: string,
	businessUnitID: string,
	landscape: boolean,
	industryIDs: Array<string>,
	serviceIDs: Array<string>
}

const Edit: React.FC<EditProps> = (props) => {

	const [record, setRecord] = useState(props.dataItem || null);
	const businessunits = useBusinessUnits();
	const languages = useLanguages();
	const [industries, setIndustries] = useState([]);
	const [allIndustriesChecked, setAllIndustriesChecked] = useState(false);
	const [services, setServices] = useState([]);
	const [allServicesChecked, setAllServicesChecked] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [dataChanged, setDataChanged] = useState(false);
	const [sectionTypes, setSectionTypes] = useState(new Array());
	const [draggedSection, setDraggedSection] = useState();
	const [draggedOverSection, setDraggedOverSection]: [any, Function] = useState();

	useEffect(() => {
		repoTemplates.get(props.dataItem.id).then((resultRecord) => {
			setRecord(resultRecord);
			setSectionTypes(resultRecord.sections.map((sectionType: any, index: number) => ({
				id: sectionType.id,
				key: sectionType.id,
				type: sectionType.type,
				templateID: props.dataItem.id,
				sectionID: sectionType.sectionID,
				name: sectionType.name,
				description: sectionType.description,
				icon: sectionType.icon,
				selected: false,
				iconSelectorVisible: false,
				sequence: index,
				required: sectionType.required
			})));
			repoIndustries.getActive().then((result) => {
				setIndustries(result.map((industry: any) => {
					return {
						id: industry.id,
						name: industry.name,
						checked: _.some(resultRecord.industries, { industryID: industry.id })
					}
				}));
			});
			loadServices(resultRecord, resultRecord.businessUnitID);
			hideLoader();
		});
	}, []);

	function loadServices(activeRecord: any, businessUnitID: any) {
		showLoader();
		repoServices.subServices.getActive(businessUnitID).then((result) => {
			setServices(result.map((service: any) => {
				return {
					id: service.id,
					name: service.mainOffer.mainOffering + " > " + service.subOffering,
					checked: _.some(activeRecord.services, { serviceID: service.id })
				}
			}));
			hideLoader();
		});
	}

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			case "businessUnitID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				loadServices(newRecord, event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000001");
				break;
			case "languageID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				break;
			default:
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value;
		}
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		record.industries.length = 0;
		industries.forEach((industry: any) => {
			if (industry.checked) record.industries.push(
				{
					templateID: record.id,
					industryID: industry.id
				}
			);
		});
		record.services.length = 0;
		services.forEach((service: any) => {
			if (service.checked) record.services.push(
				{
					templateID: record.id,
					serviceID: service.id
				}
			);
		});
		record.sections = sectionTypes.map((section: any, index: number) => {
			return { ...section, sequence: index };
		});
		props.save(record);
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	function cancelSection() {
		close("sectionedit");
		close("sectionnew");
	}

	function changeIndustry(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllIndustriesChecked(false);
		setIndustries([...industries]);
		setDataChanged(true);
	}

	function selectAllIndustries(e: any) {
		setAllIndustriesChecked(e.target.value);
		industries.forEach((industry: any) => {
			industry.checked = e.target.value;
		});
		setIndustries([...industries]);
		setDataChanged(true);
	}

	function changeService(e: any) {
		e.target.props.model.checked = e.target.value;
		setAllServicesChecked(false);
		setServices([...services]);
		setDataChanged(true);
	}

	function selectAllServices(e: any) {
		setAllServicesChecked(e.target.value);
		services.forEach((service: any) => {
			service.checked = e.target.value;
		});
		setServices([...services]);
		setDataChanged(true);
	}

	function saveSections(newSectionTypes: any) {
		setSectionTypes(newSectionTypes.map((sectionType: any, index: number) => ({
			id: "00000000-0000-0000-0000-000000000000",
			key: sectionType.key,
			type: sectionType.type,
			templateID: record.id,
			sectionID: sectionType.sectionID,
			name: sectionType.name,
			description: sectionType.description,
			icon: sectionType.icon,
			selected: false,
			iconSelectorVisible: false,
			sequence: index
		})).concat(sectionTypes));
		close("sectionnew");
	}

	function newSection() {
		var queryObject: Query = {
			languageID: record.languageID,
			businessUnitID: record.businessUnitID,
			landscape: record.landscape,
			industryIDs: [],
			serviceIDs: []
		};
		industries.forEach((industry: any) => {
			if (industry.checked) queryObject.industryIDs.push(industry.id);
		});
		services.forEach((service: any) => {
			if (service.checked) queryObject.serviceIDs.push(service.id);
		});
		if (!queryObject.languageID || queryObject.languageID == "00000000-0000-0000-0000-000000000000") {
			alert(translations.templatesections_errormessage_chooselanguage);
		} else if (!queryObject.businessUnitID || queryObject.businessUnitID == "00000000-0000-0000-0000-000000000000") {
			alert(translations.templatesections_errormessage_choosebusinessunit);
		} else if (queryObject.industryIDs.length == 0) {
			alert(translations.templatesections_errormessage_selectindustries);
		} else if (queryObject.serviceIDs.length == 0) {
			alert(translations.templatesecionts_errormessage_selectservices);
		} else {
			show(NewSection, "sectionnew", {
				save: saveSections,
				cancel: cancelSection,
				sectionQuery: queryObject,
				sectionTypes: sectionTypes
			});
		}
	}

	function selectIcon(sectionType: any) {
		sectionType.iconSelectorVisible = true;
		setSectionTypes([...sectionTypes]);
	}

	function changeIcon(icon: string, sectionType: any) {
		sectionType.icon = icon;
		sectionType.iconSelectorVisible = false;
		setSectionTypes([...sectionTypes]);
		setDataChanged(true);
	}


	function dragStart(e: any, section: any) {
		e.dataTransfer.setData('text/plain', section.key);
		setDraggedSection(section);
	}

	function dragEnd(e: any, section: any) {
		e.preventDefault();
		if (section) {

			let newSectionTypes: any[] = [...sectionTypes];

			newSectionTypes.splice(newSectionTypes.indexOf(section), 0, newSectionTypes.splice(newSectionTypes.indexOf(draggedSection), 1)[0]);

			// @ts-ignore
			setSectionTypes(newSectionTypes);
			// @ts-ignore
			setDraggedSection(null);
			// @ts-ignore
			setDraggedOverSection(null);
			setDataChanged(true);
		}
	}

	function dragOver(e: any, section: any) {
		setDraggedOverSection(section);
		e.stopPropagation();
		e.preventDefault();
	}

	function removeSection(index: number) {
		sectionTypes.splice(index, 1);
		setSectionTypes([...sectionTypes]);
		setDataChanged(true);
	}

	function pinSection(index: number) {
		sectionTypes[index].required = !sectionTypes[index].required;
		setSectionTypes([...sectionTypes]);
		setDataChanged(true);
	}

	return (
		<Dialog
			onClose={cancel}
			title={translations.general_edit + " " + translations.templates_windowtitlesingle}
			width="70%"
		>
			<div className="tab-bar">
				<ul>
					<li className={selectedTab === 0 ? "active" : ""} onClick={() => { setSelectedTab(0); }}>{translations.general_tab_details}</li>
					<li className={selectedTab === 1 ? "active" : ""} onClick={() => { setSelectedTab(1); }}>{translations.general_tab_industries}</li>
					<li className={selectedTab === 2 ? "active" : ""} onClick={() => { setSelectedTab(2); }}>{translations.general_tab_services}</li>
					<li className={selectedTab === 3 ? "active" : ""} onClick={() => { setSelectedTab(3); }}>{translations.general_tab_sections}</li>
				</ul>
			</div>
			<div className="tab-bar-container">
				<div className={selectedTab === 0 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<label className="k-form-field">
							<span>{translations.templates_title}</span>
							<Input
								name="title"
								style={{ width: "100%" }}
								placeholder={translations.general_placeholder_prefix + " " + translations.templates_title + "..."}
								value={record && record.title ? record.title : ""}
								onChange={onChange}
								required={true}
								validationMessage={translations.general_validationmessage + " " + translations.templates_title}
							/>
						</label>
						<label className="k-form-field">
							<span>{translations.templates_shortdescription}</span>
							<Input
								name="shortDescription"
								style={{ width: "100%" }}
								placeholder={translations.general_placeholder_prefix + " " + translations.templates_shortdescription + "..."}
								value={record && record.shortDescription ? record.shortDescription : ""}
								onChange={onChange}
								required={true}
								validationMessage={translations.general_validationmessage + " " + translations.templates_shortdescription}
							/>
						</label>
						<label className="k-form-field">
							<span>{translations.templates_language}</span>
							<ComboBox
								data={languages}
								name="languageID"
								style={{ width: "100%" }}
								dataItemKey="id"
								textField="name"
								defaultValue={record && record.language ? record.language : ""}
								onChange={onChange}
								required={true}
								suggest={true}
							/>
						</label>
						<label className="k-form-field">
							<span>{translations.templates_businessunit}</span>
							<ComboBox
								data={businessunits}
								name="businessUnitID"
								style={{ width: "100%" }}
								dataItemKey="id"
								textField="name"
								defaultValue={record && record.businessUnit ? record.businessUnit : ""}
								onChange={onChange}
								required={true}
								suggest={true}
							/>
						</label>
						<div className="row">
							<div className="col-md-3">
								<label className="k-form-field">
									<span>{translations.general_active}</span>
									{/*
									// @ts-ignore*/}
									<Switch name="active"
										checked={
											record && record.active ? record.active : false
										}
										onChange={onChange}
										onLabel={translations.general_switch_on}
										offLabel={translations.general_switch_off}
									/>
								</label>
							</div>
							<div className="col-md-3">
								<label className="k-form-field">
									<span>{translations.proposalwizard_hidewelcomeletter}</span>
									{/*
									// @ts-ignore*/}
									<Switch name="hideWelcomeLetter"
										checked={
											record && record.hideWelcomeLetter ? record.hideWelcomeLetter : false
										}
										onChange={onChange}
										onLabel={translations.general_switch_on}
										offLabel={translations.general_switch_off}
									/>
								</label>
							</div>
							<div className="col-md-3">
								<label className="k-form-field">
									<span>{translations.general_orientation}</span>
									{/*
									// @ts-ignore*/}
									<Switch name="landscape" checked={record && record.landscape ? record.landscape : false} onChange={onChange} onLabel="Landscape" offLabel="Portrait" className="page-orientation-switch"></Switch>
								</label>
							</div>
						</div>
					</form>
				</div>
				<div className={selectedTab === 1 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
                                    // @ts-ignore*/}
								<Switch checked={allIndustriesChecked} onChange={selectAllIndustries} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{industries.map((industry: any) => (
							<div className="row spaced-row switch-row" key={industry.id}>
								<div className="col-md-11">
									<span className={industry.checked ? "checked-row" : ""}>{industry.name}</span>
								</div>
								<div className="col-md-1">
									{/*
                                    // @ts-ignore*/}
									<Switch model={industry} checked={industry.checked} onChange={changeIndustry} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
				<div className={selectedTab === 2 ? "" : "hidden"}>
					<form className="k-form" onSubmit={onSubmit}>
						<div className="row spaced2-row switch-row">
							<div className="col-md-11">
								{translations.general_switcharray_selectall}
							</div>
							<div className="col-md-1">
								{/*
                                    // @ts-ignore*/}
								<Switch checked={allServicesChecked} onChange={selectAllServices} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
							</div>
						</div>
						{services.length == 0 && <div style={{ color: "red" }}>{translations.welcomeletters_selectbusinessunitfirst}</div>}
						{services.map((service: any) => (
							<div className="row spaced-row switch-row" key={service.id}>
								<div className="col-md-11">
									<span className={service.checked ? "checked-row" : ""}>{service.name}</span>
								</div>
								<div className="col-md-1">
									{/*
                                    // @ts-ignore*/}
									<Switch model={service} checked={service.checked} onChange={changeService} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off} />
								</div>
							</div>
						))}
					</form>
				</div>
				<div className={selectedTab === 3 ? "" : "hidden"}>
					<div>&nbsp;</div>
					<button title={translations.general_addnew} className="k-button k-primary" onClick={newSection} >
						{translations.general_addnew}
					</button>
					<div>&nbsp;</div>
					<div className="sectionListing">
						{
							sectionTypes.map((sectionType: any, index: number) => (<div key={sectionType.key} className={"sectionListingEntry " + (sectionType.type == "Static" ? "static " : "dynamic ") + (draggedOverSection && sectionType.key === draggedOverSection.key ? " dragged-over" : "")} draggable={true} onDragStart={(e) => { dragStart(e, sectionType); }} onDrop={(e) => { dragEnd(e, sectionType); }} onDragOver={(e) => { dragOver(e, sectionType); }}>
								<span className={sectionType.icon}></span>
								<div className="sectionListingEntryName">{sectionType.name}</div>
								<div className="sectionListingEntryDescription">{sectionType.description}</div>
								<div className={"sectionListingEntryPinned" + (sectionType.required ? " pinned" : "")} onClick={() => { pinSection(index); }}><span className="deloitte-icon icon-pin_special"></span></div>
								<div className="sectionListingEntryDelete" onClick={() => { removeSection(index); }}><span className="deloitte-icon icon-bin_special"></span></div>
							</div>
							))
						}
					</div>
				</div>
			</div>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				<button className="k-button k-primary" onClick={() => preventDoubleClick(save)}>
					{translations.general_save}
				</button>
			</DialogActionsBar>
		</Dialog>
	);
}

Edit.defaultProps = {
	save: (record: any) => { },
	cancel: () => { }
};

export default Edit;