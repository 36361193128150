import axios from 'axios';
import { getToken } from '../../services/authentication';
import translations from '../../translations';

const baseURL = "/api/settings/industries/";

interface Record {
	id: string,
	name: string,
	active: boolean
}

const repository = {
	getAll: async (conf: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "getall", conf, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	getActive: async () => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "getactive", {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	save: async (record: Record) => {
		try {
			if (!record.id || !record.name) throw translations.general_fillinallrequiredfields;
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "save", record, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}

	}
};

export default repository;