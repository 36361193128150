import React, { useState, useEffect } from 'react';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import New from './subnew';
import Edit from './subedit';
import { show, close, showLoader, hideLoader } from '../../../../services/renderer';
import repoServices from '../../../../repositories/settings/services';
import cellButton from '../../../global/cellbutton';
import { notify, alert } from '../../../../services/notification';
import GridPanel from '../../../global/gridpanel';
import { triggerListeners } from '../../../../services/listeners';
import { cloneObject } from '../../../../services/tools';
import { isAuthorized } from '../../../../services/authorization';
import translations from '../../../../translations';
import DeloitteButton from '../../../global/deloittebutton';

interface EditProps {
	dataItem: any,
	save: (record: any) => void,
	cancel: () => void
}

const List: React.FC<EditProps> = (props) => {

	function edit(dataItem: any) {
		show(Edit, "subserviceedit", { dataItem: cloneObject(dataItem), save: save, cancel: cancel });
	}

	function cancel() {
		close("subserviceedit");
		close("subservicenew");
	}

	function save(record: any) {
		showLoader();
		repoServices.subServices.save(record).then((result) => {
			notify(translations.general_savedsuccess);
			triggerListeners("reloadSubServices");
			cancel();
			hideLoader();
		}).catch((error) => {
			hideLoader();
			alert(error);
		});
	}

	return (
		<Dialog onClose={props.cancel} title={"Edit Sub Services for " + props.dataItem.mainOffering} width="80%" height="80%">
			<GridPanel reloadKey="reloadSubServices" parentID={props.dataItem.id} loadData={repoServices.subServices.getAll} filter={{
				logic: "and",
				filters: [
					{
						field: "active",
						operator: "eq",
						value: true
					}
				]
			}}>
				{isAuthorized("Offers", "Create") ? <GridToolbar >
					<div>
						<DeloitteButton title={translations.general_addnew} onClick={() => { show(New, "subservicenew", { parentID: props.dataItem.id, save: save, cancel: cancel }); }} >
							{translations.general_addnew}
						</DeloitteButton>
					</div>
				</GridToolbar> : null}
				<GridColumn field="subOffering" title={translations.subservices_name} editable={false} />
				<GridColumn field="active" title={translations.general_active} width="125px" editor="boolean" filter={'boolean'} />
				<GridColumn filterable={false} width="120px" editable={false} title={translations.general_edit} cell={cellButton(edit, translations.general_edit)}
				/>
			</GridPanel>
			<DialogActionsBar>
				<button className="k-button k-primary" onClick={props.cancel}>Close</button>
			</DialogActionsBar>
		</Dialog>
	);
}

export default List;