import React, { useState, useEffect } from 'react';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import FilePreview from '../../../global/filepreview';
import repoLegalDocuments from '../../../../repositories/settings/legaldocuments';
import { useLanguages } from '../../../../repositories/settings/countrylanguages';
import { useBusinessUnits } from '../../../../repositories/settings/businessunits';
import { Upload } from '@progress/kendo-react-upload';
import { alert } from '../../../../services/notification';
import { showLoader, hideLoader, confirmChanged } from '../../../../services/renderer';
import { isAuthorized } from '../../../../services/authorization';
import translations from '../../../../translations';
import { preventDoubleClick } from '../../../../services/tools';

interface EditProps {
	dataItem: any,
	save: (record: any) => void,
	cancel: () => void
}

const Edit: React.FC<EditProps> = (props) => {

	const [record, setRecord] = useState(props.dataItem || null);
	const businessunits = useBusinessUnits();
	const languages = useLanguages();
	const [file, setFile] = useState();
	const [fileChanged, setFileChanged] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [permissions, setPermissions] = useState("none");

	useEffect(() => {
		showLoader();
		if (record.document && record.document.size && record.document.size > 0) {
			setFile({
				content: "/api/content/files/get/" + record.document.internalFilename + "/" + record.document.filename,
				name: record.document.filename,
				size: record.document.size,
				type: record.document.type
			});
			hideLoader();
		} else {
			hideLoader();
		}

		if (isAuthorized("LegalDocuments", "Update")) {
			setPermissions("Update");
		} else if ((isAuthorized("LegalDocuments", "Read"))) {
			setPermissions("Read");
		}

	}, []);

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			case "businessUnitID":
			case "languageID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				break;
			default:
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value;
		}
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		if (fileChanged) {
			if (file) {
			record.file = file;
			// @ts-ignore
			record.document_fileAction = "change";
			props.save(record);
			} else {
				alert(translations.general_fileupload_pdfrequired);
			}
		} else {
			record.document_fileAction = "keep";
			props.save(record);
		}
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	// @ts-ignore
	function addFile(event: UploadOnAddEvent) {
		if (event && event.affectedFiles && event.affectedFiles.length > 0 && event.affectedFiles[0]) {
			if (event.affectedFiles[0].size && event.affectedFiles[0].size > 10000000) {
				setFile(null);
				alert(translations.general_fileupload_above10mb);
				return;
			}
			if (event.affectedFiles[0].extension.toLowerCase() != ".pdf") {
				setFile(null);
				alert(translations.general_fileupload_nopdf);
				return;
			}
			setFileChanged(true);
			setFile(event.affectedFiles[0]);
			setDataChanged(true);
		} else {
			setFile(null);
			alert(translations.general_fileupload_novalidfile);
		}
	}

	return (
		<Dialog
			onClose={cancel}
			title={(permissions === "Update" ? translations.general_edit : translations.general_view) + " " + (record && record.title ? record.title : "")}
			width={550}
		>
			<form className="k-form" onSubmit={onSubmit}>
				<label className="k-form-field">
					<span>{translations.legaldocuments_title}</span>
					<Input
						name="title"
						placeholder={translations.general_placeholder_prefix + " " + translations.legaldocuments_title + "..."}
						value={record && record.title ? record.title : ""}
						onChange={onChange}
						required={true}
						validationMessage={translations.general_validationmessage + " " + translations.legaldocuments_title}
						readOnly={permissions === "Read"}
					/>
				</label>
				<div className="row">
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.legaldocuments_businessunit}</span>
							<ComboBox
								data={businessunits}
								name="businessUnitID"
								dataItemKey="id"
								textField="name"
								defaultValue={record && record.businessUnit ? record.businessUnit : ""}
								style={{ width: "100%" }}
								onChange={onChange}
								required={true}
								disabled={permissions === "Read"}
								suggest={true}
							/>
						</label>
					</div>
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.legaldocuments_language}</span>
							<ComboBox
								data={languages}
								name="languageID"
								dataItemKey="id"
								textField="name"
								defaultValue={record && record.language ? record.language : ""}
								style={{ width: "100%" }}
								onChange={onChange}
								required={true}
								disabled={permissions === "Read"}
								suggest={true}
							/>
						</label>
					</div>
				</div>
				<label className="k-form-field">
					<span>{translations.legaldocuments_document}</span>
					<div className={!file ? "isrequired" : ""}>
						<Upload
							autoUpload={false}
							multiple={false}
							accept=".pdf"
							files={file ? [file] : []}
							onAdd={addFile}
							listItemUI={FilePreview}
							onRemove={() => { setFile(undefined); }}
							className="hidden-upload-button-in-preview"
							disabled={permissions === "Read"}
						/>
					</div>
				</label>
				<label className="k-form-field">
					<span>{translations.general_active}</span>
					{/*
					// @ts-ignore*/}
					<Switch
						name="active"
						checked={
							record && record.active ? record.active : false
						}
						onChange={onChange}
						disabled={permissions === "Read"}
						onLabel={translations.general_switch_on}
						offLabel={translations.general_switch_off}
					/>
				</label>
			</form>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				{permissions === "Update" ? <button className="k-button k-primary" onClick={() => preventDoubleClick(save)}>
					{translations.general_save}
				</button> : null}
			</DialogActionsBar>
		</Dialog>
	);
}

Edit.defaultProps = {
	dataItem: null,
	save: (record: any) => { },
	cancel: () => { }
};

export default Edit;