import React, { useState, useEffect } from 'react';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { confirmChanged } from '../../../../services/renderer';
import translations from '../../../../translations';
import { preventDoubleClick } from '../../../../services/tools';

interface NewProps {
	save: (record: any) => void,
	cancel: () => void
}

const New: React.FC<NewProps> = (props) => {

	const [record, setRecord] = useState({
		id: "00000000-0000-0000-0000-000000000000",
		name: "",
		companyName: "",
		companyAddress: "",
		phoneNumber: "",
		vatNumber: "",
		website: "",
		active: false
	});
	const [dataChanged, setDataChanged] = useState(false);

	function onChange(event: any) {
		let newRecord = { ...record };
		// @ts-ignore
		newRecord[event.target.props.name] = event.target.value;
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onChangeTextArea(event: any) {
		let newRecord = { ...record };
		newRecord.companyAddress = event.target.value;
		setRecord(newRecord);
		setDataChanged(true);

	}


	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		props.save(record);
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	return (
		<Dialog onClose={cancel} title={translations.general_new + " " + translations.businessunits_windowtitlesingle} width={400}>
			<form className="k-form" onSubmit={onSubmit}>
				<label className="k-form-field">
					<span>{translations.businessunits_name}</span>
					<Input name="name" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + translations.businessunits_name + "..."} value={(record && record.name ? record.name : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " " + translations.businessunits_name} />
				</label>
				<label className="k-form-field">
					<span>Company name</span>
					<Input name="companyName" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " company name..."} value={(record && record.companyName ? record.companyName : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " company name"} />
				</label>
				<label className="k-form-field">
					<span>Company address</span>
					<textarea name="companyAddress" style={{ width: "100%" }} value={(record && record.companyAddress ? record.companyAddress : "")} onChange={onChangeTextArea} className="k-textarea" required={true}></textarea>
				</label>
				<label className="k-form-field">
					<span>Phone number</span>
					<Input name="phoneNumber" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " Phone..."} value={(record && record.phoneNumber ? record.phoneNumber : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " Phone"} />
				</label>
				<label className="k-form-field">
					<span>VAT number</span>
					<Input name="vatNumber" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " VAT..."} value={(record && record.vatNumber ? record.vatNumber : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " VAT"} />
				</label>
				<label className="k-form-field">
					<span>Website</span>
					<Input name="website" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " Website..."} value={(record && record.website ? record.website : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " Website"} />
				</label>
				<label className="k-form-field">
					<span>{translations.general_active}</span>
					{/*
					// @ts-ignore*/ }
					<Switch name="active" checked={(record && record.active ? record.active : false)} onChange={onChange}
						onLabel={translations.general_switch_on}
						offLabel={translations.general_switch_off} />
				</label>
			</form>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>{translations.general_cancel}</button>
				<button className="k-button k-primary" onClick={() => preventDoubleClick(save)}>{translations.general_save}</button>
			</DialogActionsBar>
		</Dialog>
	);
}

New.defaultProps = {
	save: (record: any) => { },
	cancel: () => { }
};

export default New;