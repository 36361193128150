import React, { useState, useEffect } from 'react';
import GridPanel from '../../../global/gridpanel';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import cellButton from '../../../global/cellbutton';
import New from './new';
import Edit from './edit';
import { show, close, showLoader, hideLoader } from '../../../../services/renderer';
import repoBusinessUnits from '../../../../repositories/settings/businessunits';
import { notify, alert } from '../../../../services/notification';
import { triggerListeners } from '../../../../services/listeners';
import { cloneObject } from '../../../../services/tools';
import { isAuthorized } from '../../../../services/authorization';
import translations from '../../../../translations';
import DeloitteButton from '../../../global/deloittebutton';

const List: React.FC = (props) => {

	function edit(dataItem: any) {
		show(Edit, "businessunitedit", { dataItem: cloneObject(dataItem), save: save, cancel: cancel });
	}

	function cancel() {
		close("businessunitedit");
		close("businessunitnew");
	}

	function save(record: any) {
		showLoader();
		repoBusinessUnits.save(record).then((result) => {
			notify(translations.general_savedsuccess);
			triggerListeners("reloadBusinessUnits");
			cancel();
			hideLoader();
		}).catch((error) => {
			hideLoader();
			alert(error);
		});
	}
	
	return (
		<div>
			<GridPanel reloadKey="reloadBusinessUnits" loadData={repoBusinessUnits.getAll} filter={{
				logic: "and",
				filters: [
					{
						field: "active",
						operator: "eq",
						value: true
					}
				]
			}} onRowDoubleClick={edit}>
				{isAuthorized("BusinessUnits", "Create") ? <GridToolbar >
					<div>
						<DeloitteButton title={translations.general_addnew} onClick={() => { show(New, "businessunitnew", { save: save, cancel: cancel }); }} >
							{translations.general_addnew}
						</DeloitteButton>
					</div>
				</GridToolbar> : null}
				<GridColumn field="name" title={translations.businessunits_name} editable={false} />
				<GridColumn field="active" title={translations.general_active} width="125px" editor="boolean" filter={'boolean'} />
				{isAuthorized("BusinessUnits", "Update") ? <GridColumn filterable={false} width="120px" editable={false} title={translations.general_edit} cell={cellButton(edit, translations.general_edit)} />
					: (!isAuthorized("BusinessUnits", "Update") && isAuthorized("BusinessUnits", "Read") ? <GridColumn filterable={false} width="120px" editable={false} title={translations.general_view} cell={cellButton(edit, translations.general_view)} /> : null)} 
			</GridPanel>
		</div>
	);
}

export default List;