///<reference path= "../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect } from 'react';
import { editorSendProposal } from '../../../services/environment';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { confirmChanged } from '../../../services/renderer';
import translations, { getCurrentLanguage } from '../../../translations';

interface EditResumeProps {
	save: (record: any) => void,
	cancel: () => void,
	dataItem?: any
}

const EditResume: React.FC<EditResumeProps> = (props) => {

	const [record, setRecord] = useState({
		emailBody: props.dataItem && props.dataItem.emailBody ? props.dataItem.emailBody : "",
		title: props.dataItem && props.dataItem.title ? props.dataItem.title : ""
	});
	const [dataChanged] = useState(false);
	const editorConfig = {
		...editorSendProposal,
		placeholderText: translations.myprofile_resume_placeholder,
		language: getCurrentLanguage(),
		height: 400,
	};

	function changeMailBody(newContent: string) {
		record.emailBody = newContent;
		setRecord(record);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		props.save(record);
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	return (
		<Dialog onClose={cancel} title={"Send proposal for " + record.title} width={"50%"}>
			<form className="k-form">
				<label className="k-form-field">
					<span>Customize your email:</span>
				</label>
				<FroalaEditorComponent config={editorConfig} model={record.emailBody} onModelChange={changeMailBody}></FroalaEditorComponent>
			</form>

			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>{translations.general_cancel}</button>
				<button className="k-button k-primary" onClick={save}>Send</button>
			</DialogActionsBar>
		</Dialog>
	);
}

export default EditResume;