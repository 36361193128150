import axios from 'axios';
import { getToken } from '../../services/authentication';

const baseURL = "/api/settings/achievements/";

interface Query {
	languageID: string,
	businessUnitID: string,
	industryID: string,
	serviceID: string
}

const repository = {
	getAll: async (conf: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "getall", conf, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	search: async (query: Query) => {
		try {
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "search", query, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	getProjectTypes: async () => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "projecttypes", {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	get: async (id: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "get/" + id, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	save: async (record: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "save", record, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
};

export default repository;