import axios from 'axios';
import { getToken } from '../../services/authentication';
import { useState, useEffect } from 'react';
import translations from '../../translations';
import { getMyProfile } from '../../services/authentication';

const baseURL = "/api/tasks/";

interface Record {
	id: string,
	title: string
}

const repository = {
	get: async (taskID: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "get/" + taskID, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	}
};

export default repository;
