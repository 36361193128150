///<reference path= "../../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect } from 'react';
//@ts-ignore
import FroalaEditor from 'froala-editor';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { showLoader, hideLoader, confirmChanged } from '../../../../services/renderer';
import repoSections from '../../../../repositories/settings/sections';
import repoCountryLanguages from '../../../../repositories/settings/countrylanguages';
import repoIndustries from '../../../../repositories/settings/industries';
import repoServices from '../../../../repositories/settings/services';
import { useBusinessUnits } from '../../../../repositories/settings/businessunits';
import repoUsers from '../../../../repositories/security/users';
import { getMyProfile } from '../../../../services/authentication';
import sectionIcons from '../../../../services/sectionicons';
import _ from 'lodash';
import { alert } from '../../../../services/notification';
import translations from '../../../../translations';
import { preventDoubleClick } from '../../../../services/tools';

interface EditProps {
	dataItem: any,
	save: (record: any) => void,
	cancel: () => void
}

const Edit: React.FC<EditProps> = (props) => {

	const [record, setRecord] = useState(props.dataItem || null);
	const [dataChanged, setDataChanged] = useState(false);
	const [permissionStructure] = useState([
		{
			group: "Proposals",
			items: [
				{
					name: "Proposals",
					key: "Proposals",
					actions: [
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: "Contribute",
							key: "Contribute",
						},
					],
				}
			]
		},
		{
			group: translations.roles_content,
			items: [
				{
					name: translations.coverimages_windowtitle,
					key: "CoverImages",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: translations.roles_checks_update,
							key: "Update",
						},
					],
				},
				{
					name: translations.credentials_windowtitle,
					key: "Credentials",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: translations.roles_checks_update,
							key: "Update",
						},
						{
							name: translations.roles_checks_countrylevel,
							key: "CountryLevel",
						},
					],
				},
				{
					name: translations.achievements_windowtitle,
					key: "Achievements",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: translations.roles_checks_update,
							key: "Update",
						},
						{
							name: translations.roles_checks_countrylevel,
							key: "CountryLevel",
						},
					],
				},
				{
					name: translations.legaldocuments_windowtitle,
					key: "LegalDocuments",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: translations.roles_checks_update,
							key: "Update",
						},
						{
							name: translations.roles_checks_countrylevel,
							key: "CountryLevel",
						},
					],
				},
				{
					name: translations.publications_windowtitle,
					key: "Publications",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: translations.roles_checks_update,
							key: "Update",
						},
					],
				},
				{
					name: translations.sections_windowtitle,
					key: "Sections",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: translations.roles_checks_update,
							key: "Update",
						},
						{
							name: translations.roles_checks_countrylevel,
							key: "CountryLevel",
						},
					],
				},
				{
					name: translations.welcomeletters_windowtitle,
					key: "WelcomeLetters",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: translations.roles_checks_update,
							key: "Update",
						},
						{
							name: translations.roles_checks_countrylevel,
							key: "CountryLevel",
						},
					],
				},
				{
					name: translations.templates_windowtitle,
					key: "Templates",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: translations.roles_checks_update,
							key: "Update",
						},
						{
							name: translations.roles_checks_countrylevel,
							key: "CountryLevel",
						},
					],
				},
			]
		},
		{
			group: translations.roles_settings,
			items: [
				{
					name: translations.businessunits_windowtitle,
					key: "BusinessUnits",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: translations.roles_checks_update,
							key: "Update",
						},
					],
				},
				{
					name: translations.industries_windowtitle,
					key: "Industries",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: translations.roles_checks_update,
							key: "Update",
						},
					],
				},
				{
					name: translations.languages_windowtitle,
					key: "Languages",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: translations.roles_checks_delete,
							key: "Delete",
						},
					],
				},
				{
					name: translations.services_windowtitle,
					key: "Offers",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: translations.roles_checks_update,
							key: "Update",
						},
					],
				},
				{
					name: translations.salutations_windowtitle,
					key: "Salutations",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_create,
							key: "Create",
						},
						{
							name: translations.roles_checks_update,
							key: "Update",
						},
					],
				},
			]
		},
		{
			group: translations.roles_security,
			items: [
				{
					name: translations.users_windowtitle,
					key: "Users",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_updateroles,
							key: "UpdateRoles",
						},
						{
							name: translations.roles_checks_updateresumes,
							key: "UpdateCvs",
						},
						{
							name: translations.roles_checks_countrylevel,
							key: "CountryLevel",
						},
					],
				},
				{
					name: translations.roles_windowtitle,
					key: "Roles",
					actions: [
						{
							name: translations.roles_checks_read,
							key: "Read",
						},
						{
							name: translations.roles_checks_update,
							key: "Update",
						},
					],
				}
			]
		},
		{
			group: translations.roles_extra,
			items: [
				{
					name: "Audit log",
					key: "MonitoringLogging",
					actions: [
						{
							name: translations.roles_checks_readonly,
							key: "ReadOnly",
						},
					],
				},
				{
					name: "Error log",
					key: "ErrorLogging",
					actions: [
						{
							name: translations.roles_checks_readonly,
							key: "ReadOnly",
						},
					],
				},
			]
		},
	]);

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.name) {
			default:
				// @ts-ignore
				newRecord.settings[event.target.name] = event.target.checked;
		}
		setRecord(newRecord);

		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		props.save(record);
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	return (
		<Dialog
			onClose={cancel}
			title={translations.general_edit + " " + (record && record.name ? record.name : "")}
			width="70%"
		>

			<form className="k-form no-spaced-form" onSubmit={onSubmit}>
				{permissionStructure.map((permissionSubStructure) => {
					return (
						<div key={permissionSubStructure.group}>
							<div className="roles-grouptitle">{permissionSubStructure.group}</div>
							<div className="roles-items">
								{permissionSubStructure.items.map((permission) => {
									return (
										<div className="row switch-row roles-row" key={permission.key}>
											{/*<div className="col-md-1">
										<label className="k-form-field">
											<input type="checkbox" id={"selectall" + permission.key} checked={allSelections[permission.key]} className="k-checkbox" onChange={(e: any) => { selectAll(e, permission.key) }} />
											<label className="k-checkbox-label" htmlFor={"selectall" + permission.key}></label>
										</label>

									</div>*/}
											<div className="col-md-2"><strong>{permission.name}</strong></div>
											{permission.actions.map((action) => {
												return (
													<div className="col-md-2" key={permission.key + action.key}>
														<label className="k-form-field">
															{/* 
													<Switch name={permission.key + "_" + action.key} checked={ record && record.settings && record.settings[permission.key + "_" + action.key] ? record.settings[permission.key + "_" + action.key] : false } onChange={onChange} />
													*/}
															<input type="checkbox" id={permission.key + action.key} checked={record && record.settings && record.settings[permission.key + "_" + action.key] ? record.settings[permission.key + "_" + action.key] : false} name={permission.key + "_" + action.key} className="k-checkbox" onChange={onChange} />
															<label className="k-checkbox-label" htmlFor={permission.key + action.key}>{action.name}</label>
														</label>
													</div>
												);
											})}
										</div>
									);
								})}
							</div>
						</div>
					)
				})}
			</form>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				<button className="k-button k-primary" onClick={() => preventDoubleClick(save)}>
					{translations.general_save}
				</button>
			</DialogActionsBar>
		</Dialog>
	);
}

Edit.defaultProps = {
	dataItem: null,
	save: (record: any) => { },
	cancel: () => { }
};

export default Edit;