import React, { useState, useEffect } from 'react';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { useBusinessUnits } from '../../../../repositories/settings/businessunits';
import { confirmChanged } from '../../../../services/renderer';
import translations from '../../../../translations';
import { preventDoubleClick } from '../../../../services/tools';

interface NewProps {
	save: (record: any) => void,
	cancel: () => void
}

const New: React.FC<NewProps> = (props) => {

	const [record, setRecord] = useState({
		id: "00000000-0000-0000-0000-000000000000",
		businessUnitID: "00000000-0000-0000-0000-000000000000",
		mainOffering: "",
		active: false
	});

	const businessunits = useBusinessUnits();
	const [dataChanged, setDataChanged] = useState(false);

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			case "businessUnitID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				break;
			default:
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value;
		}
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		props.save(record);
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	return (
		<Dialog onClose={cancel} title={translations.general_new + " " + translations.services_windowtitlesingle} width={400}>
			<form className="k-form" onSubmit={onSubmit}>
				<label className="k-form-field">
					<span>{translations.services_name}</span>
					<Input name="mainOffering" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + translations.services_name + "..."} value={(record && record.mainOffering ? record.mainOffering : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " " + translations.services_name} />
				</label>
				<label className="k-form-field">
					<span>{translations.services_businessunit}</span>
					<ComboBox
						data={businessunits}
						name="businessUnitID"
						dataItemKey="id"
						textField="name"
						style={{ width: "100%" }}
						onChange={onChange}
						required={true}
						suggest={true}
					/>
				</label>
				<label className="k-form-field">
					<span>{translations.general_active}</span>
					{/*
					// @ts-ignore*/ }
					<Switch name="active" checked={(record && record.active ? record.active : false)} onChange={onChange}
						onLabel={translations.general_switch_on}
						offLabel={translations.general_switch_off} />
				</label>
			</form>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>{translations.general_cancel}</button>
				<button className="k-button k-primary" onClick={() => preventDoubleClick(save)}>{translations.general_save}</button>
			</DialogActionsBar>
		</Dialog>
	);
}

New.defaultProps = {
	save: (record: any) => { },
	cancel: () => { }
};

export default New;