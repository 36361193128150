import React, { useState, useEffect } from "react";
import { close, imageToShow, onImageChanged } from "../../services/imageviewer";
import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';

const ImageViewer: React.FC = props => {

	const [, setTick] = useState(0);

	const update = () => {
		setTick(tick => tick + 1);
	}

	useEffect(() => {
		onImageChanged(() => update());

	}, []);


	return (
		<div>
			{imageToShow && (
				<div className="imageviewer-container">
					<div className="imageviewer-topbar">
						<div className="name-container">
							{imageToShow.name}
						</div>
						<button
							onClick={e => {
								e.preventDefault();
								close();
							}}
						>
							<i className="fa fa-times" />
						</button>
						<button
							onClick={e => {
								e.preventDefault();
								if (imageToShow) {
									saveAs(
										imageToShow.image + "?download=yes",
										imageToShow.name
									);
								}
							}}
						>
							<i className="fa fa-cloud-download" />
						</button>
					</div>
					<div className="imageviewer-imagearea">
						{/\.(gif|jpg|jpeg|tiff|png)$/i.test(imageToShow.extension.toLowerCase()) ? (
							<img src={imageToShow.image} />
						) : (
								<embed src={imageToShow.image} />
							)}
					</div>
				</div>
			)}
		</div>
	);
	};
	
	export default ImageViewer;
