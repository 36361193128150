import React, { useState, useEffect } from 'react';
import { getCollection, onCollectionChanged } from '../../services/renderer';

interface RendererProps {
	id?: string;
}

const Renderer: React.FC<RendererProps> = (props) => {

	const [, setTick] = useState(0);

	const update = () => {
		setTick(tick => tick + 1);
	}

	useEffect(() => {
		onCollectionChanged(() => update());

	}, []);

	return (
		<div>
			{getCollection( props.id ).map((renderConf, i) => {
				return <renderConf.component key={renderConf.key} {...renderConf.props} />
			})}
		</div>
	);
}

export default Renderer;
