import React, { useState, useEffect } from 'react';
import GridPanel from '../../../global/gridpanel';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import cellButton from '../../../global/cellbutton';
import New from './new';
import { show, close, confirm, showLoader, hideLoader } from '../../../../services/renderer';
import repoCountryLanguages from '../../../../repositories/settings/countrylanguages';
import { notify, alert } from '../../../../services/notification';
import { triggerListeners } from '../../../../services/listeners';
import { isAuthorized } from '../../../../services/authorization';
import { substitute } from '../../../../services/tools';
import translations from '../../../../translations';
import DeloitteButton from '../../../global/deloittebutton';

const List: React.FC = (props) => {

	function remove(dataItem: any) {
		confirm(
			substitute(translations.languages_remove, dataItem.language.name),
			substitute(translations.languages_remove_message, dataItem.language.name),
			() => {
				showLoader();
				repoCountryLanguages.delete(dataItem.language.id).then(() => {
					notify(translations.general_savedsuccess);
					triggerListeners("reloadLanguages");
					hideLoader();
				}).catch(error => {
					hideLoader();
					alert(error);
				});
			},
			translations.languages_remove_confirm,
			() => {},
			translations.general_dialog_no
		);
	}

	function cancel() {
		close("languagenew");
	}

	function save(record: any) {
		showLoader();
		repoCountryLanguages.add(record.languageID).then((result) => {
			notify(translations.general_savedsuccess);
			triggerListeners("reloadLanguages");
			cancel();
			hideLoader();
		}).catch((error) => {
			hideLoader();
			alert(error);
		});
	}

	return (
		<GridPanel reloadKey="reloadLanguages" loadData={repoCountryLanguages.getAll}>
			{isAuthorized("Languages", "Create") ? <GridToolbar >
				<div>
					<DeloitteButton title={translations.general_addnew} onClick={() => { show(New, "languagenew", { save: save, cancel: cancel }); }} >
						{translations.general_addnew}
					</DeloitteButton>
				</div>
			</GridToolbar> : null}
			<GridColumn field="language.name" title={translations.languages_name} editable={false} />
			<GridColumn field="language.code" title={translations.languages_code} editable={false} />
			{isAuthorized("Languages", "Delete") ? <GridColumn filterable={false} width="120px" editable={false} title={translations.general_remove} cell={cellButton(remove, translations.general_remove)}
			/> : null}
		</GridPanel>
	);
}

export default List;