import React, { useState, useEffect } from 'react';
import GridPanel from '../../../global/gridpanel';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import cellButton from '../../../global/cellbutton';
import New from './new';
import Edit from './edit';
import { show, close, showLoader, hideLoader } from '../../../../services/renderer';
import repoCoverImages from '../../../../repositories/settings/coverimages';
import { notify, alert } from '../../../../services/notification';
import { triggerListeners } from '../../../../services/listeners';
import { cloneObject } from '../../../../services/tools';
import { isAuthorized } from '../../../../services/authorization';
import translations from '../../../../translations';
import DeloitteButton from '../../../global/deloittebutton';
import repoIndustries from '../../../../repositories/settings/industries';
import repoServices from '../../../../repositories/settings/services';
import { ComboBox } from '@progress/kendo-react-dropdowns';

var externalIndustry:any;
var externalService:any;

const List: React.FC = (props) => {

	const [industries, setIndustries] = useState([]);
	const [services, setServices] = useState([]);
	const [selectedIndustry, setSelectedIndustry] = useState();
	const [selectedService, setSelectedService] = useState();

	useEffect(() => {
		repoIndustries.getActive().then((result) => {
			//@ts-ignore
			setIndustries(result.map((industry: any) => {
				return {
					id: industry.id,
					name: industry.name
				}
			}));
		});
		repoServices.subServices.getActiveForCountry().then((result) => {
			//@ts-ignore
			setServices(result.map((service: any) => {
				return {
					id: service.id,
					name: service.mainOffer.mainOffering + " > " + service.subOffering
				}
			}));
		});
	}, []);

	function edit(dataItem: any) {
		show(Edit, "coverimageedit", { dataItem: cloneObject(dataItem), save: save, cancel: cancel });
	}

	function cancel() {
		close("coverimageedit");
		close("coverimagenew");
	}

	function save(record: any) {
		showLoader();
		if (!record.id ||
			!record.name ||
			!record.backgroundColor) {
			hideLoader();
			alert(translations.general_fillinallrequiredfields);
		} else if (!record.industries || (record.industries && record.industries.length == 0)) {
			hideLoader();
			alert(translations.general_selectatleastoneindustry);
		} else if (!record.services || (record.services && record.services.length == 0)) {
			hideLoader();
			alert(translations.general_selectatleastoneservice);
		} else {
			var formData = new FormData();
			if (record.image_fileAction && record.image_fileAction == "change") {
				let file = record.file.getRawFile();
				formData.append("image_file", file);
			}
			formData.append("id", record.id);
			formData.append("name", record.name);
			formData.append("backgroundColor", record.backgroundColor);
			formData.append("active", record.active);
			record.industries.forEach((industry: any) => {
				formData.append("industryIDs[]", industry);
			});
			record.services.forEach((service: any) => {
				formData.append("serviceIDs[]", service);
			});
			if (record.image_fileAction) formData.append("image_fileAction", record.image_fileAction);
			repoCoverImages.save(formData).then(result => {
				notify(translations.general_savedsuccess);
				triggerListeners("reloadCoverImages");
				cancel();
				hideLoader();
			}).catch((error) => {
				hideLoader();
				alert(error);
			});
		}
	}

	function onChangeFilters(event: any) {
		switch (event.target.props.name) {
			case "industryFilter":
				// @ts-ignore
				setSelectedIndustry(event.target.value ? event.target.value : null);
				externalIndustry = event.target.value ? event.target.value : null;
				break;
			case "serviceFilter":
				// @ts-ignore
				setSelectedService(event.target.value ? event.target.value : null);
				externalService = event.target.value ? event.target.value : null;
				break;
		}
		triggerListeners("reloadCoverImages");
	}


	function loadData(conf: any) {
		if (externalIndustry || externalService) conf.extraFilter = {};
		// @ts-ignore
		if (externalIndustry) conf.extraFilter.industryID = externalIndustry.id;
		// @ts-ignore
		if (externalService) conf.extraFilter.serviceID = externalService.id;
		return repoCoverImages.getAll(conf);
	}

	return (
		<GridPanel reloadKey="reloadCoverImages" loadData={loadData} filter={{
			logic: "and",
			filters: [
				{
					field: "active",
					operator: "eq",
					value: true
				}
			]
		}} onRowDoubleClick={edit}>
			{isAuthorized("CoverImages", "Create") ? <GridToolbar >
				<div>
					<DeloitteButton title={translations.general_addnew} onClick={() => { show(New, "coverimagenew", { save: save, cancel: cancel }); }} >
						{translations.general_addnew}
					</DeloitteButton>
					<div style={{ display: 'inline-block', marginLeft: '30px', marginRight: '10px' }}>
						Industries:&nbsp;
						<ComboBox
							data={industries}
							name="industryFilter"
							value={selectedIndustry}
							dataItemKey="id"
							textField="name"
							onChange={onChangeFilters}
							suggest={true}
						/>
					</div>
					<div style={{ display: 'inline-block', marginRight: '10px' }}>
						Services:&nbsp;
						<ComboBox
							data={services}
							name="serviceFilter"
							value={selectedService}
							dataItemKey="id"
							textField="name"
							onChange={onChangeFilters}
							suggest={true}
						/>
					</div>
				</div>
			</GridToolbar> : null}
			<GridColumn field="name" title={translations.coverimages_name} editable={false} />
			<GridColumn field="active" title={translations.general_active} width="125px" editor="boolean" filter={'boolean'} />
			{isAuthorized("CoverImages", "Update") ? <GridColumn filterable={false} width="120px" editable={false} title={translations.general_edit} cell={cellButton(edit, translations.general_edit)} /> :
				(!isAuthorized("CoverImages", "Update") && isAuthorized("CoverImages", "Read") ? <GridColumn filterable={false} width="120px" editable={false} title={translations.general_view} cell={cellButton(edit, translations.general_view)} /> : null)} 
		</GridPanel>
	);
}

export default List;