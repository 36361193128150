import axios from 'axios';
import { getToken } from '../../services/authentication';

const baseURL = "/api/myprofile/resumes/";
const baseURL2 = "/api/settings/security/users/";

const repository = {
	getAll: async () => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "getall", {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	getAllForUser: async (userID: string) => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL2 + userID + "/resumes/getall", {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	save: async (record: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.put(baseURL + "save", record, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			if (!record.id) record.id = result.data;
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	saveForUser: async (userID: string, record: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.put(baseURL2 + "resumes/save", record, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			if (!record.id) record.id = result.data;
			return result.data;
		} catch (error) {
			throw error;
		}
	}
};

export default repository;