import React, { useState, useEffect } from 'react';

interface ButtonProps {
	iconClass: string,
	title?: string | undefined,
	additionalClass?: string | undefined,
	onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined,
	disabled?: boolean | undefined,
	hidden?: boolean | undefined,
	key?: string | number | undefined
}

const DeloitteButton: React.FC<ButtonProps> = (props) => {
	return (
		<div key={props.key} title={props.title} className={"deloitte-icon-button " + props.additionalClass + (props.disabled ? " disabled" : "")} onClick={props.disabled ? () => {} : props.onClick} hidden={props.hidden}>
			<span className={props.iconClass}></span>
		</div>
	);
};

export default DeloitteButton;