import React from 'react';
import { Route, NavLink as Link } from "react-router-dom";
import SectionsList from './sections/list';
import PublicationsList from './publications/list';
import WelcomeLettersList from './welcomeletters/list';
import LegalDocumentsList from './legaldocuments/list';
import CredentialsList from './credentials/list';
import AchievementsList from './achievements/list';
import CoverImagesList from './coverimages/list';
import TemplatesList from './templates/list';
import { isAuthorized } from '../../../services/authorization';
import translations from '../../../translations';

const Content: React.FC = (props: any) => {

	const items = [
		{
			label: translations.sections_windowtitle,
			url: "/sections",
			securityKey: "Sections",
			key: "sections"
		},
		{
			label: translations.templates_windowtitle,
			url: "/templates",
			securityKey: "Templates",
			key: "templates"
		},
		{
			label: translations.welcomeletters_windowtitle,
			url: "/welcomeletters",
			securityKey: "WelcomeLetters",
			key: "welcomeletters"
		},
		{
			label: translations.publications_windowtitle,
			url: "/publications",
			securityKey: "Publications",
			key: "publications"
		},
		{
			label: translations.legaldocuments_windowtitle,
			url: "/legaldocuments",
			securityKey: "LegalDocuments",
			key: "legaldocuments"
		},
		{
			label: translations.credentials_windowtitle,
			url: "/credentials",
			securityKey: "Credentials",
			key: "credentials"
		},
		{
			label: translations.achievements_windowtitle,
			url: "/achievements",
			securityKey: "Achievements",
			key: "achievements"
		},
		{
			label: translations.coverimages_windowtitle,
			url: "/coverimages",
			securityKey: "CoverImages",
			key: "coverimages"
		},
	]

	return (
		<div>
			<div className="top-bar">
				<ul>
					{
						items.map((item, i) => {
							return (item.securityKey === "" || !item.securityKey || isAuthorized(item.securityKey, "*") ? (
								<Link to={props.match.url + item.url} key={i} activeClassName="active"><li> {item.label} </li></Link>
							) : null )
						})
					}
				</ul>
			</div>
			<div className="content-container">
				<Route path={props.match.url}>
					<div className="pages-general pages-content">
						<h1>Content</h1>
						<div className="pages-general-row">
							{items.map((item, i) => {
								return (item.securityKey === "" || !item.securityKey || isAuthorized(item.securityKey, "*") ? (
									<Link to={props.match.url + item.url} key={i}>
										<div className={"pages-general-block pages-content-" + item.key}><span>{item.label}</span></div>
									</Link>
								) : null)
							})}
						</div>
					</div>
				</Route>
				{isAuthorized("Sections", "*") && (<Route path={props.match.url + "/sections"} component={SectionsList} />)}
				{isAuthorized("WelcomeLetters", "*") && (<Route path={props.match.url + "/welcomeletters"} component={WelcomeLettersList} />)}
				{isAuthorized("Publications", "*") && (<Route path={props.match.url + "/publications"} component={PublicationsList} />)}
				{isAuthorized("LegalDocuments", "*") && (<Route path={props.match.url + "/legaldocuments"} component={LegalDocumentsList} />)}
				{isAuthorized("Credentials", "*") && (<Route path={props.match.url + "/credentials"} component={CredentialsList} />)}
				{isAuthorized("Achievements", "*") && (<Route path={props.match.url + "/achievements"} component={AchievementsList} />)}
				{isAuthorized("CoverImages", "*") && (<Route path={props.match.url + "/coverimages"} component={CoverImagesList} />)}
				{isAuthorized("Templates", "*") && (<Route path={props.match.url + "/templates"} component={TemplatesList} />)}
			</div>
		</div>
	);
}

export default Content;
