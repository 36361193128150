///<reference path= "../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { editorWelcomeLetter, editorSection } from '../../../services/environment';
import { Input, Switch, NumericTextBox } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { getMyProfile } from '../../../services/authentication';
import GridPanel from '../../global/gridpanel';
import { GridToolbar, GridColumn } from '@progress/kendo-react-grid';
import cellButton from '../../global/cellbutton';
import { ComboBox } from '@progress/kendo-react-dropdowns';
//@ts-ignore
import { CirclePicker } from 'react-color';
import translations, { getCurrentLanguage, getEmailBodyForProposal } from '../../../translations';
import repoProposals from '../../../repositories/proposals/proposals';
import { useUsers, usePartners } from '../../../repositories/security/users';
import repoSections from '../../../repositories/settings/sections';
import repoUsers from '../../../repositories/security/users';
import repoUserResumes from '../../../repositories/security/userresumes';
import repoCoverImages from '../../../repositories/settings/coverimages';
import _ from 'lodash';
import moment from 'moment';
import { formatMoney } from '../../../services/tools';
//@ts-ignore
import FroalaEditor from 'froala-editor';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { show, close, confirm, showLoader, hideLoader } from '../../../services/renderer';
import NewTeamMember from './newteammember';
import NewResumeMember from './newresumemember';
import NewContact from './newcontact';
import NewDynamicEntries from './newdynamicentries';
import EditResume from './editresume';
import SearchAchievements from './searchachievements';
import { notify, alert } from '../../../services/notification';
import sectionIcons from '../../../services/sectionicons';
import NewSection from './sectionnew';
import NewAttachment from './newattachment';
import Promise from 'bluebird';
import pdfImage from '../../../assets/img/pdf.png';
import templateImage from '../../../assets/img/pages/templates.png';
import userPlaceholder from '../../../assets/img/user-placeholder.png';
import DeloitteButton from '../../global/deloittebutton';
import DeloitteCheckbox from '../../global/deloittecheckbox';
import SendProposal from './send';
import { isRegularExpressionLiteral } from 'typescript';
import { preventDoubleClick } from '../../../services/tools';

let timeoutFunction: NodeJS.Timeout;
let delayResize: NodeJS.Timeout;
let sectionEditor: any;
let lastViewUniqueId: any;

interface Query {
	languageID: string,
	businessUnitID: string,
	landscape: boolean,
	industryIDs: Array<string>,
	serviceIDs: Array<string>
}

const arrColors = [
	'#c4d600',
	'#86bc25',
	'#43b02a',
	'#046a38',
	'#2c5234',
	'#0097a9',
	'#62b5e5',
	'#00a3e0',
	'#0076a8',
	'#012169',
	'#53565a',
	'#75787b',
	'#97999b',
	'#bbbcbc',
	'#d0d0ce'
];

const ProposalEditor: React.FC = (props: any) => {
	const [record, setRecord]:[any,Function] = useState({
		id: props.dataItem.id,
		status: props.dataItem.status,
		businessUnitID: props.dataItem.businessUnitID,
		companyName: props.dataItem.companyName,
		companyAddress: props.dataItem.companyAddress,
		sapReference: props.dataItem.sapReference,
		creatorID: props.dataItem.creatorID,
		creator: props.dataItem.creator,
		signingPartnerID: props.dataItem.signingPartnerID,
		signingPartner: props.dataItem.signingPartner,
		engagementPartnerID: props.dataItem.engagementPartnerID,
		engagementPartner: props.dataItem.engagementPartner,
		welcomeLetter: props.dataItem.welcomeLetter,
		hideWelcomeLetter: props.dataItem.hideWelcomeLetter,
		landscape: props.dataItem.landscape,
		title: props.dataItem.title,
		subtitle: props.dataItem.subtitle,
		languageID: props.dataItem.languageID,
		language: props.dataItem.language,
		industryID: props.dataItem.industryID,
		industry: props.dataItem.industry,
		serviceID: props.dataItem.serviceID,
		service: { ...props.dataItem.service, name: props.dataItem.service.subOffering },
		expectedRevenue: props.dataItem.expectedRevenue,
		creationDate: new Date(props.dataItem.creationDate),
		proposalDate: new Date(props.dataItem.proposalDate),
		deadlineDate: new Date(props.dataItem.deadlineDate),
		type: props.dataItem.type,
		emailBody: props.dataItem.emailBody,
		templateID: "00000000-0000-0000-0000-000000000000",
		contacts: new Array(),
		pursuitTeam: new Array(),
		coverImageID: props.dataItem.coverImageID,
		coverImage: props.dataItem.coverImage
	});
	const [isCreator] = useState(props.dataItem.creatorID == getMyProfile().userID);
	const [isSigningPartner, setIsSigningPartner] = useState(props.dataItem.signingPartnerID == getMyProfile().userID);
	const [isElevatedContributor, setIsElevatedContributor] = useState(false);
	const [proposalStatus, setProposalStatus] = useState(props.dataItem.status);
	const [saveStatus, setSaveStatus] = useState("");
	const [selectedTab, setSelectedTab] = useState(0);
	const users = useUsers();
	const partners = usePartners();
	const [sections, setSections] = useState(new Array());
	const [counterStatusCheck, setCounterStatusCheck] = useState(0);
	const [currentSectionKey, setCurrentSectionKey] = useState("");
	const [currentSectionName, setCurrentSectionName] = useState("");
	const [currentSectionContent, setCurrentSectionContent] = useState("");
	const [currentSectionDynamicContent, setCurrentSectionDynamicContent]: [any, Function] = useState(new Array());
	const [currentSectionResumes, setCurrentSectionResumes]: [any, Function] = useState(new Array);
	const [currentSectionIcon, setCurrentSectionIcon] = useState("");
	const [currentSectionType, setCurrentSectionType] = useState("");
	const [currentSectionReadyForApproval, setCurrentSectionReadyForApproval] = useState(false);
	const [currentSectionApproved, setCurrentSectionApproved] = useState(false);
	const [currentSectionIsLocked, setCurrentSectionIsLocked] = useState(false);
	const [currentSectionIsLockedByMe, setCurrentSectionIsLockedByMe] = useState(false);
	const [currentSectionLockedStatus, setCurrentSectionLockedStatus] = useState("");
	const [currentSectionHideTitle, setCurrentSectionHideTitle] = useState(false);
	const [currentSectionHideHeaderAndFooter, setCurrentSectionHideHeaderAndFooter] = useState(false);
	const [currentSectionBackgroundColor, setCurrentSectionBackgroundColor] = useState("#ffffff");
	const [draggedSection, setDraggedSection] = useState();
	const [draggedOverSection, setDraggedOverSection]: [any, Function] = useState();
	const [draggedEntry, setDraggedEntry] = useState();
	const [draggedOverEntry, setDraggedOverEntry]: [any, Function] = useState();
	const [draggedResume, setDraggedResume] = useState();
	const [draggedOverResume, setDraggedOverResume]: [any, Function] = useState();
	const [iconSelectorVisible, setIconSelectorVisible] = useState(false);
	const [teamMembers, setTeamMembers]: [Array<any>, Function] = useState([]);
	const [contacts, setContacts]: [Array<any>, Function] = useState([]);
	const [coverImageSelectorVisible, setCoverImageSelectorVisible] = useState(false);
	const [coverImages, setCoverImages] = useState([]);
	const ref: any = useRef(null);
	const ref2: any = useRef(null);
	const [height, setHeight] = useState(0);
	const [height2, setHeight2] = useState(0);
	const [editorConfig] = useState({
		...editorWelcomeLetter,
		placeholderText: translations.welcomeletters_content_placeholder,
		language: getCurrentLanguage(),
		height: 400,
		events: {
			"commands.after": (cmd: string) => {
				if (cmd == "fullscreen") changeHeight();
			}
		}
	});
	const [editorSectionConfig] = useState({
		...editorSection,
		placeholderText: translations.sections_content_placeholder,
		language: getCurrentLanguage(),
		height: 400,
		events: {
			...editorSection.events,
			"commands.after": (cmd: string) => {
				if (cmd == "fullscreen") changeHeight();
			}
		}
	});
	const [showSendForReview, setShowSendForReview] = useState(false);
	const [sectionExtraSettingsVisible, setSectionExtraSettingsVisible] = useState(false);
	const [colorPickerVisible, setColorPickerVisible] = useState(false);

	useEffect(() => {
		if (proposalStatus == "ReadyForReview") setShowSendForReview(true);
		repoProposals.get(props.dataItem.id).then((result) => {
			setRecord({ ...record, service: { ...result.service, name: result.service.mainOffer.mainOffering + " - " + result.service.subOffering } })
			setContacts(result.contacts.map((contact: any) => {
				return { ...contact, firstName: contact.firstname }
			}));
			setTeamMembers(result.pursuitTeam.map((teamMember: any) => {
				return { ...teamMember, role: (teamMember.editProfile && teamMember.editProfile.general) ? "Elevated contributor" : "Contributor", type: "contributor" }
			}));
			for (var i = 0; i < result.pursuitTeam.length; i++) {
				if (result.pursuitTeam[i].user.userID === getMyProfile().userID) {
					if (result.pursuitTeam[i].editProfile.general && result.pursuitTeam[i].editProfile.general === true) {
						setIsElevatedContributor(true);
						break;
					}
				}
			}
			// @ts-ignore
			let tmpSections: Array<any> = _.sortBy(result.sections, ["sequence"]).map((section) => {
				return {
					...section,
					key: section.id,
					dynamicContent: section.dynamicContent === undefined ? new Array() : section.dynamicContent,
					// @ts-ignore
					resumes: section.resumes === undefined ? new Array() : [..._.sortBy(section.resumes, ["sequence"]).map((resume: any) => {
						return {
							...resume,
							key: resume.userID,
							name: resume.user.fullName,
							imageURL: resume.user.image.size > 0 ? "/api/content/files/get/" + resume.user.image.internalFilename + "/" + resume.user.image.filename : userPlaceholder
						};
					})]
				}
			});
			setSections(tmpSections);
			if (tmpSections.length > 0) switchSection(tmpSections[0]);
			repoCoverImages.getActive({
				industryID: result.industryID,
				serviceID: result.serviceID
			}).then((resultCoverImages) => {
				setCoverImages(resultCoverImages.map((coverImage: any) => {
					return {
						id: coverImage.id,
						name: coverImage.name,
						image: coverImage.image,
						backgroundColor: coverImage.backgroundColor
					}
				}));
			});
			setTimeout(() => setCounterStatusCheck(counterStatusCheck + 1), 4000);

		});
		window.addEventListener("resize", changeHeight);
		return () => {
			window.removeEventListener("resize", changeHeight);
		};

	}, []);

	useEffect(() => {
		if (counterStatusCheck > 0) {
			repoProposals.getProposalSectionsStatus(props.dataItem.id).then((result) => {
				var newSections = [];
				var hasChanged = false;
				for (var i = 0; i < result.length; i++) {
					var foundSection = _.find(sections, { id: result[i].id });
					if (foundSection) {
						if (
							foundSection.sequence !== result[i].sequence || 
							foundSection.approved !== result[i].approved || 
							foundSection.icon !== result[i].icon || 
							foundSection.name !== result[i].name || 
							foundSection.readyForApproval !== result[i].readyForApproval || 
							foundSection.recordInfo.updatedDate !== result[i].recordInfo.updatedDate || 
							foundSection.lockedByID !== result[i].lockedByID
						) {
							if (currentSectionKey === foundSection.key && currentSectionIsLockedByMe && result[i].recordInfo.updatedDate > foundSection.recordInfo.updatedDate) {

							}
							hasChanged = true;
							newSections.push({...result[i], key: result[i].id, load: true});
						} else {
							newSections.push(foundSection);
						}
					} else {
						hasChanged = true;
						newSections.push({...result[i], key: result[i].id, load: true});
					}
				}
				if (hasChanged) {
					for (var i = 0; i < sections.length; i++) {
						if (sections[i].key && sections[i].key.indexOf("new_") === 0) newSections.push(sections[i]);
					}
					// @ts-ignore
					Promise.mapSeries(newSections, (section) => {
						if (section.load && section.load === true) {
							return repoProposals.getSection(section.id).then((processedSection) => {
								return { ...processedSection, key: processedSection.id };
							});
						} else {
							return section;
						}
					}).then((processedSections: any[]) => {
						var searchSectionToReload = _.find(processedSections, { key: currentSectionKey});
						if (searchSectionToReload && (!currentSectionIsLockedByMe || !searchSectionToReload.lockedByID)) {
							if (currentSectionIsLockedByMe && !searchSectionToReload.lockedByID) {
								alert("This section was unlocked by an administrator of this proposal!");
							}
							setTimeout(() => switchSection(searchSectionToReload), 200);
						}
						setSections(processedSections);
						setTimeout(() => setCounterStatusCheck(counterStatusCheck + 1), 2000);
					});
				} else {
					setTimeout(() => setCounterStatusCheck(counterStatusCheck + 1), 2000);
				}
			});
		}
	}, [counterStatusCheck]);

	useLayoutEffect(() => {
		var newHeight = ref.current.clientHeight;
		var newHeight2 = ref2.current.clientHeight;
		if (ref && ref.current && ref.current.getElementsByClassName("fr-wrapper").length == 1 && ref.current.getElementsByClassName("fr-toolbar").length == 1 && ref.current.getElementsByClassName("fr-fullscreen").length == 0) {
			newHeight = newHeight - 122 - ref.current.getElementsByClassName("fr-toolbar")[0].clientHeight;
		}
		if (ref2 && ref2.current && ref2.current.getElementsByClassName("fr-wrapper").length == 1 && ref2.current.getElementsByClassName("fr-toolbar").length == 1 && ref2.current.getElementsByClassName("fr-fullscreen").length == 0) {
			newHeight2 = newHeight2 - 60 - ref2.current.getElementsByClassName("fr-toolbar")[0].clientHeight;
		}
		setHeight(newHeight);
		setHeight2(newHeight2);
	});

	function changeHeight() {
		clearTimeout(delayResize);
		delayResize = setTimeout(() => {
			var newHeight = ref.current.clientHeight;
			var newHeight2 = ref2.current.clientHeight;
			if (ref && ref.current && ref.current.getElementsByClassName("fr-wrapper").length == 1 && ref.current.getElementsByClassName("fr-toolbar").length == 1 && ref.current.getElementsByClassName("fr-fullscreen").length == 0) {
				newHeight = newHeight - 122 - ref.current.getElementsByClassName("fr-toolbar")[0].clientHeight;
			}
			if (ref2 && ref2.current && ref2.current.getElementsByClassName("fr-wrapper").length == 1 && ref2.current.getElementsByClassName("fr-toolbar").length == 1 && ref2.current.getElementsByClassName("fr-fullscreen").length == 0) {
				newHeight2 = newHeight2 - 100 - ref2.current.getElementsByClassName("fr-toolbar")[0].clientHeight;
			}
			setHeight(newHeight);
			setHeight2(newHeight2);
		}, 500);
	}

	useEffect(() => {
		if (ref && ref.current && ref.current.getElementsByClassName("fr-wrapper").length == 1 && ref.current.getElementsByClassName("fr-toolbar").length == 1 && ref.current.getElementsByClassName("fr-fullscreen").length == 0) {
			ref.current.getElementsByClassName("fr-wrapper")[0].style.height = height.toString() + "px";
		}
	}, [height]);

	useEffect(() => {
		if (ref2 && ref2.current && ref2.current.getElementsByClassName("fr-wrapper").length == 1 && ref2.current.getElementsByClassName("fr-fullscreen").length == 0) {
			ref2.current.getElementsByClassName("fr-wrapper")[0].style.height = height2.toString() + "px";
		}
	}, [height2]);

	function setClasses(tab: number) {
		let className = "";
		if (tab == selectedTab) className += "active ";
		if (tab < selectedTab) className += "completed ";
		if (tab == (selectedTab - 1)) className += "last";
		return className;
	}

	function changeTab(tab: number) {
		if (tab !== 3) {
			leaveSection(() => {
				setSelectedTab(tab);
			});
		} else {
			setSelectedTab(tab);
		}
	}

	function closeEditor() {
		leaveSection(() => {
			props.cancel();
		});
	}

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			case "industryID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				break;
			case "serviceID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				break;
			case "languageID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				newRecord.language = event.target.value;
				break;
			case "signingPartnerID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.userID : "00000000-0000-0000-0000-000000000000";
				newRecord.signingPartner = event.target.value;
				if (event.target.value) setIsSigningPartner(event.target.value.userID == getMyProfile().userID);
				break;
			case "engagementPartnerID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.userID : "00000000-0000-0000-0000-000000000000";
				newRecord.engagementPartner = event.target.value;
				break;
			case "welcomeletterID":
				newRecord.welcomeLetter = event.target.value ? event.target.value.content : "";
				break;
			default:
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value;
		}
		setRecord(newRecord);
		save(newRecord);
	}

	function changeContent(newContent: string) {
		if (isAllowed("GeneralField")) {
			let newRecord = { ...record };
			newRecord.welcomeLetter = newContent;
			clearTimeout(timeoutFunction);
			timeoutFunction = setTimeout(() => {
				save(newRecord);
			}, 2000);
			setRecord(newRecord);
		}
	}

	function save(newRecord: any, callback?: Function) {
		if (newRecord.coverImageID == "00000000-0000-0000-0000-000000000000" ||
			newRecord.title == "" ||
			newRecord.subtitle == "" ||
			newRecord.signingPartnerID == "00000000-0000-0000-0000-000000000000" ||
			newRecord.welcomeLetter == ""
		) {
			setSaveStatus("You need to enter all required fields first!");
		} else {
			setSaveStatus("Saving...");
			if (newRecord.expectedRevenue > 0) {
				var recordToSave = { ...newRecord };
				//recordToSave.contacts = contacts;
				/*
				// @ts-ignore
				recordToSave.pursuitTeam = teamMembers.map((teamMember: any) => {
					return {
						id: "00000000-0000-0000-0000-000000000000",
						userID: teamMember.userID,
						editProfile: {}
					};
				});
				*/
				delete recordToSave.creator;
				delete recordToSave.signingPartner;
				delete recordToSave.engagementPartner;
				delete recordToSave.language;
				delete recordToSave.coverImage;
				delete recordToSave.industry;
				delete recordToSave.service;
				var isFullyReadyForApproval = true;
				var isFullyApproved = true;
				if (contacts.length == 0) {
					isFullyReadyForApproval = false;
					isFullyApproved = false;
				}
				for (let i = 0; i < sections.length; i++) {
					if (sections[i].readyForApproval !== true) {
						isFullyReadyForApproval = false;
					} 
					if (sections[i].approved !== true) {
						isFullyApproved = false;
					} 
				}
				if (!isFullyReadyForApproval) {
					recordToSave.status = "InProgress";
				} else if (isFullyReadyForApproval && !isFullyApproved && proposalStatus !== "InReview" && proposalStatus !== "ReadyToSend") {
					recordToSave.status = "ReadyForReview";
					setTimeout(() => {
						setShowSendForReview(true);
					}, 1000);
				} else if (isFullyReadyForApproval && !isFullyApproved && proposalStatus == "ReadyToSend") {
					recordToSave.status = "InReview";
				} else if (isFullyReadyForApproval && isFullyApproved && proposalStatus == "InReview") {
					recordToSave.status = "ReadyToSend";
				}
				delete recordToSave.contacts;
				delete recordToSave.pursuitTeam;
				repoProposals.save(recordToSave).then((result) => {
					setSaveStatus("Saved");
					setTimeout(() => {
						setSaveStatus("");
					}, 3000);
					setProposalStatus(recordToSave.status);
					if (callback && typeof callback == "function") callback();
				}).catch((err) => {
					setSaveStatus("Unable to save!");
					alert(err);
				});
			} else {
				setSaveStatus("You need to enter an expected revenue greater than 0!");
			}
		}
	}

	function sendForReview() {
		setSaveStatus("Saving...");
		repoProposals.updateStatus(record.id, "InReview").then(() => {
			setSaveStatus("Saved");
			setProposalStatus("InReview");
		}).catch((err) => {
			setSaveStatus("Unable to save!");
			alert(err);
		});
	}

	function sendWon() {
		setSaveStatus("Saving...");
		repoProposals.updateStatus(record.id, "Won").then(() => {
			setSaveStatus("Saved");
			setProposalStatus("Won");
		}).catch((err) => {
			setSaveStatus("Unable to save!");
			alert(err);
		});
	}

	function sendLost() {
		setSaveStatus("Saving...");
		repoProposals.updateStatus(record.id, "Lost").then(() => {
			setSaveStatus("Saved");
			setProposalStatus("Lost");
		}).catch((err) => {
			setSaveStatus("Unable to save!");
			alert(err);
		});
	}

	function sendBackToReview() {
		setSaveStatus("Saving...");
		repoProposals.updateStatus(record.id, "InReview").then(() => {
			setSaveStatus("Saved");
			setProposalStatus("InReview");
		}).catch((err) => {
			setSaveStatus("Unable to save!");
			alert(err);
		});
	}

	function sendProposal() {
		show(SendProposal, "sendproposal", {
			save: (sendproposal: any) => {
				showLoader();
				repoProposals.save({
					...record,
					emailBody: sendproposal.emailBody
				}).then(() => {
					repoProposals.send(record.id).then(() => {
						setProposalStatus("Sending");
						hideLoader();
						close("sendproposal");
					}).catch((err: any) => {
						alert(err);
					});;
				}).catch((err: any) => {
					alert(err);
				});
			},
			cancel: () => {
				close("sendproposal");
			},
			dataItem: {
				emailBody: getEmailBodyForProposal(record),
				title: record.companyName
			}
		})
	}

	async function getTeamMembers() {
		var memberList: Array<any> = [];
		memberList.push({
			id: 'creator',
			userID: record.creatorID,
			user: record.creator,
			role: "Creator",
			editProfile: { "general": true, "welcomeLetter": true, "contacts": true, "pursuitTeam": true, "sections": ["c1742822-eed7-4956-ba61-b6a800255606 ", "68f56aa3-acbc-4243-8859-c838ce3f265a ", "2655d1ae-ff3e-4dcc-9882-1776a29874a0"] },
			type: 'static'
		});
		if (record.signingPartnerID !== "00000000-0000-0000-0000-000000000000") {
			memberList.push({
				id: 'signingpartner',
				userID: record.signingPartnerID,
				user: record.signingPartner,
				role: "Signing partner",
				editProfile: { "general": true, "welcomeLetter": true, "contacts": true, "pursuitTeam": true, "sections": ["c1742822-eed7-4956-ba61-b6a800255606 ", "68f56aa3-acbc-4243-8859-c838ce3f265a ", "2655d1ae-ff3e-4dcc-9882-1776a29874a0"] },
				type: 'static'
			});
		}
		if (record.engagementPartnerID !== "00000000-0000-0000-0000-000000000000") {
			memberList.push({
				id: 'engagementpartner',
				userID: record.engagementPartnerID,
				user: record.engagementPartner,
				role: "Engagement partner",
				editProfile: { "general": true, "welcomeLetter": true, "contacts": true, "pursuitTeam": true, "sections": ["c1742822-eed7-4956-ba61-b6a800255606 ", "68f56aa3-acbc-4243-8859-c838ce3f265a ", "2655d1ae-ff3e-4dcc-9882-1776a29874a0"] },
				type: 'static'
			});
		}
		memberList = memberList.concat(teamMembers);
		return {
			total: memberList.length, data: memberList
		};
	}

	function saveNewTeamMember(user: any, editProfile: any) {
		setSaveStatus("Saving...");
		repoProposals.saveTeamMember({
			id: "00000000-0000-0000-0000-000000000000",
			userID: user.userID,
			editProfile: editProfile,
			proposalID: record.id
		}).then((result) => {
			teamMembers.push({ id: result, userID: user.userID, user: user, role: (editProfile && editProfile.general) ? "Elevated contributor" : "Contributor", editProfile: editProfile, type: 'contributor' });
			setTeamMembers([...teamMembers]);
			close("newteammember");
			setSaveStatus("Saved");
		});
	}

	function removeTeamMember(teamMember: any) {
		confirm("Remove team member?", "Do you really want to remove " + teamMember.user.fullName + " as team member?", () => {
			setSaveStatus("Saving...");
			repoProposals.deleteTeamMember(teamMember.id).then((result) => { setSaveStatus("Saved"); });
			// @ts-ignore
			_.remove(teamMembers, { type: "contributor", userID: teamMember.userID });
			setTeamMembers([...teamMembers]);
		}, "Yes", () => { }, "No");
	}

	async function getContacts() {
		return {
			total: contacts.length, data: contacts
		};
	}

	function saveNewContact(contact: any) {
		setSaveStatus("Saving...");
		repoProposals.saveContact({ ...contact, proposalID: record.id }).then((result) => {
			contacts.push({...contact, id: result });
			setContacts([...contacts]);
			close("newcontact");
			let newRecord = { ...record };
			save(newRecord);
		});
	}

	function removeContact(contact: any) {
		confirm("Remove contact?", "Do you really want to remove " + contact.firstName + " " + contact.lastName + " contact?", () => {
			setSaveStatus("Saving...");
			repoProposals.deleteContact(contact.id).then((result) => { 
				// @ts-ignore
				_.remove(contacts, { email: contact.email });
				setContacts([...contacts]);
				let newRecord = { ...record };
				save(newRecord);
			});
		}, "Yes", () => { }, "No");
	}

	function hideCoverImageSelector(e: any) {
		setCoverImageSelectorVisible(false);
		window.removeEventListener('click', hideCoverImageSelector, false);
	}

	function selectCoverImage() {
		if (isAllowed("GeneralField")) {
			window.removeEventListener('click', hideCoverImageSelector, false);
			setCoverImageSelectorVisible(true);
			setTimeout(() => {
				window.addEventListener('click', hideCoverImageSelector, false);
			}, 400);
		}
	}

	function changeCoverImage(coverImage: any) {
		let newRecord = { ...record };
		newRecord.coverImageID = coverImage.id;
		newRecord.coverImage = coverImage;
		save(newRecord);
		setRecord(newRecord);
		setCoverImageSelectorVisible(false);
	}

	function switchSection(section: any) {
		leaveSection(() => {
			switchSectionForReal(section);
		});
	}

	function leaveSection(followingAction: any) {
		if (currentSectionIsLockedByMe) {
			confirm("Leave section?", "You are currently editing this section, are you sure you want to leave this section? If you continue, you will loose your changes.", () => {
				repoProposals.unlockSection(currentSectionKey).then(() => {
					setCurrentSectionIsLocked(false);
					setCurrentSectionIsLockedByMe(false);
					setCurrentSectionLockedStatus("");
					let activeSection = _.find(sections, { key: currentSectionKey });
					if (activeSection) {
						activeSection.lockedBy = undefined;
						activeSection.lockedByID = undefined;
						activeSection.lockedSince = undefined;
					}
					setSections([...sections]);
				}).then(() => {
					followingAction();
				});
			}, "Yes, leave", () => {
				// do nothing
			}, "Cancel");
	
		} else {
			followingAction();
		}	
	}

	function setFroalaEditorBackgroundColor(color: any, uniqueId: any) {
		if (!color || color == "") color = "#ffffff";
		if (uniqueId == "") {
			var currentFroalaView: any = document.querySelector("div.editor-sections-sectioncontent-container");
			if (currentFroalaView) {
				currentFroalaView.classList.remove(lastViewUniqueId);
				lastViewUniqueId = "";
			}
		} else {
			var style:any = document.getElementById("froalaBackgroundStyles");
			if (style === null) {
				style = document.createElement("style");
				style.id = "froalaBackgroundStyles";
				style.type = "text/css";
				document.getElementsByTagName("head")[0].appendChild(style);
			}
			//var style: any = document.createElement('style');
			//style.type = 'text/css';
			var newViewUniqueId = "tmpviewcolor" + uniqueId;
			style.innerHTML = "." + newViewUniqueId + " .fr-view { background-color: " + color + " !important; color: white; } " + "." + newViewUniqueId + " .editor-sectionicons-content-view { background-color: " + color + " !important; color: white; }";
			//document.getElementsByTagName('head')[0].appendChild(style);
			var currentFroalaView: any = document.querySelector("div.editor-sections-sectioncontent-container");
			if (currentFroalaView) {
				currentFroalaView.classList.remove(lastViewUniqueId);
				currentFroalaView.classList.add(newViewUniqueId);
				lastViewUniqueId = newViewUniqueId;
			}
		}
	}

	function switchSectionForReal(section: any) {
		setCurrentSectionKey(section.key);
		setCurrentSectionName(section.name);
		setCurrentSectionContent(section.content);
		if (ref && ref.current && ref.current.getElementsByClassName("fr-wrapper").length == 1) ref.current.getElementsByClassName("fr-wrapper")[0].scrollTop = 0;
		if (ref && ref.current && ref.current.getElementsByClassName("editor-sectionicons-content-view").length == 1) ref.current.getElementsByClassName("editor-sectionicons-content-view")[0].scrollTop = 0;
		setCurrentSectionIcon(section.icon);
		setCurrentSectionType(section.type);
		setCurrentSectionReadyForApproval(section.readyForApproval);
		setCurrentSectionApproved(section.approved);
		setCurrentSectionHideTitle(section.hideTitle);
		setCurrentSectionHideHeaderAndFooter(section.hideHeaderAndFooter);
		setCurrentSectionBackgroundColor(section.backgroundColor);
		if (section.lockedByID && section.lockedSince) {
			setCurrentSectionIsLocked(true);
			setCurrentSectionIsLockedByMe(section.lockedByID === getMyProfile().userID);
			setCurrentSectionLockedStatus("Being edited by " + (section.lockedByID === getMyProfile().userID ? "you" : section.lockedBy.fullName) + " since " + moment(section.lockedSince).fromNow());
		} else {
			if (section.key.indexOf("new_") === 0) {
				setCurrentSectionIsLocked(true);
				setCurrentSectionIsLockedByMe(true);
				setCurrentSectionLockedStatus("Being edited by you since now");
			} else {
				setCurrentSectionIsLocked(false);
				setCurrentSectionIsLockedByMe(false);
				setCurrentSectionLockedStatus("");
			}
		}
		if (section.type !== "Static" && section.type !== "Resumes") setCurrentSectionDynamicContent(section.dynamicContent);
		if (section.type == "Resumes") setCurrentSectionResumes(section.resumes);
		setFroalaEditorBackgroundColor(section.backgroundColor, section.key);
		changeHeight();
	}

	function clearSection() {
		setCurrentSectionKey("");
		setCurrentSectionName("");
		setCurrentSectionContent("");
		setCurrentSectionIcon("");
		setCurrentSectionType("");
		setCurrentSectionReadyForApproval(false);
		setCurrentSectionApproved(false);
		setCurrentSectionHideTitle(false);
		setCurrentSectionHideHeaderAndFooter(false);
		setCurrentSectionBackgroundColor("#ffffff");
		setFroalaEditorBackgroundColor("#ffffff", "");
		setCurrentSectionIsLocked(false);
		setCurrentSectionIsLockedByMe(false);
		setCurrentSectionLockedStatus("");
		setCurrentSectionDynamicContent(new Array());
		setCurrentSectionResumes(new Array());
	}

	function editSection() {
		repoProposals.lockSection(currentSectionKey).then(() => {
			setCurrentSectionIsLocked(true);
			setCurrentSectionIsLockedByMe(true);
			setCurrentSectionLockedStatus("Being edited by you since now");
			let activeSection = _.find(sections, { key: currentSectionKey });
			if (activeSection) {
				activeSection.lockedBy = {
					fullName: getMyProfile().firstName + " " + getMyProfile().lastName
				}
				activeSection.lockedByID = getMyProfile().userID;
				activeSection.lockedSince = moment().toDate();
			}
			setSections([...sections]);
			setFroalaEditorBackgroundColor(currentSectionBackgroundColor, currentSectionKey);
		});
	}

	function saveSection() {
		setSaveStatus("Saving...");
		showLoader();
		let activeSection = _.find(sections, { key: currentSectionKey });
		if (activeSection) {
			activeSection.name = currentSectionName;
			activeSection.content = currentSectionContent;
			activeSection.dynamicContent = currentSectionDynamicContent;
			activeSection.icon = currentSectionIcon;
			activeSection.hideTitle = currentSectionHideTitle;
			activeSection.landscape = record.landscape;
			activeSection.hideHeaderAndFooter = currentSectionHideHeaderAndFooter;
			activeSection.backgroundColor = currentSectionBackgroundColor;
			activeSection.lockedBy = undefined;
			activeSection.lockedByID = undefined;
			activeSection.lockedSince = undefined;
			activeSection.readyForApproval = false;
			if (currentSectionType === "Resumes") {
				repoProposals.saveSection({ ...activeSection, resumes: undefined }).then((sectionID) => {
					activeSection.id = sectionID;
					activeSection.key = sectionID;
					Promise.mapSeries(currentSectionResumes, (resume: any) => {
						if (resume.$state == "delete") {
							resume.$state = "deleted";
							return repoProposals.deleteResume(resume.id);
						}
						if (resume.$state == "new") {
							delete resume.$state;
							return repoProposals.saveResume({ ...resume, id: "00000000-0000-0000-0000-000000000000", proposalSectionID: sectionID, user: null }).then((resumeID) => {
								resume.id = resumeID;
								return resume.id;
							});
						}
						if (resume.$state == "edit") {
							delete resume.$state;
							return repoProposals.saveResume({ ...resume, proposalSectionID: sectionID });
						}
					}).then(() => {
						repoProposals.getSection(sectionID).then((section) => {
							activeSection.resumes = section.resumes === undefined ? new Array() : [..._.sortBy(section.resumes, ["sequence"]).map((resume: any) => {
								return {
									...resume,
									key: resume.userID,
									name: resume.user.fullName,
									imageURL: resume.user.image.size > 0 ? "/api/content/files/get/" + resume.user.image.internalFilename + "/" + resume.user.image.filename : userPlaceholder
								};
							})];
							activeSection.recordInfo = section.recordInfo;
							setCurrentSectionKey(activeSection.key);
							setCurrentSectionResumes(activeSection.resumes);
							setCurrentSectionIsLocked(false);
							setCurrentSectionIsLockedByMe(false);
							setCurrentSectionLockedStatus("");
							setCurrentSectionReadyForApproval(false);
							setSections([...sections]);
							hideLoader();
							setSaveStatus("Saved");
							setTimeout(() => {
								setSaveStatus("");
							}, 3000);
						});
					});
				});
			} else {
				repoProposals.saveSection(activeSection).then((sectionID) => {
					repoProposals.getSection(sectionID).then((section) => {
						activeSection.id = sectionID;
						activeSection.key = sectionID;
						activeSection.recordInfo = section.recordInfo;
						setCurrentSectionKey(activeSection.key);
						setCurrentSectionIsLocked(false);
						setCurrentSectionIsLockedByMe(false);
						setCurrentSectionLockedStatus("");
						setCurrentSectionReadyForApproval(false);
						setSections([...sections]);
						hideLoader();
						setSaveStatus("Saved");
						setTimeout(() => {
							setFroalaEditorBackgroundColor(currentSectionBackgroundColor, activeSection.key);
						}, 300);
						setTimeout(() => {
							setSaveStatus("");
						}, 3000);
					});
				});
			}
		} else {
			hideLoader();
			setSaveStatus("");
		}
	}

	function unlockSection() {
		confirm("Unlock section?", "Are you sure you want to unlock this section? The team member editing this section will loose his changes.", () => {
			repoProposals.unlockSection(currentSectionKey).then(() => {
				setCurrentSectionIsLocked(false);
				setCurrentSectionIsLockedByMe(false);
				setCurrentSectionLockedStatus("");
				let activeSection = _.find(sections, { key: currentSectionKey });
				if (activeSection) {
					activeSection.lockedBy = undefined;
					activeSection.lockedByID = undefined;
					activeSection.lockedSince = undefined;
				}
				setSections([...sections]);
			});
		}, "Yes, unlock", () => {
			// do nothing
		}, "Cancel");
	}

	function changeSectionContent(newContent: string) {
		setCurrentSectionContent(newContent);
	}

	function changeSectionName(event: any) {
		setCurrentSectionName(event.value);
	}

	function changeSectionHideTitle(event: any) {
		setCurrentSectionHideTitle(event.value);
	}

	function changeSectionHideHeaderAndFooter(event: any) {
		setCurrentSectionHideHeaderAndFooter(event.value);
	}

	function changeSectionBackgroundColor(color: any, event: any) {
		setFroalaEditorBackgroundColor(color.hex, currentSectionKey);
		setCurrentSectionBackgroundColor(color.hex);
	}

	function changeSectionReadyForApproval(event: any) {
		setSaveStatus("Saving...");
		setCurrentSectionReadyForApproval(event.value);
		if (event.value !== true) setCurrentSectionApproved(false);
		for (let i = 0; i < sections.length; i++) {
			let thisSection: any = sections[i];
			if (thisSection.key == currentSectionKey) {
				thisSection.readyForApproval = event.value;
				if (event.value !== true) thisSection.approved = false;
				setShowSendForReview(false);
				break;
			}
		}
		setSections([...sections]);
		repoProposals.readyForApprovalSection(currentSectionKey, event.value).then((result) => {
			let newRecord = { ...record };
			save(newRecord);
		});
	}

	function changeSectionApproved(event: any) {
		setSaveStatus("Saving...");
		setCurrentSectionApproved(event.value);
		for (let i = 0; i < sections.length; i++) {
			let thisSection: any = sections[i];
			if (thisSection.key == currentSectionKey) {
				thisSection.approved = event.value;
				setShowSendForReview(false);
				break;
			}
		}
		setSections([...sections]);
		repoProposals.approveSection(currentSectionKey, event.value).then((result) => {
			let newRecord = { ...record };
			save(newRecord);
		});
	}

	function approveAllSections() {
		leaveSection(() => {
			setSaveStatus("Saving...");
			setCurrentSectionApproved(true);
			for (let i = 0; i < sections.length; i++) {
				let thisSection: any = sections[i];
				thisSection.approved = true;
				setShowSendForReview(false);

			}
			setSections([...sections]);

			Promise.mapSeries(sections, (section) => {
				return repoProposals.approveSection(section.key, true);
			}).then((result) => {
				let newRecord = { ...record };
				save(newRecord);
				notify('All sections have been approved.');
			});
		});
	}

	function hideSectionIconSelector(e: any) {
		setIconSelectorVisible(false);
		window.removeEventListener('click', hideSectionIconSelector, false);
	}

	function changeSectionIcon() {
		if (isAllowed("EditSections") && currentSectionIsLockedByMe) {
			window.removeEventListener('click', hideSectionIconSelector, false);
			setIconSelectorVisible(!iconSelectorVisible);
			setTimeout(() => {
				window.addEventListener('click', hideSectionIconSelector, false);
			}, 400);
		}
	}

	function changeIcon(icon: string) {
		setCurrentSectionIcon(icon);
		setIconSelectorVisible(false);
	}

	function changeSectionExtraSettings() {
		if (isAllowed("EditSections") && currentSectionIsLockedByMe) {
			setSectionExtraSettingsVisible(!sectionExtraSettingsVisible);
		}
	}

	function newSection() {
		leaveSection(() => {
			var queryObject: Query = {
				languageID: record.languageID,
				businessUnitID: record.businessUnitID,
				landscape: record.landscape ? record.landscape : false,
				industryIDs: [ record.industryID ],
				serviceIDs: [ record.serviceID ]
			};
			show(NewSection, "sectionnew", {
				save: saveNewSections,
				cancel: () => { close("sectionnew"); },
				sectionQuery: queryObject,
				sectionTypes: sections.map((section: any) => {
					return {
						key: section.type == "Static" ? section.originalSectionId : section.type
					};
				}),
				showEmptySection: true
			});	
		});
	}

	function saveNewSections(newSectionTypes: any) {
		showLoader();
		Promise.mapSeries(newSectionTypes, (sectionType: any) => {
			if (sectionType.type === "Static" && sectionType.sectionID !== "") {
				return repoSections.get(sectionType.sectionID).then((section) => {
					return { 
						...sectionType, 
						content: section.content,
						hideTitle: section.hideTitle,
						hideHeaderAndFooter: section.hideHeaderAndFooter,
						backgroundColor: section.backgroundColor
					}
				});
			} else {
				return sectionType;
			}
		}).then((result) => {
			let sectionsLength = sections.length;
			setSections([...sections.concat(result.map((sectionType: any, index: number) => ({
				id: "00000000-0000-0000-0000-000000000000",
				key: "new_" + sectionType.key + Date.now().toString(),
				type: sectionType.type,
				proposalID: record.id,
				originalSectionId: sectionType.sectionID,
				name: sectionType.name,
				description: sectionType.description,
				icon: sectionType.icon,
				version: sectionType.version,
				content: sectionType.content,
				sequence: sectionsLength + index,
				required: false,
				dynamicContent: new Array(),
				resumes: new Array(),
				readyForApproval: false,
				approved: false,
				landscape: record.landscape,
				hideTitle: sectionType.hideTitle,
				hideHeaderAndFooter: sectionType.hideHeaderAndFooter,
				backgroundColor: sectionType.backgroundColor
			})))]);
			setShowSendForReview(false);
			close("sectionnew");		
			hideLoader();
		});
	}

	function dragStartSection(e: any, section: any) {
		if (isAllowed("EditSections")) {
			e.dataTransfer.setData('text/plain', section.id);
			setDraggedSection(section);
		}
	}

	function dragEndSection(e: any, section: any) {
		if (isAllowed("EditSections")) {
			e.preventDefault();
			if (section) {
				setSaveStatus("Saving...");
				let newSections: any[] = [...sections];

				newSections.splice(newSections.indexOf(section), 0, newSections.splice(newSections.indexOf(draggedSection), 1)[0]);
				setShowSendForReview(false);

				// @ts-ignore
				for (var i = 0; i < newSections.length; i++) {
					newSections[i].sequence = i;
				}
				setSections(newSections);
				// @ts-ignore
				setDraggedSection(null);
				// @ts-ignore
				setDraggedOverSection(null);

				let newSectionSequences = newSections.map((section: any, index: number) => {
					return { proposalSectionID: section.id, sequence: index };
				});
				repoProposals.changeSectionSequences(newSectionSequences).then(() => {
					setSaveStatus("Saved");
					setTimeout(() => {
						setSaveStatus("");
					}, 3000);
				});
			}
		}
	}

	function dragOverSection(e: any, section: any) {
		if (isAllowed("EditSections")) {
			setDraggedOverSection(section);
			e.stopPropagation();
			e.preventDefault();
		}
	}

	function dragStartEntry(e: any, entry: any) {
		if (isAllowed("EditSections")) {
			e.dataTransfer.setData('text/plain', entry.id);
			setDraggedEntry(entry);
		}
	}

	function dragEndEntry(e: any, entry: any) {
		if (isAllowed("EditSections")) {
			e.preventDefault();
			if (entry) {

				let newEntries: any[] = [...currentSectionDynamicContent];

				newEntries.splice(newEntries.indexOf(entry), 0, newEntries.splice(newEntries.indexOf(draggedEntry), 1)[0]);

				// @ts-ignore
				setCurrentSectionDynamicContent(newEntries);
				// @ts-ignore
				setDraggedEntry(null);
				// @ts-ignore
				setDraggedEntry(null);
			}
		}
	}

	function dragOverEntry(e: any, entry: any) {
		if (isAllowed("EditSections")) {
			setDraggedOverEntry(entry);
			e.stopPropagation();
			e.preventDefault();
		}
	}

	function dragStartResume(e: any, entry: any) {
		if (isAllowed("EditSections")) {
			e.dataTransfer.setData('text/plain', entry.id);
			setDraggedResume(entry);
		}
	}

	function dragEndResume(e: any, entry: any) {
		if (isAllowed("EditSections")) {
			e.preventDefault();
			if (entry) {

				let newEntries: any[] = [...currentSectionResumes];

				newEntries.splice(newEntries.indexOf(entry), 0, newEntries.splice(newEntries.indexOf(draggedResume), 1)[0]);
				for (let i = 0; i < newEntries.length; i++) {
					newEntries[i].sequence = i;
					if (newEntries[i].$state !== "delete") {
						newEntries[i].$state = newEntries[i].$state == "new" ? "new" : "edit";
					}
				}

				// @ts-ignore
				setCurrentSectionResumes(newEntries);
				// @ts-ignore
				setDraggedResume(null);
				// @ts-ignore
				setDraggedResume(null);
			}
		}
	}

	function dragOverResume(e: any, entry: any) {
		if (isAllowed("EditSections")) {
			setDraggedOverResume(entry);
			e.stopPropagation();
			e.preventDefault();
		}
	}

	function removeSection(index: number) {
		leaveSection(() => {
			confirm("Remove section?", "Do you really want to remove " + sections[index].name + "?", () => {
				if (sections[index].id !== "00000000-0000-0000-0000-000000000000") {
					showLoader();
					setSaveStatus("Saving...");
					repoProposals.deleteSection(sections[index].id).then((result) => {
						sections.splice(index, 1);
						let nextIndex = sections.length === index ? index - 1 : index;
						setSections([...sections]);
						setSaveStatus("Saved");
						if (sections.length > 0) {
							switchSection(sections[nextIndex]);
						} else {
							clearSection();
						}
						hideLoader();
					});
				} else {
					sections.splice(index, 1);
					let nextIndex = sections.length === index ? index - 1 : index;
					setSections([...sections]);
					if (sections.length > 0) {
						switchSection(sections[nextIndex]);
					} else {
						clearSection();
					}
				}
			}, "Yes", () => { }, "No");
			});
	}

	function newPublication() {
		var queryObject: any = {
			languageID: record.languageID,
			industryID: record.industryID,
			serviceID: record.serviceID
		};
		show(NewDynamicEntries, "publicationnew", {
			save: saveNewPublications,
			cancel: () => { close("publicationnew"); },
			query: queryObject,
			sectionType: "Publications",
			exclude: currentSectionDynamicContent.map((entry: any) => {
				return { key: entry.key }
			})
		});
	}

	function saveNewPublications(publications: Array<any>) {
		let newDynamicContent = currentSectionDynamicContent.concat(publications.map((publication: any) => {
			return {
				key: publication.id,
				externalLink: publication.externalLink,
				internalImageName: publication.image.internalFilename,
				externalImageName: publication.image.filename,
				title: publication.title,
				description: publication.description
			}
		}));
		setCurrentSectionDynamicContent([...newDynamicContent]);
		close("publicationnew");
	}

	function removePublication(index: number) {
		confirm("Remove publication?", "Do you really want to remove " + currentSectionDynamicContent[index].title + "?", () => {
			currentSectionDynamicContent.splice(index, 1);
			setCurrentSectionDynamicContent([...currentSectionDynamicContent]);
		}, "Yes", () => { }, "No");
	}

	function newLegalDocument() {
		var queryObject: any = {
			languageID: record.languageID,
			businessUnitID: record.businessUnitID,
		};
		show(NewDynamicEntries, "legaldocumentnew", {
			save: saveNewLegalDocuments,
			cancel: () => { close("legaldocumentnew"); },
			query: queryObject,
			sectionType: "LegalDocuments",
			exclude: currentSectionDynamicContent.map((entry: any) => {
				return { key: entry.key }
			})
		});
	}

	function saveNewLegalDocuments(legalDocuments: any) {
		let newDynamicContent = currentSectionDynamicContent.concat(legalDocuments.map((legalDocument: any) => {
			return {
				key: legalDocument.id,
				internalFileName: legalDocument.id,
				externalFileName: legalDocument.document.filename,
				title: legalDocument.title
			}
		}));
		setCurrentSectionDynamicContent([...newDynamicContent]);
		close("legaldocumentnew");
	}

	function removeLegalDocument(index: number) {
		confirm("Remove legal document?", "Do you really want to remove " + currentSectionDynamicContent[index].title + "?", () => {
			currentSectionDynamicContent.splice(index, 1);
			setCurrentSectionDynamicContent([...currentSectionDynamicContent]);
		}, "Yes", () => { }, "No");
	}

	function newAttachment() {
		var queryObject: any = {
			languageID: record.languageID,
			businessUnitID: record.businessUnitID,
		};
		show(NewAttachment, "newattachment", {
			save: saveNewAttachment,
			cancel: () => { close("newattachment"); },
		});
	}

	function saveNewAttachment(attachment: any) {
		showLoader();
		var formData = new FormData();
		let file = attachment.file.getRawFile();
		formData.append("attachment_file", file);
		repoProposals.saveAttachment(formData).then(result => {
			let newDynamicContent = currentSectionDynamicContent.concat([
				{
					key: attachment.key,
					description: attachment.description,
					internalFileName: result.internalFileName,
					externalFileName: result.externalFileName,
					fileSize: result.fileSize,
					fileType: result.fileType
				}
			]);
			setCurrentSectionDynamicContent([...newDynamicContent]);
			close("newattachment");
			hideLoader();
		}).catch((error) => {
			hideLoader();
			close("newattachment");
			alert(error);
		});
	}

	function removeAttachment(index: number) {
		confirm("Remove attachment?", "Do you really want to remove " + currentSectionDynamicContent[index].description + "?", () => {
			currentSectionDynamicContent.splice(index, 1);
			setCurrentSectionDynamicContent([...currentSectionDynamicContent]);
		}, "Yes", () => { }, "No");
	}

	function newCredential() {
		var queryObject: any = {
			languageID: record.languageID,
			businessUnitID: record.businessUnitID,
			industryID: record.industryID,
			serviceID: record.serviceID
		};
		show(NewDynamicEntries, "credentialnew", {
			save: saveNewCredentials,
			cancel: () => { close("credentialnew"); },
			query: queryObject,
			sectionType: "Credentials",
			exclude: currentSectionDynamicContent.map((entry: any) => {
				return { key: entry.key }
			})
		});
	}

	function saveNewCredentials(credentials: any) {
		let newDynamicContent = currentSectionDynamicContent.concat(credentials.map((credential: any) => {
			return {
				key: credential.id,
				internalImageName: credential.logo.internalFilename,
				externalImageName: credential.logo.filename,
				customer: credential.customer,
				shortDescription: credential.shortDescription,
				longDescription: credential.longDescription
			}
		}));
		setCurrentSectionDynamicContent([...newDynamicContent]);
		close("credentialnew");
	}

	function removeCredential(index: number) {
		confirm("Remove credential?", "Do you really want to remove " + currentSectionDynamicContent[index].customer + "?", () => {
			currentSectionDynamicContent.splice(index, 1);
			setCurrentSectionDynamicContent([...currentSectionDynamicContent]);
		}, "Yes", () => { }, "No");
	}

	function newAchievement() {
		var queryObject: any = {
			languageID: record.languageID,
			businessUnitID: record.businessUnitID,
			industryID: record.industryID,
			serviceID: record.serviceID
		};
		show(SearchAchievements, "achievementsearch", {
			save: saveNewAchievements,
			cancel: () => { close("achievementsearch"); },
			query: queryObject,
			sectionType: "Achievements",
			exclude: currentSectionDynamicContent.map((entry: any) => {
				return { key: entry.key }
			})
		});
	}

	function saveNewAchievements(achievements: any) {
		let newDynamicContent = currentSectionDynamicContent.concat(achievements.map((achievement: any) => {
			return {
				key: achievement.id,
				clientName: achievement.clientName,
				governementLevel: achievement.governementLevel,
				projectName: achievement.projectName,
				projectType: achievement.projectType,
				policyArea: achievement.policyArea,
				shortDescription: achievement.shortDescription,
				description: achievement.description,
				budget: formatMoney(achievement.budget, achievement.currency.split(',')[0], achievement.currency.split(',')[1]),
				numberOfInvolvedStaff: achievement.numberOfInvolvedStaff,
				period: moment(achievement.periodStart).format("MM/YYYY") + " - " + moment(achievement.periodEnd).format("MM/YYYY"),
				contactClient: achievement.contactClient,
				projectPartner: achievement.projectPartner.fullName,
				projectManager: achievement.projectManager.fullName,
				subcontractorsPartners: achievement.subcontractorsPartners === true ? "% Budget by candidate: " + achievement.percentBudget + "%<br />Details:<br />" + achievement.subcontractorDetails.replace(/\n/g, '<br />') : "No",
			}
		}));
		setCurrentSectionDynamicContent([...newDynamicContent]);
		close("achievementnew");
		close("achievementsearch");
	}

	function removeAchievement(index: number) {
		confirm("Remove achievement?", "Do you really want to remove " + currentSectionDynamicContent[index].clientName + "?", () => {
			currentSectionDynamicContent.splice(index, 1);
			setCurrentSectionDynamicContent([...currentSectionDynamicContent]);
		}, "Yes", () => { }, "No");
	}

	function saveNewResume(resume: any) {
		showLoader();
		repoUserResumes.getAllForUser(resume.userID).then((resumes: any) => {
			// @ts-ignore
			let foundResume = _.find(resumes, { languageID: record.languageID });
			let resumeContent = "";
			if (foundResume) resumeContent = foundResume.content;
			currentSectionResumes.push({
				$state: "new",
				key: resume.userID,
				userID: resume.userID,
				name: resume.fullName,
				user: resume,
				imageURL: resume.image.size > 0 ? "/api/content/files/get/" + resume.image.internalFilename + "/" + resume.image.filename : userPlaceholder,
				function: resume.function,
				resume: resumeContent == "" ? "Please fill in your resumé" : resumeContent,
				ready: false,
				validated: false
			});
			setCurrentSectionReadyForApproval(false);
			setCurrentSectionApproved(false);
			setCurrentSectionResumes([...currentSectionResumes]);
			close("newresume");
			hideLoader();

		});
	}

	function removeResume(index: number) {
		// @ts-ignore
		let currentResume = _.reject(currentSectionResumes, { $state: "delete" })[index];
		confirm("Remove resumé?", "Do you really want to remove the resumé for " + currentResume.name + "?", () => {
			if (currentResume.$state == "new") {
				currentSectionResumes.splice(index, 1);
			} else {
				currentResume.$state = "delete";
			}
			let isReadyForApproval = true;
			let isApproved = true;
			for (let i = 0; i < currentSectionResumes.length; i++) {
				if (currentSectionResumes[i].$state !== "delete") {
					if (currentSectionResumes[i].ready !== true) {
						isReadyForApproval = false;
					}
					if (currentSectionResumes[i].validated !== true) {
						isApproved = false;
					}
				}
			}
			setCurrentSectionResumes([...currentSectionResumes]);
			setCurrentSectionReadyForApproval(isReadyForApproval);
			setCurrentSectionApproved(isApproved);
		}, "Yes", () => { }, "No");
	}

	function editResume(index: number) {
		// @ts-ignore
		let resumeToEdit = _.reject(currentSectionResumes, { $state: "delete" })[index];
		show(EditResume, "editresume", {
			save: (resume: any) => {
				resumeToEdit.$state = resumeToEdit.$state == "new" ? "new" : "edit";
				resumeToEdit.function = resume.function;
				resumeToEdit.resume = resume.resume;
				setCurrentSectionApproved(false);
				close("editresume");
			},
			cancel: () => {
				close("editresume");
			},
			dataItem: {
				userID: resumeToEdit.key,
				fullName: resumeToEdit.name,
				function: resumeToEdit.function,
				resume: resumeToEdit.resume
			}
		})
	}

	function changeResumeApproved(index: number, checked: boolean | undefined) {
		currentSectionResumes[index].$state = currentSectionResumes[index].$state == "new" ? "new" : "edit";
		if (checked) currentSectionResumes[index].ready = true;
		currentSectionResumes[index].validated = checked;
		let isReadyForApproval = true;
		let isApproved = true;
		for (let i = 0; i < currentSectionResumes.length; i++) {
			if (currentSectionResumes[i].$state !== "delete") {
				if (currentSectionResumes[i].ready !== true) {
					isReadyForApproval = false;
				}
				if (currentSectionResumes[i].validated !== true) {
					isApproved = false;
				}
			}
		}
		setCurrentSectionReadyForApproval(isReadyForApproval);
		setCurrentSectionApproved(isApproved);
	}

	function changeResumeReadyForApproval(index: number, checked: boolean | undefined) {
		currentSectionResumes[index].$state = currentSectionResumes[index].$state == "new" ? "new" : "edit";
		currentSectionResumes[index].ready = checked;
		if (checked) currentSectionResumes[index].validated = false;
		let isReadyForApproval = true;
		let isApproved = true;
		for (let i = 0; i < currentSectionResumes.length; i++) {
			if (currentSectionResumes[i].$state !== "delete") {
				if (currentSectionResumes[i].ready !== true) {
					isReadyForApproval = false;
				}
				if (currentSectionResumes[i].validated !== true) {
					isApproved = false;
				}
			}
		}
		setCurrentSectionReadyForApproval(isReadyForApproval);
		setCurrentSectionApproved(isApproved);
	}

	const statusMessage = (proposalstatus: string) => {
		let status = "";
		if (proposalstatus == "InProgress") status = "In progress";
		if (proposalstatus == "ReadyForReview") status = "Ready for review";
		if (proposalstatus == "InReview") status = "In review";
		if (proposalstatus == "ReadyToSend") status = "Ready to send";
		if (proposalstatus == "Sending") status = "Sending";
		if (proposalstatus == "Defend") status = "Defend";
		if (proposalstatus == "Won") status = "Won";
		if (proposalstatus == "Lost") status = "Lost";
		return status;
	};

	const isAllowed = (item: string) => {
		if (!isSigningPartner && (item == "ApproveSection" || item == "WinLostBack" || item == "SendProposal")) return false;
		if (!isSigningPartner && !isCreator && !isElevatedContributor && (proposalStatus == "InProgress" || proposalStatus == "ReadyForReview") && (item == "EditSections" || item == "ReadyForApprovalSection")) return true;
		if ((isCreator || isElevatedContributor) && !isSigningPartner && proposalStatus != "InProgress" && proposalStatus != "ReadyForReview") return false;
		if (isSigningPartner && (proposalStatus == "Lost" || proposalStatus == "Won" || proposalStatus == "Sending")) return false;
		if (isCreator) return true;
		if (isElevatedContributor) return true;
		if (isSigningPartner) {
			if (proposalStatus == "Defend") {
				return item == "EditContacts" || item == "WinLostBack";
			}
			return true;
		}
		return false;
	}

	function toggleColorPicker() {
		setSectionExtraSettingsVisible(true);
		setColorPickerVisible(!colorPickerVisible);
	}

	return (
		<div className="editor-container">
			<div className="editor-innercontainer">
				<div className="editor-close" onClick={closeEditor}><i className="deloitte-icon icon-cross_special" aria-hidden="true"></i></div>
				<h1 className="editor-h1"><span>{getMyProfile().firstName}</span>, let's make an impact for {record.companyName}!</h1>
				<ul className="editor-ul">
					<li className={setClasses(0)}>
						<a onClick={() => { changeTab(0); }}>
							<div className="editor-icon"><i className="deloitte-icon icon-slider_special" aria-hidden="true"></i></div>
							<div className="editor-steplabel">{translations.proposalwizard_steps_general}</div>
						</a>
					</li>
					<li className={setClasses(1)}>
						<a onClick={() => { changeTab(1); }}>
							<div className="editor-icon"><i className="deloitte-icon icon-profile_2_special" aria-hidden="true"></i></div>
							<div className="editor-steplabel">{translations.proposalwizard_steps_team}</div>
						</a>
					</li>
					<li className={setClasses(2)}>
						<a onClick={() => { changeTab(2); }}>
							<div className="editor-icon"><i className="deloitte-icon icon-edit_profile_special" aria-hidden="true"></i></div>
							<div className="editor-steplabel">{translations.proposalwizard_steps_contacts}</div>
						</a>
					</li>
					<li className={setClasses(3)}>
						<a onClick={() => { changeTab(3); }}>
							<div className="editor-icon"><i className="deloitte-icon icon-options_special" aria-hidden="true"></i></div>
							<div className="editor-steplabel">{translations.proposalwizard_steps_sections}</div>
						</a>
					</li>
					<li className={setClasses(4)}>
						<a onClick={() => { changeTab(4); }}>
							<div className="editor-icon"><i className="deloitte-icon icon-file_edit_special" aria-hidden="true"></i></div>
							<div className="editor-steplabel">{translations.proposalwizard_steps_letter}</div>
						</a>
					</li>
				</ul>
				<div className="tab-bar-container">
					<div className={selectedTab === 0 ? "tab-fixed-height" : "tab-fixed-height hidden"}>
						<h2 className="editor-h2">{translations.proposalwizard_general_title}</h2>
						<div className="tab-fixed-height-content">
							<form className="k-form">
								<div className="row">
									<div className="col-md-9">
										<label className="k-form-field">
											<span>{translations.proposalwizard_opportunity}</span>
											<Input readOnly={false} name="sapReference" style={{ width: "100%" }} value={(record && record.sapReference ? record.sapReference : "")} />
										</label>
										<label className="k-form-field">
											<span>{translations.proposalwizard_customer}</span>
											<Input readOnly={false} name="companyName" style={{ width: "100%" }} value={(record && record.companyName ? record.companyName : "")} />
										</label>
										<label className="k-form-field">
											<span>{translations.proposalwizard_address}</span>
											<Input readOnly={false} name="companyAddress" style={{ width: "100%" }} value={(record && record.companyAddress ? record.companyAddress : "")} />
										</label>
									</div>
									<div className="col-md-3">
										<div className={record.coverImageID == "00000000-0000-0000-0000-000000000000" ? "coverimages-selectedimage isrequired" : "coverimages-selectedimage"} onClick={selectCoverImage} style={{ backgroundColor: record.coverImage.backgroundColor == "Black" ? "black" : "transparent" }} ><span className={record.coverImageID == "00000000-0000-0000-0000-000000000000" ? "deloitte-icon icon-download_from_cloud_special" : "hidden"}></span>
											{record.coverImageID != "00000000-0000-0000-0000-000000000000" && (
												<img src={"/api/content/files/get/" + record.coverImage.image.internalFilename + "/" + record.coverImage.image.filename} className={"img-responsive coveriamges-imagepicker"} alt={record.coverImage.name} />
											)}
										</div>
										{coverImageSelectorVisible && (
											<div className="coverimages-popup">
												<div className="coverimages-arrow"></div>
												<div className="coverimages-container">
													{coverImages.map((coverImage: any) => (
														<div className="coverimages-imageblock" key={coverImage.id} onClick={() => {
															changeCoverImage(coverImage);
														}}>
															<img src={"/api/content/files/get/" + coverImage.image.internalFilename + "/" + coverImage.image.filename} className="img-responsive" alt={coverImage.name} />
														</div>
													))}
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<label className="k-form-field">
											<span>{translations.proposalwizard_titlefield}</span>
											<Input readOnly={!isAllowed("GeneralField")} name="title" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + translations.proposalwizard_titlefield + "..."} value={(record && record.title ? record.title : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " " + translations.proposalwizard_titlefield} />
										</label>
									</div>
									<div className="col-md-6">
										<label className="k-form-field">
											<span>{translations.proposalwizard_subtitle}</span>
											<Input readOnly={!isAllowed("GeneralField")} name="subtitle" style={{ width: "100%" }} placeholder={translations.general_placeholder_prefix + " " + translations.proposalwizard_subtitle + "..."} value={(record && record.subtitle ? record.subtitle : "")} onChange={onChange} required={true} validationMessage={translations.general_validationmessage + " " + translations.proposalwizard_subtitle} />
										</label>
									</div>
								</div>
								<div className="row">
									<div className="col-md-5">
										<label className="k-form-field">
											<span>{translations.proposalwizard_signingpartner}</span>
											<ComboBox
												data={partners}
												name="signingPartnerID"
												style={{ width: "100%" }}
												dataItemKey="userID"
												textField="fullName"
												value={record.signingPartner}
												onChange={onChange}
												required={true}
												suggest={true}
												disabled={!isAllowed("GeneralField")}
											/>
										</label>
									</div>
									<div className="col-md-5">
										<label className="k-form-field">
											<span>{translations.proposalwizard_engagementpartner}</span>
											<ComboBox
												data={partners}
												name="engagementPartnerID"
												style={{ width: "100%" }}
												dataItemKey="userID"
												textField="fullName"
												value={record.engagementPartner}
												onChange={onChange}
												required={true}
												suggest={true}
												disabled={!isAllowed("GeneralField")}
											/>
										</label>
									</div>
									<div className="col-md-2">
										<label className="k-form-field">
											<span>{translations.proposalwizard_expectedrevenue}</span>
											<NumericTextBox disabled={!isAllowed("GeneralField")} name="expectedRevenue" width={"100%"} className={record && record.expectedRevenue > 0 ? " " : "k-state-invalid"} placeholder={translations.general_placeholder_prefix + " " + translations.proposalwizard_expectedrevenue + "..."} value={(record && record.expectedRevenue ? record.expectedRevenue : 0)} onChange={onChange} />
										</label>
									</div>
								</div>
								<div className="row">
									<div className="col-md-4">
										<label className="k-form-field">
											<span>{translations.proposalwizard_language}</span>
											<Input readOnly={true} name="languageName" style={{ width: "100%" }} value={(record && record.language && record.language.name ? record.language.name : "")} />
										</label>
									</div>
									<div className="col-md-3">
										<label className="k-form-field">
											<span>{translations.proposalwizard_proposaldate}</span>
											<DatePicker disabled={!isAllowed("GeneralField")} name="proposalDate" width={"100%"} value={(record && record.proposalDate ? record.proposalDate : new Date())} onChange={onChange} format="yyyy/MM/dd" />
										</label>
									</div>
									<div className="col-md-3">
										<label className="k-form-field">
											<span>{translations.proposalwizard_deadlinedate}</span>
											<DatePicker disabled={!isAllowed("GeneralField")} name="deadlineDate" width={"100%"} value={(record && record.deadlineDate ? record.deadlineDate : new Date())} onChange={onChange} format="yyyy/MM/dd" />
										</label>
									</div>
									<div className="col-md-2">
										<label className="k-form-field">
											<span>{translations.general_orientation}</span>
											{/*
											// @ts-ignore*/}
											<Switch name="landscape" disabled={true} checked={record.landscape} onLabel="Landscape" offLabel="Portrait" className="page-orientation-switch"></Switch>
										</label>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<label className="k-form-field">
											<span>{translations.proposalwizard_industry}</span>
											<Input readOnly={true} name="industryName" style={{ width: "100%" }} value={(record && record.industry && record.industry.name ? record.industry.name : "")} />
										</label>
									</div>
									<div className="col-md-6">
										<label className="k-form-field">
											<span>{translations.proposalwizard_service}</span>
											<Input readOnly={true} name="serviceName" style={{ width: "100%" }} value={(record && record.service && record.service.name ? record.service.name : "")} />
										</label>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div className={selectedTab === 1 ? "tab-fixed-height" : "tab-fixed-height hidden"}>
						<h2 className="editor-h2">{translations.proposalwizard_team_title}</h2>
						<div className="editor-gridpanel">
							{selectedTab === 1 ? <GridPanel reloadKey="reloadTeam" loadData={getTeamMembers} filterable={false} sortable={false} key={teamMembers.length} pageable={false}>
								<GridToolbar >
									{isAllowed("EditTeamMembers") && (
										<div>
											<DeloitteButton title="Add new" onClick={() => {
												show(NewTeamMember, "newteammember", {
													save: saveNewTeamMember,
													cancel: () => {
														close("newteammember");
													}
												});
											}}>
												{translations.proposalwizard_team_addnew}
											</DeloitteButton>
											<DeloitteButton title="Notify contributors" onClick={() => {
												repoProposals.notifyPursuitTeam(record.id).then(() => {
													notify("Notifications will be sent!");
												});
											}}>
												Notify contributors
											</DeloitteButton>
										</div>
									)}
								</GridToolbar>
								<GridColumn field="user.firstName" title={translations.users_firstname} editable={false} />
								<GridColumn field="user.lastName" title={translations.users_lastname} editable={false} />
								<GridColumn field="user.function" title={translations.users_funtion} editable={false} />
								<GridColumn field="user.phone" title={translations.myprofile_phone} editable={false} />
								<GridColumn field="role" title={translations.roles_windowtitlesingle} editable={false} />
								{isAllowed("EditTeamMembers") && (
									<GridColumn filterable={false} width="120px" editable={false} title="Remove" cell={cellButton(removeTeamMember, "Remove", (rowRecord: any) => {
										return rowRecord.type !== "contributor"
									})} />
								)}
							</GridPanel> : null}
						</div>
					</div>
					<div className={selectedTab === 2 ? "tab-fixed-height" : "tab-fixed-height hidden"}>
						<h2 className="editor-h2">{translations.proposalwizard_contacts_title}</h2>
						<div className="editor-gridpanel">
							{selectedTab === 2 ? <GridPanel reloadKey="reloadContacts" loadData={getContacts} filterable={false} sortable={false} key={contacts.length} pageable={false}>
								<GridToolbar >
									{isAllowed("EditContacts") && (
										<div>
											<DeloitteButton title="Add new" onClick={() => {
												show(NewContact, "newcontact", {
													save: saveNewContact,
													cancel: () => {
														close("newcontact");
													}
												});
											}}>
												{translations.proposalwizard_contacts_addnew}
											</DeloitteButton>
										</div>
									)}
								</GridToolbar>
								<GridColumn field="salutation" title="Salutation" editable={false} />
								<GridColumn field="firstName" title={translations.users_firstname} editable={false} />
								<GridColumn field="lastName" title={translations.users_lastname} editable={false} />
								<GridColumn field="email" title={translations.users_email} editable={false} />
								<GridColumn field="mobile" title={translations.myprofile_mobile} editable={false} />
								{isAllowed("EditContacts") && (
									<GridColumn filterable={false} width="120px" editable={false} title="Remove" cell={cellButton(removeContact, "Remove")} />
								)}
							</GridPanel> : null}
						</div>
					</div>
					<div className={selectedTab === 3 ? "tab-fixed-height" : "tab-fixed-height hidden"}>
						<h2 className="editor-h2">Sections {isAllowed("AddSections") && (<div className="editor-sections-addsection" onClick={newSection}><span>+</span></div>)}  {(proposalStatus == "ReadyForReview" && isAllowed("ApproveSection")) && (<div className="editor-sections-approveallsections" onClick={approveAllSections}><span>Approve all</span></div>)}</h2>
						<div className="editor-sections-sectionslisting-container">
							<div className="editor-sections-sectionslisting">
								{
									sections.map((section: any, index: number) => (
										<div key={section.key} className={"editor-sections-preview-row" + (section.key == currentSectionKey ? " active" : "") + (draggedOverSection && section.id === draggedOverSection.key ? " dragged-over" : "")} onClick={() => { switchSection(section); }} draggable={isAllowed("EditSections")} onDragStart={(e) => { dragStartSection(e, section); }} onDrop={(e) => { dragEndSection(e, section); }} onDragOver={(e) => { dragOverSection(e, section); }}>
											<div className={"editor-sections-preview-state"} style={{
												backgroundColor: section.approved ? "#86bc25" : section.readyForApproval ? "#ffA500" : "#ff0000"
											}}></div>
											<div className={"editor-sections-preview-icon " + section.icon} style={{color: arrColors[index]}}></div>
											<div className="editor-sections-preview-description">{section.name}</div>
											{!section.required && isAllowed("RemoveSections") && !section.lockedByID && (<div className="editor-sections-preview-delete" onClick={() => { removeSection(index); }}><span className="deloitte-icon icon-bin_special"></span></div>)}
											{section.lockedByID && section.lockedByID !== getMyProfile().userID && (<div className="editor-sections-preview-locked"><span className="deloitte-icon icon-lock_special"></span></div>)}
											{section.lockedByID && section.lockedByID === getMyProfile().userID && (<div className="editor-sections-preview-editing"><span className="deloitte-icon icon-file_edit_special"></span></div>)}
										</div>
									))
								}
							</div>
						</div>
						<div ref={ref} className="editor-sections-sectioncontent-container">
							{currentSectionKey !== "" && (
								<div className="editor-sections-container">
									<div className="editor-sections-container-top">
										<div className="row">
											<div className="col-md-10 editor-sections-name-container">
												<Input readOnly={!isAllowed("EditSections") || !currentSectionIsLockedByMe} style={{ width: "100%" }} maxLength={50} className={"editor-sections-name " + (!isAllowed("EditSections") || currentSectionIsLocked === false || (currentSectionIsLocked && currentSectionIsLockedByMe === false) ? "readonly" : "")} value={currentSectionName} onChange={changeSectionName} required={true} />
												<div className="editor-sections-name-approvals">
													{ isAllowed("EditSections") && currentSectionIsLocked === false && (<div className="editor-sections-editsection" onClick={() => preventDoubleClick(editSection)}><span className="deloitte-icon icon-file_edit_special"></span> Edit</div>)}
													{ isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-sections-savesection" onClick={() => preventDoubleClick(saveSection)}><span className="deloitte-icon icon-upload_to_cloud_special"></span> Save</div>)}
													{ isAllowed("EditSections") && currentSectionIsLocked && currentSectionIsLockedByMe === false && (isCreator || isSigningPartner || isElevatedContributor) && (<div className="editor-sections-unlocksection" onClick={() => preventDoubleClick(unlockSection)}><span className="deloitte-icon icon-unlock_special"></span> Unlock</div>)}
													<div className="editor-sections-name-approvals-switch"><Switch checked={currentSectionReadyForApproval} onChange={changeSectionReadyForApproval} disabled={currentSectionType == "Resumes" || !isAllowed("ReadyForApprovalSection") || currentSectionIsLocked}></Switch><span>Ready for approval</span></div>
													<div className="editor-sections-name-approvals-switch"><Switch checked={currentSectionApproved} onChange={changeSectionApproved} disabled={currentSectionType == "Resumes" || !currentSectionReadyForApproval || !isAllowed("ApproveSection") || currentSectionIsLocked}></Switch><span>Approved</span></div>
													{ currentSectionIsLocked && (<div className="editor-sections-name-approvals-switch">
														<div className="editor-sections-extrasettings" onClick={changeSectionExtraSettings}><i className="deloitte-icon icon-slider_special" aria-hidden="true"></i></div>
															{sectionExtraSettingsVisible && (
																<div className="editor-sectionextrasettings-popup">
																	<div className="editor-sectionextrasettings-close" onClick={changeSectionExtraSettings}><i className="deloitte-icon icon-cross_special" aria-hidden="true"></i></div>
																	<div className="editor-sectionextrasettings-container">
																		<div className="editor-sectionextrasettings-title">Extra Settings</div>
																		<form className="k-form">
																			<div className="row">
																				<div className="col-md-4">
																					<label className="k-form-field">
																						<span>{translations.sections_hidetitle}</span>
																						{/*
																						// @ts-ignore*/}
																						<Switch name="currenSectionHideTitle" checked={currentSectionHideTitle} onChange={changeSectionHideTitle}></Switch>
																					</label>
																				</div>
																				<div className="col-md-8">
																					<label className="k-form-field">
																						<span>{translations.sections_hideHeaderAndFooter}</span>
																						{/*
																						// @ts-ignore*/}
																						<Switch name="currenSectionHideHeaderAndFooter" checked={currentSectionHideHeaderAndFooter} onChange={changeSectionHideHeaderAndFooter}></Switch>
																					</label>
																				</div>
																			</div>
																			<div className="row">
																				<div className="col-md-12">
																					<label className="k-form-field">
																						<span>{translations.coverimages_backgroundcolor}</span>
																						<div>
																							<div className="general-colorpicker-swatch" onClick={toggleColorPicker}>
																								<div className="general-colorpicker-color" style={{ backgroundColor: currentSectionBackgroundColor }}></div>
																							</div>
																							{
																								colorPickerVisible ? <div className="general-colorpicker-popover">
																									<div className="general-colorpicker-cover" onClick={toggleColorPicker} />
																									<CirclePicker color={currentSectionBackgroundColor} onChangeComplete={changeSectionBackgroundColor} colors={["#ffffff", "#86BC25", "#26890D", "#0D8390", "#007CB0"]} />
																								</div> : null
																							}
																						</div>
																					</label>
																				</div>
																			</div>
																		</form>
																	</div>
																</div>
															)}
														</div>) }
												</div>
											</div>
											<div className="col-md-2">
												<div className={currentSectionIsLockedByMe ? "editor-sectionicons-selectedicon" : "editor-sectionicons-selectedicon-readonly"} onClick={changeSectionIcon}><span className={currentSectionIcon}></span></div>
												{iconSelectorVisible && (
													<div className="editor-sectionicons-popup">
														<div className="editor-sectionicons-arrow"></div>
														<div className="editor-sectionicons-container">
															{sectionIcons.map((icon: any) => (
																<div className="editor-sectionicons-icon" key={icon.class} onClick={() => {
																	changeIcon(icon.class);
																}}>
																	<span className={icon.class} title={icon.name}></span>
																</div>
															))}
														</div>
													</div>
												)}
											</div>
										</div>
										{ currentSectionIsLocked && (<div className="editor-sections-lockedstatus">{currentSectionLockedStatus}</div>)}
									</div>
									{ currentSectionType == "Static" && (
										<div className="row editor-sectionicons-content-container">
											<div className="col-md-12">
												{isAllowed("EditSections") && currentSectionIsLockedByMe && (<FroalaEditorComponent config={editorSectionConfig} model={currentSectionContent} onModelChange={changeSectionContent}></FroalaEditorComponent>)}
												{(!isAllowed("EditSections") || !currentSectionIsLockedByMe) && (<div className="editor-sectionicons-content-view"><FroalaEditorView model={currentSectionContent}></FroalaEditorView></div>)}
											</div>
										</div>
									)}
									{ currentSectionType == "Publications" && (
										<div className="editor-sectionicons-content-container-dynamic">
											{isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-sectionicons-content-container-top">
												<DeloitteButton title={translations.general_addnew} onClick={newPublication} >
													{translations.general_addnew}
												</DeloitteButton>
											</div>)}
											<div className="editor-sectionicons-content-container-listing">
												{
													currentSectionDynamicContent.map((publication: any, index: number) => (
														<div key={publication.key} className={"row editor-dynamicsections-general-entry" + (draggedOverEntry && publication.key === draggedOverEntry.key ? " dragged-over" : "")} draggable={isAllowed("EditSections") && currentSectionIsLockedByMe} onDragStart={(e) => { dragStartEntry(e, publication); }} onDrop={(e) => { dragEndEntry(e, publication); }} onDragOver={(e) => { dragOverEntry(e, publication); }}>
															<div className="col-md-2">
																<img src={"/api/content/files/get/" + publication.internalImageName + "/" + publication.externalImageName} className="img-responsive"></img>
															</div>
															<div className="col-md-9">
																<h3>{publication.title}</h3>
																<p dangerouslySetInnerHTML={{__html: publication.description}}></p>
															</div>
															<div className="col-md-1">
																{isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-dynamicsections-general-delete" onClick={() => { removePublication(index); }}><span className="deloitte-icon icon-bin_special"></span></div>)}
															</div>
														</div>
													))
												}
											</div>
										</div>
									)}
									{currentSectionType == "LegalDocuments" && (
										<div className="editor-sectionicons-content-container-dynamic">
											{isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-sectionicons-content-container-top">
												<DeloitteButton title={translations.general_addnew} onClick={newLegalDocument} >
													{translations.general_addnew}
												</DeloitteButton>
											</div>)}
											<div className="editor-sectionicons-content-container-listing">
												{
													currentSectionDynamicContent.map((legalDocument: any, index: number) => (
														<div key={legalDocument.key} className={"row editor-dynamicsections-general-entry" + (draggedOverEntry && legalDocument.key === draggedOverEntry.key ? " dragged-over" : "")} draggable={isAllowed("EditSections") && currentSectionIsLockedByMe} onDragStart={(e) => { dragStartEntry(e, legalDocument); }} onDrop={(e) => { dragEndEntry(e, legalDocument); }} onDragOver={(e) => { dragOverEntry(e, legalDocument); }}>
															<div className="col-md-2">
																<img src={pdfImage} className="img-responsive"></img>
															</div>
															<div className="col-md-9">
																<h3>{legalDocument.title}</h3>
															</div>
															<div className="col-md-1">
																{isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-dynamicsections-general-delete" onClick={() => { removeLegalDocument(index); }}><span className="deloitte-icon icon-bin_special"></span></div>)}
															</div>
														</div>
													))
												}
											</div>
										</div>
									)}
									{currentSectionType == "Attachments" && (
										<div className="editor-sectionicons-content-container-dynamic">
											{isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-sectionicons-content-container-top">
												<DeloitteButton title={translations.general_addnew} onClick={newAttachment} >
													{translations.general_addnew}
												</DeloitteButton>
											</div>)}
											<div className="editor-sectionicons-content-container-listing">
												{
													currentSectionDynamicContent.map((attachment: any, index: number) => (
														<div key={attachment.key} className={"row editor-dynamicsections-general-entry" + (draggedOverEntry && attachment.key === draggedOverEntry.key ? " dragged-over" : "")} draggable={isAllowed("EditSections") && currentSectionIsLockedByMe} onDragStart={(e) => { dragStartEntry(e, attachment); }} onDrop={(e) => { dragEndEntry(e, attachment); }} onDragOver={(e) => { dragOverEntry(e, attachment); }}>
															<div className="col-md-2">
																<a href={"/api/content/files/get/" + attachment.internalFileName + "/" + attachment.externalFileName} target="_blank"><img src={templateImage} className="img-responsive"></img></a>
															</div>
															<div className="col-md-9">
																<h3>{attachment.description}</h3>
																<p dangerouslySetInnerHTML={{__html: attachment.externalFileName}}></p>
															</div>
															<div className="col-md-1">
																{isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-dynamicsections-general-delete" onClick={() => { removeAttachment(index); }}><span className="deloitte-icon icon-bin_special"></span></div>)}
															</div>
														</div>
													))
												}
											</div>
										</div>
									)}
									{(currentSectionType == "IconCredentials" || currentSectionType == "ShortCredentials" || currentSectionType == "LongCredentials") && (
										<div className="editor-sectionicons-content-container-dynamic">
											{isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-sectionicons-content-container-top">
												<DeloitteButton title={translations.general_addnew} onClick={newCredential} >
													{translations.general_addnew}
												</DeloitteButton>
											</div>)}
											<div className="editor-sectionicons-content-container-listing">
												{
													currentSectionDynamicContent.map((credential: any, index: number) => (
														<div key={credential.key} className={"row editor-dynamicsections-general-entry" + (draggedOverEntry && credential.key === draggedOverEntry.key ? " dragged-over" : "")} draggable={isAllowed("EditSections") && currentSectionIsLockedByMe} onDragStart={(e) => { dragStartEntry(e, credential); }} onDrop={(e) => { dragEndEntry(e, credential); }} onDragOver={(e) => { dragOverEntry(e, credential); }}>
															<div className="col-md-2">
																<img src={"/api/content/files/get/" + credential.internalImageName + "/" + credential.externalImageName} className="img-responsive"></img>
															</div>
															<div className="col-md-9">
																<h3>{credential.customer}</h3>
																<p dangerouslySetInnerHTML={{ __html: credential.shortDescription }}></p>
															</div>
															<div className="col-md-1">
																{isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-dynamicsections-general-delete" onClick={() => { removeCredential(index); }}><span className="deloitte-icon icon-bin_special"></span></div>)}
															</div>
														</div>
													))
												}
											</div>
										</div>
									)}
									{(currentSectionType == "ShortAchievements" || currentSectionType == "LongAchievements") && (
										<div className="editor-sectionicons-content-container-dynamic">
											{isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-sectionicons-content-container-top">
												<DeloitteButton title={translations.general_addnew} onClick={newAchievement} >
													{translations.general_addnew}
												</DeloitteButton>
											</div>)}
											<div className="editor-sectionicons-content-container-listing">
												{
													currentSectionDynamicContent.map((achievement: any, index: number) => (
														<div key={achievement.key} className={"row editor-dynamicsections-general-entry" + (draggedOverEntry && achievement.key === draggedOverEntry.key ? " dragged-over" : "")} draggable={isAllowed("EditSections") && currentSectionIsLockedByMe} onDragStart={(e) => { dragStartEntry(e, achievement); }} onDrop={(e) => { dragEndEntry(e, achievement); }} onDragOver={(e) => { dragOverEntry(e, achievement); }}>
															<div className="col-md-11">
																<h3>{achievement.clientName}</h3>
																<p>
																	Project: {achievement.projectName} ({achievement.projectType})<br />
																	Budget: {achievement.budget}<br />
																	Perdiod: {moment(achievement.periodStart).format("MM/YYYY")} - {moment(achievement.periodEnd).format("MM/YYYY")}
																</p>
															</div>
															<div className="col-md-1">
																{isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-dynamicsections-general-delete" onClick={() => { removeAchievement(index); }}><span className="deloitte-icon icon-bin_special"></span></div>)}
															</div>
														</div>
													))
												}
											</div>
										</div>
									)}
									{currentSectionType == "Resumes" && (
										<div className="editor-sectionicons-content-container-dynamic">
											{isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-sectionicons-content-container-top">
												<DeloitteButton title={translations.general_addnew} onClick={() => {
													show(NewResumeMember, "newresume", {
														save: saveNewResume,
														cancel: () => {
															close("newresume");
														},
														// @ts-ignore
														exclude: _.reject(currentSectionResumes, { $state: "delete" }).map((resume: any) => {
															return { userID: resume.user.userID };
														})
													});
												}}>
													{translations.general_addnew}
												</DeloitteButton>
												&nbsp;&nbsp;
												<DeloitteButton title="Notify members" onClick={() => {
													repoProposals.notifyDeliveryTeam(record.id).then(() => {
														notify("Notifications will be sent!");
													});
												}}>
													Notify members
												</DeloitteButton>
											</div>)}
											<div className="editor-sectionicons-content-container-listing">
												{
													// @ts-ignore
													_.reject(currentSectionResumes, (resume) => { return resume.$state == "delete" || resume.$state == "deleted" }).map((resume: any, index: number) => (
														<div key={resume.key} className={"row editor-dynamicsections-general-entry" + (draggedOverResume && resume.key === draggedOverResume.key ? " dragged-over" : "")} draggable={isAllowed("EditSections") && currentSectionIsLockedByMe} onDragStart={(e) => { dragStartResume(e, resume); }} onDrop={(e) => { dragEndResume(e, resume); }} onDragOver={(e) => { dragOverResume(e, resume); }}>
															<div className="col-md-2">
																<img src={resume.imageURL} className="img-responsive"></img>
															</div>
															<div className="col-md-7">
																<h3>{resume.user.fullName}</h3>
																<p>{resume.function} - {resume.user.location}</p>
																<DeloitteCheckbox disabled={!isAllowed("EditSections") || !currentSectionIsLockedByMe} label="Ready for approval" checked={resume.ready} onClick={(checked) => {
																	changeResumeReadyForApproval(index, checked);
																}}></DeloitteCheckbox>
																<DeloitteCheckbox disabled={!isAllowed("EditSections") || !currentSectionIsLockedByMe} label="Approved" checked={resume.validated} onClick={(checked) => {
																	changeResumeApproved(index, checked);
																}}></DeloitteCheckbox>
															</div>
															<div className="col-md-3">
																{isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-dynamicsections-general-edit" onClick={() => { editResume(index); }}><span className="deloitte-icon icon-edit_profile_special"></span></div>)}
																{isAllowed("EditSections") && currentSectionIsLockedByMe && (<div className="editor-dynamicsections-general-delete" onClick={() => { removeResume(index); }}><span className="deloitte-icon icon-bin_special"></span></div>)}
															</div>
														</div>
													))
												}
											</div>
										</div>
									)}
								</div>
							)}
						</div>
					</div>
					<div className={selectedTab === 4 ? "tab-fixed-height" : "tab-fixed-height hidden"}>
						<h2 className="editor-h2">{translations.proposalwizard_letter_title}</h2>
						<div ref={ref2} className="tab-fixed-height-content">
							<form className="k-form">
								<div className="row">
									<div className="col-md-12">
										<label className="k-form-field">
											<span>{translations.proposalwizard_hidewelcomeletter}</span>
											{/*
											// @ts-ignore*/}
											<Switch name="hideWelcomeLetter" disabled={!isAllowed("GeneralField")} checked={record.hideWelcomeLetter} onChange={onChange}></Switch>
										</label>
									</div>
								</div>
								{isAllowed("GeneralField") && (<FroalaEditorComponent config={editorConfig} model={record.welcomeLetter} onModelChange={changeContent}></FroalaEditorComponent>)}
								{!isAllowed("GeneralField") && (<FroalaEditorView model={record.welcomeLetter}></FroalaEditorView>)}
							</form>
						</div>
					</div>
				</div>
				<div className="editor-footer">
					{proposalStatus == "ReadyForReview" && isAllowed("SendForReview") && showSendForReview && !currentSectionIsLockedByMe && (<div className="editor-proposalstatus-sendforreview" onClick={() => preventDoubleClick(sendForReview)}>Send for review</div>)}
					{proposalStatus == "Defend" && isAllowed("WinLostBack") && !currentSectionIsLockedByMe && (
						<div>
							<div className="editor-proposalstatus-setwon" onClick={() => preventDoubleClick(sendWon)}>Won</div>
							<div className="editor-proposalstatus-setlost" onClick={() => preventDoubleClick(sendLost)}>Lost</div>
							<div className="editor-proposalstatus-setback" onClick={() => preventDoubleClick(sendBackToReview)}>Back to review</div>
						</div>
					)}
					{proposalStatus == "ReadyToSend" && isAllowed("SendProposal") && !currentSectionIsLockedByMe && (<div className="editor-proposalstatus-sendproposal" onClick={() => preventDoubleClick(sendProposal)}>Send Proposal to client</div>)}
				</div>
				<div className={"editor-savestatus " + (saveStatus == "Saving..." ? "editor-savestatus-saving " : " ") + (saveStatus !== "Saving..." && saveStatus !== "Saved" ? "editor-savestatus-error" : "")}>
					{saveStatus}
				</div>
				<div className={"editor-proposalstatus"}>
					{statusMessage(proposalStatus)}
					<div className="editor-proposalstatus-tooltip">
						<span className={proposalStatus == "InProgress" ? "active" : ""}>{statusMessage("InProgress")}</span>
						<span className="editor-proposalstatus-tooltip-arrow"></span>
						<span className={proposalStatus == "ReadyForReview" ? "active" : ""}>{statusMessage("ReadyForReview")}</span>
						<span className="editor-proposalstatus-tooltip-arrow"></span>
						<span className={proposalStatus == "InReview" ? "active" : ""}>{statusMessage("InReview")}</span>
						<span className="editor-proposalstatus-tooltip-arrow"></span>
						<span className={proposalStatus == "ReadyToSend" ? "active" : ""}>{statusMessage("ReadyToSend")}</span>
						<span className="editor-proposalstatus-tooltip-arrow"></span>
						<span className={proposalStatus == "Sending" ? "active" : ""}>{statusMessage("Sending")}</span>
						<span className="editor-proposalstatus-tooltip-arrow"></span>
						<span className={proposalStatus == "Defend" ? "active" : ""}>{statusMessage("Defend")}</span>
						<span className="editor-proposalstatus-tooltip-arrow"></span>
						<span className={(proposalStatus == "Won" || proposalStatus == "Lost") ? "active" : ""}>{statusMessage("Won")}/{statusMessage("Lost")}</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProposalEditor;
