import React, { useState, useEffect } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { switchAndStoreLanguage, getCurrentLanguage } from '../../../translations';

const Header: React.FC = () => {

	/*
	const switchLang = () => {
		switchAndStoreLanguage((getCurrentLanguage() == "en") ?  "fr" : "en");
	}
	*/

	return (
		<div className="header-container">
			<div className="logo"></div>
			<div className="proposal-factory-container">
				Proposal Factory
			</div>
			<div className="privacy-container">
				<Link to="/privacy"><i className="deloitte-icon icon-shield_special" aria-hidden="true"></i></Link>
			</div>
			<div className="help-container">
				<Link to="/faq"><i className="deloitte-icon icon-question_mark_special" aria-hidden="true"></i></Link>
			</div>
			<div className="user-container">
				<Link to="/myprofile"><i className="deloitte-icon icon-profile_special" aria-hidden="true"></i></Link>
			</div>
		</div>
	);
}

export default Header;
