import React, { useState, useEffect } from 'react';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import New from './new';
import Edit from './edit';
import { show, close, showLoader, hideLoader } from '../../../../services/renderer';
import repoIndustries from '../../../../repositories/settings/industries';
import cellButton from '../../../global/cellbutton';
import { notify, alert } from '../../../../services/notification';
import GridPanel from '../../../global/gridpanel';
import { triggerListeners } from '../../../../services/listeners';
import { cloneObject } from '../../../../services/tools';
import { isAuthorized } from '../../../../services/authorization';
import translations from '../../../../translations';
import DeloitteButton from '../../../global/deloittebutton';

const List: React.FC = (props) => {

	function edit(dataItem: any) {
		show(Edit, "industryedit", { dataItem: cloneObject(dataItem), save: save, cancel: cancel });
	}

	function cancel() {
		close("industryedit");
		close("industrynew");
	}

	function save(record: any) {
		showLoader();
		repoIndustries.save(record).then((result) => {
			notify(translations.general_savedsuccess);
			triggerListeners("reloadIndustries");
			cancel();
			hideLoader();
		}).catch((error) => {
			hideLoader();
			alert(error);
		});
	}

	return (
		<GridPanel reloadKey="reloadIndustries" loadData={repoIndustries.getAll} filter={{
			logic: "and",
			filters: [
				{
					field: "active",
					operator: "eq",
					value: true
				}
			]
		}} onRowDoubleClick={edit}>
			{isAuthorized("Industries", "Create") ? <GridToolbar >
				<div>
					<DeloitteButton title={translations.general_addnew} onClick={() => { show(New, "industrynew", { save: save, cancel: cancel }); }} >
						{translations.general_addnew}
					</DeloitteButton>
				</div>
			</GridToolbar> : null}
			<GridColumn field="name" title={translations.industries_name} editable={false} />
			<GridColumn field="active" title={translations.general_active} width="125px" editor="boolean" filter={'boolean'} />
			{isAuthorized("Industries", "Update") ? <GridColumn filterable={false} width="120px" editable={false} title={translations.general_edit} cell={cellButton(edit, translations.general_edit)} /> 
				: (!isAuthorized("Industries", "Update") && isAuthorized("Industries", "Read") ? <GridColumn filterable={false} width="120px" editable={false} title={translations.general_view} cell={cellButton(edit, translations.general_view)} /> : null)} 
		</GridPanel>
	);
}

export default List;