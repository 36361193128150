import { EditorUtils } from '@progress/kendo-react-editor';

const msClass = /^Mso/;
const stylesSplit = /\s*;\s*/;
const styleValueSplit = /\s*:\s*/;

export const sanitizeHtml = (html: any) => {
	// Removes the invalid content.
	html = EditorUtils.sanitize(html);

	// Converts MS Word lists into HTML lists.
	html = EditorUtils.convertMsLists(html);

	// `EditorUtils.removeTag` will remove the pointed tags without their child nodes.
	// html = EditorUtils.removeTag(html, 'span|i|b|u');

	const div = document.createElement('div');
	div.innerHTML = html;

	Array.from(div.querySelectorAll('*')).forEach(node => sanitizeNode(node));

	return div.innerHTML;
};

const sanitizeNode = (node: any) => {
	if (node.nodeType === Node.ELEMENT_NODE) {
		for (let i = node.attributes.length - 1; i >= 0; i--) {
			sanitizeAttr(node.attributes[i]);
		}
		if (node.nodeName === 'SPAN' && node.attributes.length === 0) {
			removeNode(node);
		}
	}
};

const sanitizeAttr = (attr: any) => {
	if (attr.name === 'class' && msClass.test(attr.value)) {
		removeAttribute(attr);
	} else if (attr.name === 'lang') {
		removeAttribute(attr);
	} else if (attr.name === 'style') {
		sanitizeStyleAttr(attr);
	}
};

const sanitizeStyleAttr = (attr: any) => {
	const attrValue = attr.value;
	const styles = attrValue.split(stylesSplit).filter((v: any) => v);
	const supportedStyles = attr.ownerElement.style;
	let result = '', name, value;

	styles.forEach((style: any) => {
		[name, value] = style.split(styleValueSplit);
		if (supportedStyles[name] !== undefined) {
			result += `${name}: ${value}; `;
		}
	});
	result = result.trim();

	if (result) {
		attr.value = result;
	} else {
		removeAttribute(attr);
	}
};

const removeAttribute = (attr: any) => {
	if (attr.ownerElement) {
		attr.ownerElement.removeAttribute(attr.name);
	}
};

const removeNode = (node: any) => {
	const parentNode = node.parentNode;
	if (parentNode) {
		while (node.firstChild) {
			parentNode.insertBefore(node.firstChild, node);
		}
		parentNode.removeChild(node);
	}
};

let isCalledClickButton = false;
let timerClickButton:NodeJS.Timeout;

export const preventDoubleClick = (functionTobeCalled:any, interval:number = 600) => {
	if (!isCalledClickButton) {
		isCalledClickButton = true;
		clearTimeout(timerClickButton);
		timerClickButton = setTimeout(() => {
			isCalledClickButton = false;
		}, interval);
		return functionTobeCalled();
	}
};

export const cloneObject = (object: any) => {
	return Object.assign({}, object);
};

//Generate UUID
export const newGuid = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
};

export const getFileExtension = (fileName: string) => {
	var rFileExtension = /\.([^\.]+)$/;
	var matches = fileName.match(rFileExtension);
	return matches ? matches[0] : '';
};

export const substitute = (input: string, ...args: [string]) => {
	return input.replace(/{(\d+)}/g, (match, number) => {
		return typeof args[number] != 'undefined' ? args[number] : match;
	})
}

export const formatMoney = (amount: number, countryCode: string, currency: string) => {
	var formatter = new Intl.NumberFormat(countryCode, {
		style: 'currency',
		currency: currency,
	});

	return formatter.format(amount); 
}
