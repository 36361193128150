import { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken } from '../../services/authentication';
import translations from '../../translations';

const baseURL = "/api/settings/countries/languages/";

const repository = {
	getAll: async (conf: any) => {
		try {
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "getall", conf, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	getActive: async () => {
		try {
			var accessToken = await getToken();
			var result = await axios.get(baseURL + "getactive", {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data.map((countrylanguage: any) => {
				return {
					id: countrylanguage.language.id,
					name: countrylanguage.language.name,
					code: countrylanguage.language.code
				};
			});
		} catch (error) {
			throw error;
		}
	},
	add: async (languageID: string) => {
		try {
			if (!languageID || languageID == "" || languageID == "00000000-0000-0000-0000-000000000000") throw translations.languages_languagerequired;
			var accessToken = await getToken();
			var result = await axios.post(baseURL + "add/" + languageID, {}, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	},
	delete: async (languageID: string) => {
		try {
			if (!languageID || languageID == "" || languageID == "00000000-0000-0000-0000-000000000000") throw translations.languages_languagerequired;
			var accessToken = await getToken();
			var result = await axios.delete(baseURL + "delete/" + languageID, {
				headers: {
					"Authorization": "Bearer " + accessToken
				}
			});
			return result.data;
		} catch (error) {
			throw error;
		}
	}
};

export default repository;

export const useLanguages = () => {
	const [languages, setLanguages] = useState([]);
	useEffect(() => {
		let isSame = true;
		repository.getActive().then((result) => {
			if (isSame) setLanguages(result);
		});
		return () => { isSame = false; }
	}, []);
	return languages;
};
