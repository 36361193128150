import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import DeloitteButton from './deloittebutton';

export default function cellButton(callback: any, title: string, hidden?: Function) {
    return class extends GridCell {
        render() {
            return (
				<td>
					{(!hidden || (hidden && typeof(hidden) == "function" && !hidden(this.props.dataItem))) && (
						<DeloitteButton
							additionalClass="k-grid-edit-command"
							onClick={() => { callback(this.props.dataItem); }}
						>
							{title}
						</DeloitteButton>
					)}
                </td>
            );
        }
    };
}