import Confirm from '../components/global/confirm';
import Loader from '../components/global/loader';
import { newGuid } from './tools';

export interface IRenderConfig {
	component: React.FC<any>;
	key: string;
	parentKey?: string;
	props?: Object;
}

let collection = <IRenderConfig[]>[];
let eventHandlers = <Function[]>[];

function close(key: string) {
	//Get conf to remove from the colleciton
	const confToDelete: IRenderConfig = <IRenderConfig>collection.find(function (conf) {
		return conf.key === key;
	});
	if (confToDelete) {
		//Actual removal from collection
		collection.splice(collection.indexOf(confToDelete), 1);
		//Trigger event handlers
		triggerEventHandlers();
	}
	
}

function getCollection(id?: string) {
	return collection.filter((conf) => { return conf.parentKey === id; });
}

function setEventHandler(fn: Function) {
	eventHandlers = <Function[]>[...eventHandlers, fn];
}

function triggerEventHandlers() {
	eventHandlers.forEach((handler) => handler());
}


export { getCollection };
export { close };
export { setEventHandler as onCollectionChanged };
export function show(component: React.FC<any>, key: string): void;
export function show(component: React.FC<any>, key: string, props: Object): void;
export function show(component: React.FC<any>, key: string, parentKey: string): void;
export function show(component: React.FC<any>, key: string, parentKey: string, props: Object): void;
export function show(component: React.FC<any>, key: string, parentKey?: string | Object, props?: Object): void {
	//Create conf
	const conf: IRenderConfig = {
		component: component,
		key: key,
		props: props || parentKey,
	};

	if (parentKey) {
		if (typeof parentKey === "string") {
			conf.parentKey = parentKey;
		}
	}

	//Push to collection
	collection.push(conf);
	//Trigger event handlers
	triggerEventHandlers();
}

export function confirm(title: string, message: string, yes: () => void, yesLabel?: string, no?: () => void, noLabel?: string) {
	const key = newGuid();
	const yesWrapper = () => {
		close(key);
		yes();
	};
	const noWrapper = () => {
		close(key);
		if (no) no();
	};
	var props: any = {
		title: title,
		message: message,
		yes: yesWrapper,
		cancel: () => { close(key); }
	};
	if (yesLabel) props.yesLabel = yesLabel;
	if (no) props.no = noWrapper;
	if (noLabel) props.noLabel = noLabel;
	show(Confirm, key, props);
}

export function confirmChanged(hasChanged: boolean, save: () => void, dontSave: () => void) {
	if (hasChanged) {
		const key = newGuid();
		const yesWrapper = () => {
			close(key);
			save();
		};
		const noWrapper = () => {
			close(key);
			if (dontSave) dontSave();
		};
		var props: any = {
			title: "Continue without saving?",
			message: "You have unsaved data!",
			yes: yesWrapper,
			cancel: () => { close(key); }
		};
		props.yesLabel = "Save changes";
		props.no = noWrapper;
		props.noLabel = "Don't save";
		show(Confirm, key, props);
	} else {
		dontSave();
	}
}

export function showLoader() {
	close("loader-global");
	show(Loader, "loader-global");
}

export function hideLoader() {
	close("loader-global");
}