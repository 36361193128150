import React, { useState, useEffect } from 'react';
import { Route, NavLink as Link } from "react-router-dom";
import UsersList from "./users/list";
import RolesList from "./roles/list";
import AuditLogList from "./auditlog/list";
import ErrorLogList from "./errorlog/list";
import translations from '../../../translations';
import { isAuthorized } from '../../../services/authorization';

const Security: React.FC = (props: any) => {

	const items = [
		{
			label: translations.users_windowtitle,
			url: "/users",
			key: "users",
			securityKey: "Users"
		},
		{
			label: translations.roles_windowtitle,
			url: "/roles",
			key: "roles",
			securityKey: "Roles"
		},
		{
			label: "Audit log",
			url: "/audit",
			key: "audit",
			securityKey: "MonitoringLogging"
		},
		{
			label: "Error log",
			url: "/error",
			key: "error",
			securityKey: "ErrorLogging"
		},
	]

	return (
		<div>
			<div className="top-bar">
				<ul>
					{
						items.map((item, i) => {
							return (item.securityKey === "" || !item.securityKey || isAuthorized(item.securityKey, "*") ? (
								<Link to={props.match.url + item.url} key={i} activeClassName="active"><li> {item.label} </li></Link>
							) : null);
						})
					}
				</ul>
			</div>
			<div className="content-container">
				<Route path={props.match.url}>
					<div className="pages-general pages-security">
						<h1>Security &amp; Roles</h1>
						<div className="pages-general-row">
							{items.map((item, i) => {
								return (item.securityKey === "" || !item.securityKey || isAuthorized(item.securityKey, "*") ? (
									<Link to={props.match.url + item.url} key={i}>
										<div className={"pages-general-block pages-security-" + item.key}>
											<span>{item.label}</span>
										</div>
									</Link>
								) : null);
							})}
						</div>
					</div>
				</Route>
				{isAuthorized("Users", "*") && (<Route path={props.match.url + "/users"} component={UsersList} />)}
				{isAuthorized("Roles", "*") && (<Route path={props.match.url + "/roles"} component={RolesList} />)}
				{isAuthorized("MonitoringLogging", "*") && (<Route path={props.match.url + "/audit"} component={AuditLogList} />)}
				{isAuthorized("ErrorLogging", "*") && (<Route path={props.match.url + "/error"} component={ErrorLogList} />)}
			</div>
		</div>
	);
}

export default Security;
