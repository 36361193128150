import React, { useState, useEffect } from 'react';
import GridPanel from '../../global/gridpanel';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import cellButton from '../../global/cellbutton';
import Wizard from '../proposals/wizard';
import Editor from '../proposals/editor';
import { show, close, showLoader, hideLoader } from '../../../services/renderer';
import repoProposals from '../../../repositories/proposals/proposals';
import repoTasks from '../../../repositories/tasks/tasks';
import { notify, alert } from '../../../services/notification';
import { triggerListeners } from '../../../services/listeners';
import { cloneObject } from '../../../services/tools';
import { isAuthorized } from '../../../services/authorization';
import translations from '../../../translations';
import DeloitteButton from '../../global/deloittebutton';
import IconButton from '../../global/iconbutton';
import SendProposal from '../proposals/send';
import EditResume from './editresume';

interface ProposalProps {
	isHome: boolean
}

const List: React.FC<ProposalProps> = (props) => {

	function edit(dataItem: any) {
		if (dataItem.type == "CompleteResumeTask") {
			editResume(dataItem.payload);
		} else {
			editProposal(dataItem.payload);
		}
	}

	function cancel() {
		triggerListeners("reloadTasks");
		close("proposaledit");
		close("resumeedit");
	}

	function editProposal(proposalID: string) {
		repoProposals.get(proposalID).then((proposal) => {
			show(Editor, "proposaledit", {
				dataItem: proposal, save: () => {
					triggerListeners("reloadTasks");
					cancel();
				},
				cancel: cancel
			});
		});
	}

	function editResume(resumeID: string) {
		repoProposals.getResume(resumeID).then((resume: any) => {
			show(EditResume, "resumeedit", {
				dataItem: {
					...resume,
					fullName: resume.user.fullName
				}, save: (record: any) => {
					repoProposals.saveResume(record).then(() => {
						triggerListeners("reloadTasks");
						cancel();
					});
				}, cancel: cancel
			});
		});
	}

	const taskCell = (cellProps: any) => {
		return (
			<td>
				{cellProps.dataItem.type == "CompleteResumeTask" ? "Complete resume" : "Edit proposal"}
			</td>
		);
	};

	const actionsCell = (cellProps: any) => {
		return (
			<td>
				<IconButton
					title="Edit"
					iconClass="deloitte-icon icon-file_edit_special"
					onClick={() => { cellProps.dataItem.type == "CompleteResumeTask" ? editResume(cellProps.dataItem.payload) : editProposal(cellProps.dataItem.payload); }}
				/>
			</td>
		);
	};

	return (
		<div>
			<GridPanel reloadKey="reloadTasks" loadData={repoProposals.getForTasks} onRowDoubleClick={edit}>
				<GridColumn field="companyName" title={translations.proposals_customer} width={180} editable={false} />
				<GridColumn field="title" title={translations.proposals_title} editable={false} />
				<GridColumn field="subTitle" title="Subtitle" editable={false} />
				<GridColumn filterable={false} editable={false} width={210} title="Task" cell={taskCell} />
				<GridColumn filterable={false} editable={false} width={80} title="" cell={actionsCell} />
			</GridPanel>
		</div>
	);
}

export default List;