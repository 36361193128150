import React, { useState, useEffect } from 'react';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { confirmChanged } from '../../../../services/renderer';
import repoLanguages from '../../../../repositories/settings/languages';
import translations from '../../../../translations';
import { preventDoubleClick } from '../../../../services/tools';

interface NewProps {
	save: (record: any) => void,
	cancel: () => void
}

const New: React.FC<NewProps> = (props) => {

	const [record, setRecord] = useState({
		languageID: "00000000-0000-0000-0000-000000000000",
	});
	const [languages, setLanguages] = useState([]);
	const [dataChanged, setDataChanged] = useState(false);

	useEffect(() => {
		repoLanguages.getActive().then((result) => {
			setLanguages(result);
		});
	}, []);

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			case "languageID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				break;
		}
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		props.save(record);
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	return (
		<Dialog
			onClose={cancel}
			title={translations.general_new + " " + translations.languages_windowtitlesingle}
			width={300}
		>
			<form className="k-form" onSubmit={onSubmit}>
				<label className="k-form-field">
					<span>{translations.languages_name}</span>
					<ComboBox
						data={languages}
						name="languageID"
						dataItemKey="id"
						textField="name"
						onChange={onChange}
						required={true}
						style={{ width: "100%" }}
					/>
				</label>
			</form>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				<button className="k-button k-primary" onClick={() => preventDoubleClick(save)}>
					{translations.general_save}
				</button>
			</DialogActionsBar>
		</Dialog>
	);
}

New.defaultProps = {
	save: (record: any) => { },
	cancel: () => { }
};

export default New;