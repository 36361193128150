///<reference path= "../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import React, { useState, useEffect } from 'react';
//@ts-ignore
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import repoPublications from '../../../repositories/settings/publications';
import repoLegalDocuments from '../../../repositories/settings/legaldocuments';
import repoCredentials from '../../../repositories/settings/credentials';
import repoAchievements from '../../../repositories/settings/achievements';
import _ from 'lodash';
import moment from 'moment';
import { showLoader, hideLoader, confirm } from '../../../services/renderer';
import translations, { getCurrentLanguage } from '../../../translations';
import pdfImage from '../../../assets/img/pdf.png';
import { formatMoney } from '../../../services/tools';

interface NewProps {
	save: (record: any) => void,
	cancel: () => void,
	query: any,
	sectionType: string,
	exclude: Array<string>
}

const New: React.FC<NewProps> = (props) => {

	const [entries, setEntries] = useState(new Array());

	useEffect(() => {
		showLoader();
		if (props.sectionType == "Publications") {
			repoPublications.getActive(props.query).then((result: any) => {
				setEntries(_.differenceBy(result.map((entry: any) => {
					return {
						...entry,
						key: entry.id,
						titleLabel: entry.title,
						descriptionLabel: "",
						imageUrl: "/api/content/files/get/" + entry.image.internalFilename + "/" + entry.image.filename,
						selectedEntry: false
					}
				}), props.exclude, "key"));
				hideLoader();
			});
		} else if (props.sectionType == "LegalDocuments") {
			repoLegalDocuments.getActive(props.query).then((result: any) => {
				setEntries(_.differenceBy(result.map((entry: any) => {
					return {
						...entry,
						key: entry.id,
						titleLabel: entry.title,
						descriptionLabel: "",
						imageUrl: pdfImage,
						selectedEntry: false
					}
				}), props.exclude, "key"));
				hideLoader();
			});
		} else if (props.sectionType == "Credentials") {
			repoCredentials.getActive(props.query).then((result: any) => {
				setEntries(_.differenceBy(result.map((entry: any) => {
					return {
						...entry,
						key: entry.id,
						titleLabel: entry.customer,
						descriptionLabel: entry.shortDescription,
						imageUrl: "/api/content/files/get/" + entry.logo.internalFilename + "/" + entry.logo.filename,
						selectedEntry: false
					}
				}), props.exclude, "key"));
				hideLoader();
			});
		} else if (props.sectionType == "Achievements") {
			repoAchievements.search(props.query).then((result: any) => {
				setEntries(_.differenceBy(result.map((entry: any) => {
					return {
						...entry,
						key: entry.id,
						titleLabel: entry.clientName + " - " + entry.governementLevel,
						descriptionLabel: "Project: " + entry.projectName + " (" + entry.projectType + ")",
						descriptionLabel2: "Budget: " + formatMoney(entry.budget, entry.currency.split(',')[0], entry.currency.split(',')[1]),
						descriptionLabel3: "Perdiod: " + moment(entry.periodStart).format("MM/YYYY") + " - " + moment(entry.periodEnd).format("MM/YYYY"),
						selectedEntry: false
					}
				}), props.exclude, "key"));
				hideLoader();
			});
		}
	}, []);

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		props.save(entries.filter((entry: any) => entry.selectedEntry));
	}


	function pickEntry(entry: any) {
		props.save([entry]);
	}

	function cancel() {
		props.cancel();
	}

	function selectEntry(entry: any) {
		entry.selectedEntry = !entry.selectedEntry;
		setEntries([...entries]);
	}

	return (
		<Dialog
			onClose={cancel}
			title={translations.general_new}
			width="50%"
		>
			<form className="k-form" onSubmit={onSubmit}>
				<div>Select multiple {props.sectionType == "Publications" && ("publications")}{props.sectionType == "LegalDocuments" && ("legal documents")}{props.sectionType == "Credentials" && ("credentials")} to add.</div>
				<div>&nbsp;</div>
				<div className="editor-newdynamicentries-listing">
					{
						entries.map((entry: any) => (<div key={entry.key} className={"editor-newdynamicentries-entry " + (entry.selectedEntry ? "active" : "")} onClick={() => {
							selectEntry(entry);
						}} onDoubleClick={() => {
							pickEntry(entry);
						}}>
							{props.sectionType == "Achievements" ? (<>
								<div className="editor-newdynamicentries-name-achievements">{entry.titleLabel}</div>
								<div className="editor-newdynamicentries-description-achievements">{entry.descriptionLabel}</div>
								<div className="editor-newdynamicentries-description-achievements">{entry.descriptionLabel2}</div>
								<div className="editor-newdynamicentries-description-achievements">{entry.descriptionLabel3}</div>
							</>) : (<>
								<div className="editor-newdynamicentries-image"><img src={entry.imageUrl}></img></div>
								<div className="editor-newdynamicentries-name">{entry.titleLabel}</div>
								<div className="editor-newdynamicentries-description">{entry.descriptionLabel}</div>
							</>)}
						</div>
						))
					}
				</div>
			</form>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				<button className="k-button k-primary" onClick={save}>
					{translations.templatesections_new_addbutton}
				</button>
			</DialogActionsBar>
		</Dialog>
	);
}

New.defaultProps = {
	save: (record: any) => { },
	cancel: () => { }
};

export default New;