import React, { useState, useEffect } from 'react';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Upload, UploadOnAddEvent } from '@progress/kendo-react-upload';
import FilePreview from '../../../global/filepreview';
import { useLanguages } from '../../../../repositories/settings/countrylanguages';
import { useBusinessUnits } from '../../../../repositories/settings/businessunits';
import { alert } from '../../../../services/notification';
import { confirmChanged } from '../../../../services/renderer';
import translations from '../../../../translations';
import { preventDoubleClick } from '../../../../services/tools';

interface NewProps {
	save: (record: any) => void,
	cancel: () => void
}

const New: React.FC<NewProps> = (props) => {

	const [record, setRecord] = useState({
		id: "00000000-0000-0000-0000-000000000000",
		businessUnitID: "00000000-0000-0000-0000-000000000000",
		languageID: "00000000-0000-0000-0000-000000000000",
		title: "",
		active: false,
		file: {}
	});
	const businessunits = useBusinessUnits();
	const languages = useLanguages();
	const [file, setFile] = useState();
	const [dataChanged, setDataChanged] = useState(false);

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			case "businessUnitID":
			case "languageID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.id : "00000000-0000-0000-0000-000000000000";
				break;
			default:
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value;
		}
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		if (file) {
			record.file = file;
			// @ts-ignore
			record.document_fileAction = "change";
			props.save(record);
		} else {
			alert(translations.general_fileupload_pdfrequired);
		}
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	// @ts-ignore
	function addFile(e: UploadOnAddEvent) {
		if (e && e.affectedFiles && e.affectedFiles.length > 0 && e.affectedFiles[0]) {
			if (e.affectedFiles[0].size && e.affectedFiles[0].size > 10000000) {
				setFile(null);
				alert(translations.general_fileupload_above10mb);
				return;
			}
			if (e.affectedFiles[0].extension && e.affectedFiles[0].extension.toLowerCase() != ".pdf") {
				setFile(null);
				alert(translations.general_fileupload_nopdf);
				return;
			}
			// @ts-ignore
			setFile(e.affectedFiles[0]);
			setDataChanged(true);
		} else {
			setFile(null);
			alert(translations.general_fileupload_novalidfile);
		}
	}

	return (
		<Dialog
			onClose={cancel}
			title={translations.general_new + " " + translations.legaldocuments_windowtitlesingle}
			width={550}
		>
			<form className="k-form" onSubmit={onSubmit}>
				<label className="k-form-field">
					<span>{translations.legaldocuments_title}</span>
					<Input
						name="title"
						style={{ width: "100%" }}
						placeholder={translations.general_placeholder_prefix + " " + translations.legaldocuments_title + "..."}
						value={record && record.title ? record.title : ""}
						onChange={onChange}
						required={true}
						validationMessage={translations.general_validationmessage + " " + translations.legaldocuments_title}
					/>
				</label>
				<div className="row">
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.legaldocuments_businessunit}</span>
							<ComboBox
								data={businessunits}
								name="businessUnitID"
								dataItemKey="id"
								textField="name"
								style={{ width: "100%" }}
								onChange={onChange}
								required={true}
								suggest={true}
							/>
						</label>
					</div>
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.legaldocuments_language}</span>
							<ComboBox
								data={languages}
								name="languageID"
								dataItemKey="id"
								textField="name"
								style={{ width: "100%" }}
								onChange={onChange}
								required={true}
								suggest={true}
							/>
						</label>
					</div>
				</div>
				<label className="k-form-field">
					<span>{translations.legaldocuments_document}</span>
					<div className={!file ? "isrequired" : ""}>
						<Upload
							autoUpload={false}
							multiple={false}
							accept=".pdf"
							files={file ? [file] : []}
							onAdd={addFile}
							listItemUI={FilePreview}
							onRemove={() => {
								setFile(undefined);
							}}
							className="hidden-upload-button-in-preview"
						/>
					</div>
				</label>
				<label className="k-form-field">
					<span>{translations.general_active}</span>
					{/*
					// @ts-ignore*/}
					<Switch
						name="active"
						checked={
							record && record.active ? record.active : false
						}
						onChange={onChange}
						onLabel={translations.general_switch_on}
						offLabel={translations.general_switch_off}
					/>
				</label>
			</form>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>
					{translations.general_cancel}
				</button>
				<button className="k-button k-primary" onClick={() => preventDoubleClick(save)}>
					{translations.general_save}
				</button>
			</DialogActionsBar>
		</Dialog>
	);
}

New.defaultProps = {
	save: (record: any) => { },
	cancel: () => { }
};

export default New;