import translationEN from './translations_en.json';
import translationFR from './translations_fr.json';
import { getMyProfile } from '../services/authentication';
import { triggerListeners } from '../services/listeners';
import _ from 'lodash';

// @ts-ignore
let langCode: string = "en";

let translations = _.clone(langCode == "fr" ? translationFR : translationEN);

// @ts-ignore
export default translations;

export const switchLanguage = (language: string) => {
	langCode = language == "fr" ? "fr" : "en";
	Object.keys(translations).forEach((key: string) => {
		// @ts-ignore
		translations[key] = langCode == "fr" ? translationFR[key] : translationEN[key]
	});
	triggerListeners("translations_reload");
}

export const switchToDefaultLanguage = () => {
	var language: string = (getMyProfile().language && getMyProfile().language.code) ? getMyProfile().language.code.toLowerCase() : "en";
	if (language == "fra") language = "fr";
	if (language == "eng") language = "en";
	if (sessionStorage.getItem("ui_lang")) {
		switchLanguage(<string>sessionStorage.getItem("ui_lang"));
	} else {
		switchLanguage(language);
	}
}

export const switchAndStoreLanguage = (language: string) => {
	var lang = language.toLowerCase() == "fr" ? "fr" : "en";
	sessionStorage.setItem("ui_lang", lang);
	switchLanguage(lang);
}

export const getEmailBodyForProposal = (proposal: any) => {
	if (proposal.language.code == "ENG") {
		return "<p>Dear {{CONTACTSALUTATION}} {{CONTACTLASTNAME}}</p><p>&nbsp;</p><p>We are delighted to present to you our proposal: <strong><a href=\"{{MICROSITEPORTAL}}\" style=\"color:#79a531;\">" + proposal.title + "</a></strong> for " + proposal.companyName + "<br />Password to use to access your proposal: {{PASSWORD}}</p><p>Our team represents the very best Deloitte has to offer and will provide " + proposal.companyName + " with outstanding service.</p><p>This commitment is from the whole of Deloitte, our entire team, and me personally.</p><p>&nbsp;</p><p>You will be choosing among excellent organizations. However, the overriding factors in establishing a partnership, which we are sure you envisage, are the chemistry, the commitment to excellence, and the dedication to your organization of the team leading this engagement.&nbsp;</p><p>&nbsp;</p><p>Please allow us to contact you soon, to ensure that all is clear.</p><p>If you have any questions in the meantime, please do not hesitate to contact us.</p><p>&nbsp;</p><p>Best regards</p><p>&nbsp;</p><p>" + proposal.signingPartner.fullName + "</p><p>" + proposal.signingPartner.function + "</p>";
	}
	if (proposal.language.code == "FRA") {
		return "<p>Cher {{CONTACTSALUTATION}} {{CONTACTLASTNAME}}</p><p>&nbsp;</p><p>Nous avons le plaisir de vous présenter notre proposition: <strong><a href=\"{{MICROSITEPORTAL}}\" style=\"color:#79a531;\">" + proposal.title + "</a></strong> for " + proposal.companyName + "<br />Mot de passe à utiliser pour accéder à votre proposition : {{PASSWORD}}</p><p>Notre équipe représente ce que Deloitte a de mieux à offrir et fournira à " + proposal.companyName + " un service exceptionnel.</p><p>Cet engagement concerne l’ensemble de Deloitte, toute notre équipe et moi-même.</p><p>&nbsp;</p><p>Vous choisirez parmi d'excellentes organisations. Cependant, les facteurs déterminants dans l'établissement d'un partenariat, et nous en sommes sûrs, sont la chimie, l'engagement envers l'excellence et le dévouement envers votre organisation, l'équipe responsable de cet engagement.&nbsp;</p><p>&nbsp;</p><p>Nous nous permettrons de vous contacter bientôt, pour vérifier que tout soit clair.</p><p>Cependant, si vous avez des questions entre-temps, n’hésitez pas à nous le faire savoir.</p><p>&nbsp;</p><p>Meilleures salutations</p><p>&nbsp;</p><p>" + proposal.signingPartner.fullName + "</p><p>" + proposal.signingPartner.function + "</p>";
	}
	if (proposal.language.code == "NLD") {
		return "<p>Beste {{CONTACTSALUTATION}} {{CONTACTLASTNAME}}</p><p>&nbsp;</p><p>We stellen u graag onze proposal voor: <strong><a href=\"{{MICROSITEPORTAL}}\" style=\"color:#79a531;\">" + proposal.title + "</a></strong> for " + proposal.companyName + "<br />Wachtwoord voor toegang tot uw voorstel: {{PASSWORD}}</p><p>Ons team vertegenwoordigt het allerbeste dat Deloitte te bieden heeft en zal " + proposal.companyName + " een uitstekende service bieden.</p><p>Deze inzet komt van heel Deloitte, dit hele team en mezelf persoonlijk.</p><p>&nbsp;</p><p>U zal kiezen uit uitstekende organisaties. De belangrijkste factoren bij het opzetten van een partnerschap, waarvan we zeker weten dat u het voor ogen hebt, is de chemie, de toewijding aan uitmuntendheid en de toewijding aan uw organisatie van het team.&nbsp;</p><p>&nbsp;</p><p>Sta ons toe snel contact met u op te nemen, zodat alles duidelijk is.</p><p>Mocht u in de tussentijd nog vragen hebben, aarzel dan niet om contact met ons op te nemen.</p><p>&nbsp;</p><p>Vriendelijke groet</p><p>&nbsp;</p><p>" + proposal.signingPartner.fullName + "</p><p>" + proposal.signingPartner.function + "</p>";
	}
	return "<p>Dear {{CONTACTSALUTATION}} {{CONTACTLASTNAME}}</p><p>&nbsp;</p><p>We are delighted to present to you our proposal: <strong><a href=\"{{MICROSITEPORTAL}}\" style=\"color:#79a531;\">" + proposal.title + "</a></strong> for " + proposal.companyName + "<br />Password to use to access your proposal: {{PASSWORD}}</p><p>Our team represents the very best Deloitte has to offer and will provide " + proposal.companyName + " with outstanding service.</p><p>This commitment is from the whole of Deloitte, our entire team, and me personally.</p><p>&nbsp;</p><p>You will be choosing among excellent organizations. However, the overriding factors in establishing a partnership, which we are sure you envisage, are the chemistry, the commitment to excellence, and the dedication to your organization of the team leading this engagement.&nbsp;</p><p>&nbsp;</p><p>Please allow us to contact you soon, to ensure that all is clear.</p><p>If you have any questions in the meantime, please do not hesitate to contact us.</p><p>&nbsp;</p><p>Best regards</p><p>&nbsp;</p><p>" + proposal.signingPartner.fullName + "</p><p>" + proposal.signingPartner.function + "</p>";
}

export const getCurrentLanguage = () => langCode;

export const getRawTranslations = () => {
	return [
		{
			langCode: "en",
			translations: translationEN
		},
		{
			langCode: "fr",
			translations: translationFR
		}
	]
};
