import React, { useState, useEffect } from 'react';
import GridPanel from '../../../global/gridpanel';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import cellButton from "../../../global/cellbutton";
import { show, close, showLoader, hideLoader } from '../../../../services/renderer';
import repoErrorLog from '../../../../repositories/security/errorlog';
import { notify, alert } from '../../../../services/notification';
import { triggerListeners } from '../../../../services/listeners';
import { cloneObject } from '../../../../services/tools';
import { isAuthorized } from '../../../../services/authorization';
import translations from '../../../../translations';

const List: React.FC = (props) => {

	const recordCell = (cellProps: any) => {
		return (
			<td>
				<div style={{ height: 100, overflow: "auto" }} title={cellProps.dataItem.message}>{cellProps.dataItem.message}</div>
			</td>
		);
	};

	return (
		<div>
			<GridPanel reloadKey="reloadAuditLog" loadData={repoErrorLog.getAll}>
				<GridColumn field="date" title="Date" editable={false} filter={'date'} format="{0:yyyy/MM/dd HH:mm}" width={180} />
				<GridColumn field="source" title="Source" editable={false} width={240} />
				<GridColumn field="message" title="Message" editable={false} cell={recordCell} />
			</GridPanel>
		</div>
	);
}

export default List;