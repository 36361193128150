import React, { useState, useEffect } from 'react';
import { Input, Switch, NumericTextBox } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { confirmChanged } from '../../../services/renderer';
import { show, close, confirm, showLoader, hideLoader } from '../../../services/renderer';
import NewDynamicEntries from './newdynamicentries';
import translations from '../../../translations';
import repoUsers from '../../../repositories/security/users';
import repoAchievements from '../../../repositories/settings/achievements';
import _ from 'lodash';
import moment from 'moment';

interface SearchAchievementsProps {
	save: (record: any) => void,
	cancel: () => void,
	query: any,
	sectionType: string,
	exclude: Array<string>
}

const SearchAchievements: React.FC<SearchAchievementsProps> = (props) => {

	const [record, setRecord] = useState({
		projectPartnerID: "",
		projectManagerID: "",
		clientName: "",
		governementLevel: "",
		projectName: "",
		projectType: "",
		policyArea: "",
		budgetFrom: undefined,
		budgetTo: undefined,
		numberOfInvolvedStaffFrom: undefined,
		numberOfInvolvedStaffTo: undefined,
		period: undefined,
		contactClient: "",
		subcontractorsPartners: undefined,
	});
	const [dataChanged, setDataChanged] = useState(false);
	const [users, setUsers] = useState(new Array());
	const [projectTypes, setProjectTypes] = useState([]);
	useEffect(() => {
		repoUsers.searchActive("").then((result) => {
			setUsers(_.differenceBy(result, props.exclude, "userID"));
		});
		repoAchievements.getProjectTypes().then((result) => {
			setProjectTypes(result);
		});
	}, []);

	function filterChange(event: any) {
		repoUsers.searchActive((event.filter && event.filter.value) ? event.filter.value : '').then((result) => {
			setUsers(result);
		});
	}

	function onChange(event: any) {
		let newRecord = { ...record };
		switch (event.target.props.name) {
			case "projectPartnerID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.userID : "";
				break;
			case "projectManagerID":
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value ? event.target.value.userID : "";
				break;
			default:
				// @ts-ignore
				newRecord[event.target.props.name] = event.target.value;
		}
		setRecord(newRecord);
	}

	function onChangePeriod(event: any) {
		let newRecord = { ...record };
		newRecord.period = event.value;
		setRecord(newRecord);
		setDataChanged(true);
	}

	function onSubmit(e: any) {
		e.preventDefault();
	}

	function save(e: any) {
		var queryObject: any = {
			languageID: props.query.languageID,
			businessUnitID: props.query.businessUnitID,
			industryID: props.query.industryID,
			serviceID: props.query.serviceID
		};
		if (record.clientName.length > 0) queryObject.clientNames = record.clientName.split(' ');
		if (record.governementLevel.length > 0) queryObject.governementLevels = record.governementLevel.split(' ');
		if (record.projectName.length > 0) queryObject.projectNames = record.projectName.split(' ');
		if (record.projectType.length > 0) queryObject.projectTypes = record.projectType.split(' ');
		if (record.policyArea.length > 0) queryObject.policyAreas = record.policyArea.split(' ');
		if (record.budgetFrom) queryObject.budgetFrom = record.budgetFrom; 
		if (record.budgetTo) queryObject.budgetTo = record.budgetTo; 
		if (record.numberOfInvolvedStaffFrom) queryObject.numberOfInvolvedStaffFrom = record.numberOfInvolvedStaffFrom; 
		if (record.numberOfInvolvedStaffTo) queryObject.numberOfInvolvedStaffTo = record.numberOfInvolvedStaffTo;
		if (record.period) {
			var period = record.period || { start: new Date(), end: new Date() }; // THIS IS THE REASON WHY I HATE TYPESCRIPT!!!!!
			queryObject.periodStartFrom = moment(period.start).startOf("month").add(1, "days").startOf("day").toDate();
			queryObject.periodEndTo = moment(period.end).startOf("month").add(1, "days").startOf("day").toDate();
		}
		if (record.contactClient.length > 0) queryObject.contactClient = record.contactClient;
		if (record.projectPartnerID.length > 0) queryObject.projectPartner = {
			userID: record.projectPartnerID,
			email: "dummy@deloitte.be",
			firstName: "Dummy",
			function: "None",
			lastName: "Deloitte",
			location: "Nowhere"
		};
		if (record.projectManagerID.length > 0) queryObject.projectManager = {
			userID: record.projectManagerID,
			email: "dummy@deloitte.be",
			firstName: "Dummy",
			function: "None",
			lastName: "Deloitte",
			location: "Nowhere"
		};
		if (record.subcontractorsPartners !== undefined) queryObject.subcontractorsPartners = record.subcontractorsPartners;
		show(NewDynamicEntries, "achievementnew", {
			save: props.save,
			cancel: () => { close("achievementnew"); },
			query: queryObject,
			sectionType: props.sectionType,
			exclude: props.exclude
		});
	}

	function cancel() {
		confirmChanged(dataChanged, () => { save(null); }, props.cancel);
	}

	return (
		<Dialog onClose={cancel} title={translations.general_search} width="70%">
			<form className="k-form" onSubmit={onSubmit}>
				<div className="row">
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.achievements_clientname}</span>
							<Input
								name="clientName"
								style={{ width: "100%" }}
								placeholder={translations.general_placeholder_prefix + " " + translations.achievements_clientname + "..."}
								value={record && record.clientName ? record.clientName : ""}
								onChange={onChange}
								validationMessage={translations.general_validationmessage + " " + translations.achievements_clientname}
							/>
						</label>
					</div>
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.achievements_governementlevel}</span>
							<Input
								name="governementLevel"
								style={{ width: "100%" }}
								placeholder={translations.general_placeholder_prefix + " " + translations.achievements_governementlevel + "..."}
								value={record && record.governementLevel ? record.governementLevel : ""}
								onChange={onChange}
								validationMessage={translations.general_validationmessage + " " + translations.achievements_governementlevel}
							/>
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.achievements_projectname}</span>
							<Input
								name="projectName"
								style={{ width: "100%" }}
								placeholder={translations.general_placeholder_prefix + " " + translations.achievements_projectname + "..."}
								value={record && record.projectName ? record.projectName : ""}
								onChange={onChange}
								validationMessage={translations.general_validationmessage + " " + translations.achievements_projectname}
							/>
						</label>
					</div>
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.achievements_projecttype}</span>
							<ComboBox
								data={projectTypes}
								name="projectType"
								value={record && record.projectType ? record.projectType : undefined}
								style={{ width: "100%" }}
								onChange={onChange}
								allowCustom={true}
							/>
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.achievements_policyarea}</span>
							<Input
								name="policyArea"
								style={{ width: "100%" }}
								placeholder={translations.general_placeholder_prefix + " " + translations.achievements_policyarea + "..."}
								value={record && record.policyArea ? record.policyArea : ""}
								onChange={onChange}
								validationMessage={translations.general_validationmessage + " " + translations.achievements_policyarea}
							/>
						</label>
					</div>
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.achievements_contactclient}</span>
							<Input
								name="contactClient"
								style={{ width: "100%" }}
								placeholder={translations.general_placeholder_prefix + " " + translations.achievements_contactclient + "..."}
								value={record && record.contactClient ? record.contactClient : ""}
								onChange={onChange}
								validationMessage={translations.general_validationmessage + " " + translations.achievements_contactclient}
							/>
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.achievements_budgetfrom}</span>
							<NumericTextBox name="budgetFrom" onChange={onChange} value={record && record.budgetFrom ? record.budgetFrom : undefined} min={0} format="#,###.##"></NumericTextBox>
						</label>
					</div>
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.achievements_budgetto}</span>
							<NumericTextBox name="budgetTo" onChange={onChange} value={record && record.budgetTo ? record.budgetTo : undefined} min={0} format="#,###.##"></NumericTextBox>
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.achievements_numberofinvolvedstafffrom}</span>
							<NumericTextBox name="numberOfInvolvedStaffFrom" onChange={onChange} value={record && record.numberOfInvolvedStaffFrom ? record.numberOfInvolvedStaffFrom : undefined} min={0} format="#"></NumericTextBox>
						</label>
					</div>
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.achievements_numberofinvolvedstaffto}</span>
							<NumericTextBox name="numberOfInvolvedStaffTo" onChange={onChange} value={record && record.numberOfInvolvedStaffTo ? record.numberOfInvolvedStaffTo : undefined} min={0} format="#"></NumericTextBox>
						</label>
					</div>
				</div>
				<label className="k-form-field">
					<span>{translations.achievements_period}</span>
					<DateRangePicker value={record.period} onChange={onChangePeriod} format="MM/yyyy" calendarSettings={{ bottomView: 'year', topView: 'year', views: 2 }}></DateRangePicker>
				</label>
				<div className="row">
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.achievements_projectpartner}</span>
							<ComboBox
								data={users}
								name="projectPartnerID"
								dataItemKey="userID"
								textField="fullName"
								style={{ width: "100%" }}
								onChange={onChange}
								filterable={true}
								onFilterChange={filterChange}
							/>
						</label>
					</div>
					<div className="col-md-6">
						<label className="k-form-field">
							<span>{translations.achievements_projectmanager}</span>
							<ComboBox
								data={users}
								name="projectManagerID"
								dataItemKey="userID"
								textField="fullName"
								style={{ width: "100%" }}
								onChange={onChange}
								filterable={true}
								onFilterChange={filterChange}
							/>
						</label>
					</div>
				</div>
				<label className="k-form-field">
					<span>{translations.achievements_subcontractorspartners}</span>
					{/*
							// @ts-ignore*/}
					<Switch name="subcontractorsPartners" onChange={onChange} onLabel={translations.general_switch_on} offLabel={translations.general_switch_off}
					/>
				</label>
			</form>
			<DialogActionsBar>
				<button className="k-button" onClick={cancel}>{translations.general_cancel}</button>
				<button className="k-button k-primary" onClick={save}>{translations.general_search}</button>
			</DialogActionsBar>
		</Dialog>
	);
}

SearchAchievements.defaultProps = {
	save: (record: any) => { },
	cancel: () => { },
};

export default SearchAchievements;